import {
  BaseDomainTheme,
  CobrandId,
  COBRAND_IDS,
  FontFace,
  StyleDefMapping,
} from '@client/store/types/cobranding';
import { get } from 'lodash';

import partnerAgentImage from '@client/assets/images/agent_partner_contract.png';
import chaseNullStateLotMapBackgroundImage from '@client/assets/images/cobrand/chase/lot-line-null-state-image-desktop-chase.png';
import lendcoLotMapNullStateImageUrl from '@client/assets/images/lot-line-null-state-image-desktop.png';
import { View } from '@client/routes/constants';
import {
  convertHexColorToRgba,
  lightenDarkenHexColor,
} from '@client/utils/string.utils';
import { defaultCustomizationDataJSON } from '@hc/consumer-customization-data';

export const AVENIR_FONT_FACES: FontFace[] = [
  {
    src: [
      {
        url: 'fonts/avenir/avenir_300.woff2',
        format: 'woff2',
        isHostedAsset: true,
      },
      {
        url: 'fonts/avenir/avenir_300.woff',
        format: 'woff',
        isHostedAsset: true,
      },
    ],
    weight: 300,
    style: 'normal',
  },
  {
    src: [
      {
        url: 'fonts/avenir/avenir_500.woff2',
        format: 'woff2',
        isHostedAsset: true,
      },
      {
        url: 'fonts/avenir/avenir_500.woff',
        format: 'woff',
        isHostedAsset: true,
      },
    ],
    weight: 500,
    style: 'normal',
  },
  {
    src: [
      {
        url: 'fonts/avenir/avenir_800.woff2',
        format: 'woff2',
        isHostedAsset: true,
      },
      {
        url: 'fonts/avenir/avenir_800.woff',
        format: 'woff',
        isHostedAsset: true,
      },
    ],
    weight: 800,
    style: 'normal',
  },
  {
    src: [
      {
        url: 'fonts/avenir/avenir_900.woff2',
        format: 'woff2',
        isHostedAsset: true,
      },
      {
        url: 'fonts/avenir/avenir_900.woff',
        format: 'woff',
        isHostedAsset: true,
      },
    ],
    weight: 900,
    style: 'normal',
  },
];

const OPEN_SAN_FONT_FACES: FontFace[] = [
  {
    src: [
      {
        url: 'fonts/openSans/OpenSans-Light.ttf',
        format: 'truetype',
        isHostedAsset: true,
      },
    ],
    weight: 100,
    style: 'normal',
  },
  {
    src: [
      {
        url: 'fonts/openSans/OpenSans-Regular.ttf',
        format: 'truetype',
        isHostedAsset: true,
      },
    ],
    weight: 200,
    style: 'normal',
  },
  {
    src: [
      {
        url: 'fonts/openSans/OpenSans-SemiBold.ttf',
        format: 'truetype',
        isHostedAsset: true,
      },
    ],
    weight: 500,
    style: 'normal',
  },
  {
    src: [
      {
        url: 'fonts/openSans/OpenSans-Bold.ttf',
        format: 'truetype',
        isHostedAsset: true,
      },
    ],
    weight: 700,
    style: 'normal',
  },
];

export const DEFAULT_DOMAIN_COLORS = {
  lightGreyColor: '#747A80',
  semiLightGreyColor: '#9b9b9b',
  darkGreyColor: '#414042',
  midGreyColor: '#4A4A4A',
  whiteColor: '#fff',
  almostBlackColor: '#0E0E0E',
  mapPlaceBoundaryLayerLineColor: '#FD8A5F',
};

/* Hardcoded for now, will soon be retrieved from API */
/* Note: the values in this object currently OVERRIDE the domain theme retrieved from Parcon in `set-domain-theme.ts` */
export const CHASE_DOMAIN_THEME_OVERRIDES = {
  accessibilityFocusOutlineColor: '#6e6e6e',
  authScheme: 'saml',
  displayName: 'Chase',
  primaryColor: '#0092FF',
  secondaryColor: '#0B6EFD',
  textOnPrimary: '',
  textOnWhite: '',
  linkOnPrimary: '',
  linkOnWhite: '#0B6EFD',
  logo: '',
  mobileLogo: null,
  favicon: '',
  homeSiteUrl: null,
  backToSiteText: null,
  fontFaces: OPEN_SAN_FONT_FACES,
  ...DEFAULT_DOMAIN_COLORS,
  primaryCtaColor: '',
  secondaryCtaColor: '',
  svgFillColor: '',
  primaryButtonFillColor: '',
  primaryButtonBorderRadius: '',
  secondaryButtonFillColor: '',
  secondaryButtonBorderRadius: '',
  avmBackgroundColor: '',
  headerBackgroundColor: '#0846A8',
  headerLinkColor: '',
  headerLinkHoverColor: '',
  headerLinks: null,
  disclosureText: {
    estimatedPayment: '',
    mortgageCalculator: '',
    refiCalculator: '',
  },
  supportUrl: null,
  customizationData: defaultCustomizationDataJSON,
  hoDashboardConfig: null,
  widget: [],
  customDisclaimers: {
    estimated_value_disclaimer_label: '',
    estimated_value_disclaimer_content: '',
    mortgage_calculator_disclaimer_label: '',
    mortgage_calculator_disclaimer_content: '',
  },
};

/* Note: the values in this object currently OVERRIDE the domain theme retrieved from consumer-api in `set-domain-theme.ts`
 * TODO we should diff these with what the /v2/domain/{cobrandId} endpoint currently returns and eliminate most/all of
 * these hardcoded values */
export const COMEHOME_DOMAIN_THEME_OVERRIDES = {
  accessibilityFocusOutlineColor: '#6e6e6e',
  authScheme: 'standard',
  displayName: 'ComeHome',
  primaryColor: '#5646EF',
  secondaryColor: '#5840EA',
  textOnPrimary: '#FFF',
  textOnWhite: '#3CDFC2',
  linkOnPrimary: '#FFF',
  linkOnWhite: '#5646EF',
  logo: null,
  mobileLogo: null,
  favicon: '',
  ...DEFAULT_DOMAIN_COLORS,
  homeSiteUrl: null,
  backToSiteText: null,
  fontFaces: AVENIR_FONT_FACES,
  primaryCtaColor: '',
  secondaryCtaColor: '',
  svgFillColor: '#5646EF',
  primaryButtonFillColor: '#564EF0',
  primaryButtonBorderRadius: '2em',
  secondaryButtonFillColor: '#4A4A4A',
  secondaryButtonBorderRadius: '2em',
  avmBackgroundColor: '',
  headerBackgroundColor: '#ffffff',
  headerLinkColor: '#3CDFC2',
  headerLinkHoverColor: '#5646EF',
  headerLinks: null,
  footer: {
    logoUrl: '',
    text: '',
  },
  disclosureText: {
    estimatedPayment: '',
    mortgageCalculator: '',
    refiCalculator: '',
  },
  supportUrl: View.FEEDBACK,
  customizationData: defaultCustomizationDataJSON,
  hoDashboardConfig: null,
  widget: [],
  customDisclaimers: {
    estimated_value_disclaimer_label: '',
    estimated_value_disclaimer_content: '',
    mortgage_calculator_disclaimer_label: '',
    mortgage_calculator_disclaimer_content: '',
  },
};

export const CUSTOM_FONT = 'CustomFont';
export const CUSTOM_FONT_FALLBACK = 'CustomFontFallback';

export const DOMAIN_THEME_OVERRIDES = {
  [COBRAND_IDS.WWW]: COMEHOME_DOMAIN_THEME_OVERRIDES,
  [COBRAND_IDS.CHASE]: CHASE_DOMAIN_THEME_OVERRIDES,
};

/**
 * Styles to be used for each cobrand ID.  Note that tests will fail if all
 * component defs don't contain the exact same keys.
 * Please keep in alpha order.
 */
function getChaseStyleDefs(colorScheme: BaseDomainTheme): StyleDefMapping {
  return {
    ...getBaseStyleDefs(colorScheme),
    accessibilityFocusColor: colorScheme.secondaryColor,
    activeAddressSearchLabelPosition: { left: '0px', top: '0px' },
    activeBuyingPowerColor: colorScheme.primaryColor,
    activeFilterCountColor: colorScheme.secondaryColor,
    activeFilterPillColor: colorScheme.darkGreyColor,
    activeNavItemBorderBottomStyle: `1px solid ${colorScheme.primaryColor}`,
    activeNavItemColor: colorScheme.primaryColor,
    activeOptionUnderlineColor: colorScheme.primaryColor,
    activePrimaryColor: '',
    activeRadioButtonColor: colorScheme.primaryColor,
    activitiesActiveOptionUnderlineColor: colorScheme.secondaryColor,
    agentContactPhoneIconColor: colorScheme.primaryColor,
    alertsCardValueInlineFontWeight: 100,
    alertMailIconMobileColor: colorScheme.secondaryColor,
    appHeadingBackgroundColor: colorScheme.secondaryColor,
    authButtonBackgroundColor: colorScheme.secondaryColor,
    authButtonHoverColor: '',
    authModalHeadingBackground: colorScheme.midGreyColor,
    autoCompleteDropdownColor: '',
    avmBreakdownBackground: '#0E4C96',
    avmBreakdownBorderColor: '#F0F0F0',
    avmBreakdownBorderRadius: '3px',
    avmBreakdownBoxShadow: '0 3px 3px 0 rgba(0,0,0,0.14)',
    avmDeepDiveFactorBorderColor: colorScheme.primaryColor,
    breakoutAndPreviewSectionsTitleFontSize: '17px',
    breakoutAndPreviewSectionsTitleFontWeight: 200,
    buttonBackgroundSecondary: colorScheme.darkGreyColor,
    cantFindAddressModalClickableLogoStyle: {
      height: 'initial',
      width: 'initial',
    },
    chatSurveyInputBg: 'rgba(0, 57, 100, 0.1)',
    chatSurveyPrimary: colorScheme.secondaryColor,
    chatSurveyResponseBg: colorScheme.secondaryColor,
    checkboxCheckedColor: colorScheme.primaryColor,
    choChartDollarIconFill: '#3CDFC2',
    choHeaderBgBase: colorScheme.secondaryColor,
    brokerageInfoPageBgColorFilter: convertHexColorToRgba(
      colorScheme.primaryColor,
      50
    ),
    choHeroHomePageBgGradientOverlay: `linear-gradient(90deg, ${colorScheme.primaryColor} 0%, #0E4C96 100%)`,
    choHeroHomePageLinkColor: '#f0f0f0',
    choHeroHomePageSearchBoxLogInLinkColor: colorScheme.linkOnWhite,
    choHeroHomePageSearchButtonBackground: `linear-gradient(90deg, ${colorScheme.primaryColor} 0%, #0E4C96 100%)`,
    choHomeImprovementEstimatedValueAmountBackground: colorScheme.primaryColor,
    choHomeImprovementHeroContentTextColor: colorScheme.darkGreyColor,
    choHomeImprovementHeroTitleFontWeight: 600,
    choHomeImprovementHeroTitleTextColor: colorScheme.darkGreyColor,
    choLineChartStrokeColor: colorScheme.primaryColor,
    choProFinderCheckBg: colorScheme.primaryColor,
    choProFinderResultsBorderRadius: '',
    choProFinderResultsButtonColor: '',
    choProFinderResultsLinkColor: colorScheme.primaryColor,
    circleButtonActiveBackground: colorScheme.primaryColor,
    circleButtonInactiveBackground: '#f0f0f0',
    confirmEmailButtonBg: 'transparent',
    defaultTextColor: colorScheme.darkGreyColor,
    dismissableBannerBackground: '',
    dismissableBannerTextColor: '',
    dismissableBannerLinkColor: '',
    dropdownListOptionSelected: colorScheme.primaryColor,
    editProfileLinkColor: colorScheme.secondaryColor,
    favicon: '',
    filterButtonHighLight: colorScheme.primaryColor,
    findAgentButtonColor: colorScheme.secondaryColor,
    findAgentPageBgGradientOverlay: `linear-gradient(90deg, ${colorScheme.primaryColor} 0%, #0E4C96 100%)`,
    findAgentPageLinkColor: colorScheme.linkOnWhite,
    flatButtonColor: '',
    forecastBackgroundColorOne: '#b0d2fe',
    forecastBackgroundColorThree: '#318afe',
    forecastBackgroundColorTwo: '#63a7fe',
    forecastChartGradientColorOne: colorScheme.secondaryColor,
    forecastChartGradientColorTwo: colorScheme.secondaryColor,
    headerBackgroundColor: colorScheme.headerBackgroundColor,
    homeImprovementCalculatorSqFtInputButtonColor: colorScheme.midGreyColor,
    homeImprovementCalculatorSqFtInputButtonHoverColor:
      colorScheme.primaryColor,
    homeImprovementToggleHomeConditionListArrowColor:
      colorScheme.secondaryColor,
    homeownerAdBg: colorScheme.whiteColor,
    homeownerAdBtnRadius: '4px',
    homeownerAdTextColor: '',
    homeownerAvmBackground: colorScheme.primaryColor,
    homeownerBorderColor: '',
    homeownerCardHeading: colorScheme.secondaryColor,
    homeownerCardRefiCardBg: colorScheme.primaryColor,
    homeownerCardRefiCardTxt: colorScheme.whiteColor,
    homeownerCardRefiDescription: colorScheme.whiteColor,
    homeownerCardRefiDividerColor: colorScheme.secondaryColor,
    homeownerCardRefiTitle: colorScheme.whiteColor,
    homeownerEditDetailsBtnColor: colorScheme.whiteColor,
    homeownerEditDetailsColor: colorScheme.primaryColor,
    homeownerHelocBtnBg: colorScheme.secondaryColor,
    homeownerHelocBtnTxt: colorScheme.whiteColor,
    homeownerHelocTitle: colorScheme.secondaryColor,
    homeownerModalHeaderBackgroundColor: colorScheme.primaryColor,
    homeownerModalHeaderTextColor: colorScheme.whiteColor,
    homeownerModalInputUnderlineColor: colorScheme.primaryColor,
    homeownerModalResetTextColor: colorScheme.primaryColor,
    homeownerModalResultValueBackgroundColor: colorScheme.primaryColor,
    homeownerRefiBtnBg: colorScheme.whiteColor,
    homeownerRefiBtnTxt: colorScheme.midGreyColor,
    homeOwnerRefiChartBarHoverColor: colorScheme.primaryColor,
    homeownerRefiDataColor: colorScheme.whiteColor,
    homepageDarkBackground: colorScheme.secondaryColor,
    homepageDarkBgTextColor: colorScheme.textOnPrimary,
    inputTextColor: colorScheme.secondaryColor,
    legendColor: colorScheme.secondaryColor,
    lenderHomePageBackgroundColor: '',
    linkColor: colorScheme.secondaryColor,
    loanOfficerBg: colorScheme.secondaryColor,
    loanRefiCalcBg: colorScheme.primaryColor,
    loanRefiCalcFontColor: colorScheme.textOnPrimary,
    logoUrl: null,
    lotMapNullStateImageUrl: chaseNullStateLotMapBackgroundImage,
    manageAlertsLinkColor: colorScheme.secondaryColor,
    manageHomesCardHeaderBackground: colorScheme.primaryColor,
    manageHomesCardHeaderTextColor: colorScheme.whiteColor,
    mapPinsColor: '#0E4C96',
    mapPinsVisitedColor: '#0077d2',
    mlsModalCTAColor: '',
    mlsRegistrationBorderColor: colorScheme.primaryColor,
    mlsRegistrationHoverColor: colorScheme.lightGreyColor,
    mobileActiveNavItemBottomBorderStyle: `1px solid ${colorScheme.primaryColor}`,
    mobileActiveNavItemColor: colorScheme.primaryColor,
    mobileFiltersShowResultsButtonBackground: colorScheme.primaryColor,
    mobileInactiveNavItemColor: colorScheme.darkGreyColor,
    mobileLogoUrl: null,
    modalBorderRadius: '4px',
    mortgageCalculatorInputTextColor: colorScheme.midGreyColor,
    mortgagePaymentBackground: '#4A7AE3',
    neighborhoodInsightsBarColorOne: colorScheme.secondaryColor,
    neighborhoodInsightsBarColorTwo: colorScheme.secondaryColor,
    newIconBg: '#3CDFC2',
    newIconTextColor: '#FFFFFF',
    partialHeaderNavItemColor: '',
    partialHeaderNavItemHoverColor: '',
    partialHeaderChevronIconPositionOffset: '',
    partialHeaderHoverChevronIconColor: '',
    partialHeaderChevronIconColor: '',
    partialHeaderNavItemWidth: '',
    partialHeaderChevronIconHeight: '',
    paperAirplaneIconColor: colorScheme.primaryColor,
    pdpLotMapLotIndicatorColor: colorScheme.secondaryColor,
    pdpLotMapTooltipColor: '#0E4C96',
    photosModalTopBarHeight: '48px',
    photoModalSelectedBorderColor: colorScheme.primaryColor,
    pieChartPrimaryColor: colorScheme.primaryColor,
    pillButtonDeemphasizedBackgroundColor: colorScheme.whiteColor,
    pillButtonDeemphasizedTextColor: colorScheme.darkGreyColor,
    pillButtonPrimaryBackgroundColor: '#0060f0',
    pillButtonPrimaryButtonBorderRadius: '4px',
    pillButtonPrimaryTextColor: colorScheme.whiteColor,
    pillButtonSecondaryBackgroundColor: '#0060f0',
    pillButtonSecondaryButtonBorderRadius: '4px',
    pillButtonSecondaryTextColor: colorScheme.whiteColor,
    pillButtonTertiaryBackgroundColor: '#0060f0',
    pillButtonTertiaryTextColor: colorScheme.whiteColor,
    primaryButtonBorderRadius: '',
    primaryButtonFillColor: colorScheme.primaryColor,
    progressBarColor: colorScheme.primaryColor,
    propertyBrokerageLinkColor: colorScheme.linkOnWhite,
    propertyCardColor: '',
    propertyCardMinHeight: '388px',
    propertyInfoPDPMobileToggleColor: '#0060f0',
    propertyInfoTableBackground: colorScheme.whiteColor,
    propertyIntroSeeAllDetailsLinkColor: colorScheme.secondaryColor,
    propertyListingPhoneColor: colorScheme.linkOnWhite,
    regressionLineChartHomeScatterPlotColor: '#4a4a4a',
    rentalYieldBackgroundColor: colorScheme.secondaryColor,
    rentalYieldTextColor: colorScheme.whiteColor,
    resumeRecentSearchComponentBorderRadius: '2px',
    savedAndAlertsHeaderActiveItemUnderlineColor: colorScheme.primaryColor,
    saveSearchCTABackground: colorScheme.midGreyColor,
    saveSearchCTABorderRadius: '30px',
    saveSearchDesktopButtonColor: '#0060f0',
    saveSearchDesktopButtonFontWeight: 400,
    saveSearchFloatingButtonColor: '#0060f0',
    saveSearchFloatingButtonSuccessColor: '#0060f0',
    searchListControlsColor: colorScheme.secondaryColor,
    secondaryButtonBorderRadius: '',
    secondaryButtonFillColor: '',
    selectedSortOptionColor: colorScheme.secondaryColor,
    supportUrl: null,
    textAreaBorder: '',
    textButtonLinkColor: colorScheme.primaryColor,
    tooltipIconColorOnHover: colorScheme.secondaryColor,
    watchlistHeaderItemColor: colorScheme.secondaryColor,
    welcomePageColor: '',
    /* need to unset the defined width so that min-width take effect on the shared component */
    savedSearchCardButtonStyle: { marginLeft: 'auto', width: 'unset' },
    lighterDisclosureAsteriskColor: '',
    CHOLoanOfficerPDPContactLOLinkColor: colorScheme.primaryColor,
    MobileSearchHomesBackButtonTextColor: colorScheme.primaryColor,
    partnerAgentBannerGradientColor: '',
    partnerAgentCardGradientColor: '',
    partnerAgentBackgroundColor: '',
    pillButtonCustomButtonMinWidth: '160px',
    pillButtonCustomButtonWidth: 'auto',
  };
}

function getComeHomeStyleDefs(colorScheme: BaseDomainTheme): StyleDefMapping {
  return {
    ...getBaseStyleDefs(colorScheme),
    activeFilterCountColor: colorScheme.midGreyColor,
    activeFilterPillColor: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    activeNavItemBorderBottomStyle: `1px solid ${colorScheme.primaryColor}`,
    activeNavItemColor: colorScheme.primaryColor,
    mobileActiveNavItemColor: colorScheme.primaryColor,
    mobileInactiveNavItemColor: colorScheme.midGreyColor,
    mobileActiveNavItemBottomBorderStyle: `1px solid ${colorScheme.primaryColor}`,
    activitiesActiveOptionUnderlineColor: colorScheme.midGreyColor,
    appHeadingBackgroundColor: colorScheme.primaryColor,
    authButtonBackgroundColor: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    authButtonHoverColor: '',
    autoCompleteDropdownColor: '',
    authModalHeadingBackground: colorScheme.midGreyColor,
    avmBreakdownBackground: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    avmBreakdownBorderColor: '#F0F0F0',
    avmBreakdownBorderRadius: '12px',
    breakoutAndPreviewSectionsTitleFontSize: '17px',
    breakoutAndPreviewSectionsTitleFontWeight: 200,
    buttonBackgroundSecondary: colorScheme.midGreyColor,
    cantFindAddressModalClickableLogoStyle: { height: '20px', width: '118px' },
    chatSurveyInputBg: 'rgba(86, 78, 240, 0.1)',
    chatSurveyResponseBg: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    choLineChartStrokeColor: '#3CDFC2',
    choHeaderBgBase: 'rgb(74, 78, 228)',
    brokerageInfoPageBgColorFilter: convertHexColorToRgba(
      colorScheme.secondaryColor,
      50
    ),
    choHeroHomePageBgGradientOverlay: `linear-gradient(104.11deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    choHeroHomePageLinkColor: '#f0f0f0',
    choHeroHomePageSearchButtonBackground: `linear-gradient(104deg, ${colorScheme.secondaryColor} 0%,  #3A61DE 100%)`,
    choHomeImprovementEstimatedValueAmountBackground: colorScheme.primaryColor,
    choHomeImprovementHeroTitleTextColor: colorScheme.primaryColor,
    choHomeImprovementHeroTitleFontWeight: 900,
    choHomeImprovementHeroContentTextColor: '#20203F',
    choProFinderResultsBorderRadius: '22px',
    choProFinderResultsButtonColor: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    choProFinderResultsLinkColor: colorScheme.primaryColor,
    choProFinderCheckBg: '#3CDFC2',
    circleButtonActiveBackground: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    defaultTextColor: colorScheme.midGreyColor,
    favicon: '',
    filterButtonHighLight: '#3CDFC2',
    findAgentPageBgGradientOverlay: `linear-gradient(104.11deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    findAgentButtonColor: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    flatButtonColor: '',
    forecastBackgroundColorOne: '#bab0f6',
    forecastBackgroundColorTwo: '#9d8ff3',
    forecastBackgroundColorThree: '#6d58ed',
    forecastChartGradientColorOne: colorScheme.secondaryColor,
    forecastChartGradientColorTwo: '#3A61DE',
    homeownerAdBg: 'rgba(60,223,194,0.09)',
    homeownerAdTextColor: '#4A4A4A',
    confirmEmailButtonBg: `linear-gradient(104.11deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    homeImprovementCalculatorSqFtInputButtonColor: '#20203F',
    homeImprovementToggleHomeConditionListArrowColor: '#0B6EFD',
    homeownerBorderColor: '',
    homeownerAvmBackground: colorScheme.primaryColor,
    homeownerCardRefiDividerColor: colorScheme.whiteColor,
    lenderHomePageBackgroundColor: '',
    legendColor: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    linkColor: colorScheme.primaryColor,
    loDirectAuthBackgroundColor: '#0B62FF',
    loDirectAuthButtonColor: '#0B62FF',
    loDirectAuthButtonTextColor: '#FFFFFF',
    loDirectAuthTextColor: '#FFFFFF',
    loDirectAuthLinkColor: '#0B62FF',
    logoUrl: null,
    loanOfficerBg: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    manageHomesCardHeaderBackground: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    mobileFiltersShowResultsButtonBackground: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    mobileLogoUrl: null,
    mortgageCalculatorInputTextColor: colorScheme.midGreyColor,
    neighborhoodInsightsBarColorOne: colorScheme.secondaryColor,
    neighborhoodInsightsBarColorTwo: '#3A61DE',
    photosModalTopBarHeight: '48px',
    progressBarColor: `linear-gradient(104.11deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    propertyCardColor: '',
    propertyInfoPDPMobileToggleColor: colorScheme.primaryColor,
    rentalYieldBackgroundColor: `linear-gradient(80deg, ${colorScheme.secondaryColor} 0%, #3A61DE 100%)`,
    resumeRecentSearchComponentBorderRadius: '2px',
    saveSearchCTABackground: colorScheme.midGreyColor,
    saveSearchCTABorderRadius: '30px',
    searchListControlsColor: colorScheme.midGreyColor,
    textAreaBorder: '',
    savedAndAlertsHeaderActiveItemUnderlineColor:
      'linear-gradient(270deg, #FBDA2B 0%, #FBD82D 30.13%, #FFB854 100%)',
    welcomePageColor: '',
    tooltipIconColorOnHover: colorScheme.secondaryColor,
    checkboxCheckedColor: colorScheme.primaryColor,
    mlsRegistrationHoverColor: colorScheme.lightGreyColor,
    propertyIntroSeeAllDetailsLinkColor: colorScheme.primaryColor,
    mlsModalCTAColor: '#4a4a4a',
  };
}

function getCaliberHomeLoansStyleDefs(
  colorScheme: BaseDomainTheme
): StyleDefMapping {
  return {
    ...getBaseStyleDefs(colorScheme),
    CHOLoanOfficerPDPContactLOLinkColor: colorScheme.primaryCtaColor,
  };
}

function getShopForHomesStyleDefs(
  colorScheme: BaseDomainTheme
): StyleDefMapping {
  return {
    ...getBaseStyleDefs(colorScheme),
    accessibilityFocusOutlineColor: '#075fcc',
    accessibilityFocusOutlineWidth: '2px',
    accessibilityFocusOutlineStyle: 'dashed',
    choHeaderBgBase: colorScheme.primaryColor,
  };
}

function getBaseStyleDefs(colorScheme: BaseDomainTheme): StyleDefMapping {
  return {
    accessibilityFocusOutlineColor: colorScheme.accessibilityFocusOutlineColor,
    accessibilityFocusOutlineStyle: 'solid',
    accessibilityFocusOutlineWidth: '1px',
    accessibilityFocusColor: colorScheme.primaryColor,
    activeAddressSearchLabelPosition: { left: '18px', top: '6px' },
    activeBuyingPowerColor: colorScheme.primaryColor,
    activeFilterCountColor: colorScheme.primaryColor,
    activeFilterPillColor: colorScheme.darkGreyColor,
    activeNavItemBorderBottomStyle: `2px solid ${colorScheme.primaryColor}`,
    activeNavItemColor: colorScheme.primaryCtaColor,
    activeOptionUnderlineColor: colorScheme.primaryColor,
    activePrimaryColor: colorScheme.primaryColor,
    activeRadioButtonColor: colorScheme.primaryColor,
    activitiesActiveOptionUnderlineColor: colorScheme.primaryColor,
    agentContactPhoneIconColor: colorScheme.primaryColor,
    alertMailIconMobileColor: colorScheme.primaryColor,
    alertsCardValueInlineFontWeight: 900,
    appHeadingBackgroundColor: colorScheme.midGreyColor,
    authButtonBackgroundColor: colorScheme.primaryColor,
    authButtonHoverColor: colorScheme.primaryColor,
    authModalHeadingBackground: colorScheme.primaryColor,
    autoCompleteDropdownColor: colorScheme.primaryColor,
    avmBreakdownBackground: colorScheme.avmBackgroundColor,
    avmBreakdownBorderColor: '#EEE',
    avmBreakdownBorderRadius: '8px',
    avmBreakdownBoxShadow: '3px 9px 15px -3px rgba(0, 0, 0, 0.17)',
    avmDeepDiveFactorBorderColor: colorScheme.primaryColor,
    breakoutAndPreviewSectionsTitleFontSize: '16px',
    breakoutAndPreviewSectionsTitleFontWeight: 900,
    buttonBackgroundSecondary: colorScheme.darkGreyColor,
    cantFindAddressModalClickableLogoStyle: { height: '26px', width: 'auto' },
    chatSurveyInputBg: 'rgba(5, 191, 104, 0.1)',
    chatSurveyPrimary: colorScheme.primaryColor,
    chatSurveyResponseBg: colorScheme.primaryColor,
    checkboxCheckedColor: colorScheme.primaryCtaColor,
    choChartDollarIconFill: colorScheme.primaryColor,
    choHeaderBgBase: colorScheme.secondaryColor,
    brokerageInfoPageBgColorFilter: convertHexColorToRgba(
      colorScheme.secondaryColor,
      50
    ),
    choHeroHomePageBgGradientOverlay: `linear-gradient(116deg, ${convertHexColorToRgba(
      colorScheme.secondaryColor,
      96
    )} 0%, ${convertHexColorToRgba(
      colorScheme.secondaryColor,
      98
    )} 68%, ${convertHexColorToRgba(colorScheme.secondaryColor, 100)} 100%)`,
    choHeroHomePageLinkColor: '#FFFFFF',
    choHeroHomePageSearchBoxLogInLinkColor: colorScheme.linkOnWhite,
    choHeroHomePageSearchButtonBackground: colorScheme.primaryButtonFillColor,
    choHomeImprovementEstimatedValueAmountBackground: get(
      colorScheme,
      ['loCardConfig', 'hoHic', 'bgColor'],
      '#364656'
    ),
    choHomeImprovementHeroContentTextColor: colorScheme.almostBlackColor,
    choHomeImprovementHeroTitleFontWeight: 500,
    choHomeImprovementHeroTitleTextColor: colorScheme.almostBlackColor,
    choLineChartStrokeColor: colorScheme.primaryColor,
    choProFinderCheckBg: colorScheme.primaryColor,
    choProFinderResultsBorderRadius: '',
    choProFinderResultsButtonColor: '',
    choProFinderResultsLinkColor: '',
    circleButtonActiveBackground: colorScheme.primaryCtaColor,
    circleButtonInactiveBackground: '#f0f0f0',
    confirmEmailButtonBg: colorScheme.primaryColor,
    defaultTextColor: colorScheme.textOnWhite,
    dismissableBannerBackground: colorScheme.primaryColor,
    dismissableBannerTextColor: colorScheme.textOnPrimary,
    dismissableBannerLinkColor: colorScheme.linkOnPrimary,
    dropdownListOptionSelected: colorScheme.primaryColor,
    editProfileLinkColor: colorScheme.primaryColor,
    favicon: colorScheme.favicon,
    filterButtonHighLight: colorScheme.primaryColor,
    findAgentButtonColor: colorScheme.secondaryButtonFillColor,
    findAgentPageBgGradientOverlay: `linear-gradient(116.74deg, ${colorScheme.primaryColor} 0%, ${colorScheme.primaryColor} 100%)`,
    findAgentPageLinkColor: colorScheme.linkOnWhite,
    flatButtonColor: colorScheme.midGreyColor,
    forecastBackgroundColorOne: convertHexColorToRgba(
      colorScheme.primaryColor,
      50
    ),
    forecastBackgroundColorThree: colorScheme.primaryColor,
    forecastBackgroundColorTwo: convertHexColorToRgba(
      colorScheme.primaryColor,
      75
    ),
    forecastChartGradientColorOne: colorScheme.primaryColor,
    forecastChartGradientColorTwo: colorScheme.primaryColor,
    headerBackgroundColor: colorScheme.headerBackgroundColor,
    homeImprovementCalculatorSqFtInputButtonColor: colorScheme.midGreyColor,
    homeImprovementCalculatorSqFtInputButtonHoverColor:
      colorScheme.primaryColor,
    homeImprovementToggleHomeConditionListArrowColor: colorScheme.linkOnWhite,
    homeownerAdBg: '#f7f3e8',
    homeownerAdBtnRadius: colorScheme.primaryButtonBorderRadius,
    homeownerAdTextColor: colorScheme.textOnWhite,
    homeownerAvmBackground: colorScheme.secondaryColor,
    homeownerBorderColor: colorScheme.primaryColor,
    homeownerCardHeading: colorScheme.primaryColor,
    homeownerCardRefiCardBg: colorScheme.primaryColor,
    homeownerCardRefiCardTxt: colorScheme.whiteColor,
    homeownerCardRefiDescription: colorScheme.whiteColor,
    homeownerCardRefiDividerColor: colorScheme.secondaryColor,
    homeownerCardRefiTitle: colorScheme.whiteColor,
    homeownerEditDetailsBtnColor: colorScheme.whiteColor,
    homeownerEditDetailsColor: colorScheme.primaryColor,
    homeownerHelocBtnBg: colorScheme.primaryColor,
    homeownerHelocBtnTxt: colorScheme.whiteColor,
    homeownerHelocTitle: colorScheme.primaryColor,
    homeownerModalHeaderBackgroundColor: colorScheme.primaryColor,
    homeownerModalHeaderTextColor: colorScheme.whiteColor,
    homeownerModalInputUnderlineColor: colorScheme.primaryColor,
    homeownerModalResetTextColor: colorScheme.primaryColor,
    homeownerModalResultValueBackgroundColor: colorScheme.primaryColor,
    homeownerRefiBtnBg: colorScheme.whiteColor,
    homeownerRefiBtnTxt: colorScheme.midGreyColor,
    homeOwnerRefiChartBarHoverColor: colorScheme.primaryColor,
    homeownerRefiDataColor: colorScheme.whiteColor,
    homepageDarkBackground: colorScheme.secondaryColor,
    homepageDarkBgTextColor: colorScheme.textOnPrimary,
    inputTextColor: colorScheme.primaryColor,
    legendColor: colorScheme.primaryColor,
    lenderHomePageBackgroundColor: colorScheme.primaryColor,
    linkColor: colorScheme.linkOnWhite,
    loanOfficerBg: '#063040',
    loanRefiCalcBg: colorScheme.primaryColor,
    loanRefiCalcFontColor: colorScheme.textOnPrimary,
    loDirectAuthBackgroundColor: colorScheme.primaryButtonFillColor,
    loDirectAuthButtonColor: colorScheme.primaryButtonFillColor,
    loDirectAuthButtonTextColor: colorScheme.whiteColor,
    loDirectAuthTextColor: colorScheme.whiteColor,
    loDirectAuthLinkColor: colorScheme.linkOnWhite,
    logoUrl: colorScheme.logo,
    lotMapNullStateImageUrl: lendcoLotMapNullStateImageUrl,
    manageAlertsLinkColor: colorScheme.primaryColor,
    manageHomesCardHeaderBackground: colorScheme.primaryColor,
    manageHomesCardHeaderTextColor: colorScheme.whiteColor,
    mapPlaceBoundaryLayerLineColor: colorScheme.mapPlaceBoundaryLayerLineColor,
    mapPinsColor: colorScheme.primaryColor,
    mapPinsVisitedColor: lightenDarkenHexColor(colorScheme.primaryColor, 45),
    mlsModalCTAColor: colorScheme.primaryColor,
    mlsRegistrationBorderColor: colorScheme.primaryColor,
    mlsRegistrationHoverColor: colorScheme.semiLightGreyColor,
    mobileActiveNavItemBottomBorderStyle: `2px solid ${colorScheme.primaryColor}`,
    mobileActiveNavItemColor: colorScheme.midGreyColor,
    mobileFiltersShowResultsButtonBackground: colorScheme.primaryColor,
    mobileInactiveNavItemColor: colorScheme.semiLightGreyColor,
    mobileLogoUrl: colorScheme.mobileLogo,
    modalBorderRadius: '14px',
    mortgageCalculatorInputTextColor: colorScheme.primaryColor,
    mortgagePaymentBackground: colorScheme.primaryColor,
    neighborhoodInsightsBarColorOne: colorScheme.primaryColor,
    neighborhoodInsightsBarColorTwo: colorScheme.primaryColor,
    newIconBg: '#FFFFFF',
    newIconTextColor: colorScheme.primaryColor,
    partialHeaderChevronIconColor: colorScheme.textOnWhite,
    partialHeaderChevronIconHeight: '17px',
    partialHeaderChevronIconPositionOffset: '7px',
    partialHeaderHoverChevronIconColor: colorScheme.primaryColor,
    partialHeaderNavItemColor: colorScheme.headerLinkColor,
    partialHeaderNavItemHoverColor: colorScheme.headerLinkHoverColor,
    partialHeaderNavItemWidth: '',
    paperAirplaneIconColor: colorScheme.primaryColor,
    pdpLotMapLotIndicatorColor: colorScheme.primaryColor,
    pdpLotMapTooltipColor: colorScheme.primaryColor,
    photosModalTopBarHeight: '88px',
    photoModalSelectedBorderColor: colorScheme.primaryColor,
    pieChartPrimaryColor: colorScheme.primaryColor,
    pillButtonDeemphasizedBackgroundColor: colorScheme.midGreyColor,
    pillButtonDeemphasizedTextColor: colorScheme.whiteColor,
    pillButtonPrimaryBackgroundColor: colorScheme.primaryButtonFillColor,
    pillButtonPrimaryButtonBorderRadius: colorScheme.primaryButtonBorderRadius,
    pillButtonPrimaryTextColor: colorScheme.whiteColor,
    pillButtonSecondaryBackgroundColor: colorScheme.secondaryButtonFillColor,
    pillButtonSecondaryButtonBorderRadius:
      colorScheme.secondaryButtonBorderRadius,
    pillButtonSecondaryTextColor: colorScheme.whiteColor,
    pillButtonTertiaryBackgroundColor: '#f8f7f7',
    pillButtonTertiaryTextColor: colorScheme.primaryColor,
    primaryButtonBorderRadius: colorScheme.primaryButtonBorderRadius,
    primaryButtonFillColor: colorScheme.primaryButtonFillColor,
    progressBarColor: colorScheme.primaryColor,
    propertyBrokerageLinkColor: colorScheme.linkOnWhite,
    propertyCardColor: colorScheme.primaryColor,
    propertyCardMinHeight: '',
    propertyInfoPDPMobileToggleColor: colorScheme.linkOnWhite,
    propertyInfoTableBackground: colorScheme.whiteColor,
    propertyIntroSeeAllDetailsLinkColor: colorScheme.linkOnWhite,
    propertyListingPhoneColor: colorScheme.linkOnWhite,
    regressionLineChartHomeScatterPlotColor: '#4a4a4a',
    rentalYieldBackgroundColor: colorScheme.primaryColor,
    rentalYieldTextColor: colorScheme.whiteColor,
    resumeRecentSearchComponentBorderRadius: '14px',
    savedAndAlertsHeaderActiveItemUnderlineColor: colorScheme.primaryColor,
    saveSearchCTABackground: colorScheme.darkGreyColor,
    saveSearchCTABorderRadius: '8px',
    saveSearchDesktopButtonColor: colorScheme.primaryColor,
    saveSearchDesktopButtonFontWeight: 900,
    saveSearchFloatingButtonColor: colorScheme.secondaryColor,
    saveSearchFloatingButtonSuccessColor: colorScheme.primaryColor,
    searchListControlsColor: colorScheme.darkGreyColor,
    secondaryButtonBorderRadius: colorScheme.secondaryButtonBorderRadius,
    secondaryButtonFillColor: colorScheme.secondaryButtonFillColor,
    selectedSortOptionColor: colorScheme.primaryColor,
    supportUrl: colorScheme.supportUrl || null,
    textAreaBorder: colorScheme.primaryColor,
    textButtonLinkColor: colorScheme.secondaryColor,
    tooltipIconColorOnHover: colorScheme.primaryButtonFillColor,
    watchlistHeaderItemColor: colorScheme.midGreyColor,
    welcomePageColor: colorScheme.primaryColor,
    savedSearchCardButtonStyle: {},
    lighterDisclosureAsteriskColor: '',
    CHOLoanOfficerPDPContactLOLinkColor: colorScheme.primaryColor,
    MobileSearchHomesBackButtonTextColor: '#4A4A4A',
    partnerAgentBannerGradientColor: `linear-gradient(115deg, rgba(6, 48, 64, 1) 23%, rgba(6,48,64,0.5) 90%), url('${partnerAgentImage}')`,
    partnerAgentCardGradientColor: `linear-gradient(360deg, rgba(6, 48, 64, 1) 0%, rgba(6,48,64,0.3) 100%), url('${partnerAgentImage}')`,
    partnerAgentBackgroundColor: 'rgba(6, 48, 64, 1)',
    pillButtonCustomButtonMinWidth: '',
    pillButtonCustomButtonWidth: '',
  };
}

export const COBRANDED_STYLE_DEFS = {
  [COBRAND_IDS.WWW]: getComeHomeStyleDefs,
  [COBRAND_IDS.CHASE]: getChaseStyleDefs,
  [COBRAND_IDS.CALIBERHOMELOANS]: getCaliberHomeLoansStyleDefs,
  [COBRAND_IDS.SHOPFORHOMES]: getShopForHomesStyleDefs,
};

/**
 * Given a cobrandId, return the respective styles, components, or utils for the cobrand
 */
export function getCobrandStyles(
  cobrandId: CobrandId | string,
  domainTheme: BaseDomainTheme
): StyleDefMapping {
  if (COBRANDED_STYLE_DEFS[cobrandId]) {
    return COBRANDED_STYLE_DEFS[cobrandId](domainTheme);
  } else {
    return getBaseStyleDefs(domainTheme);
  }
}
