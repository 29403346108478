import { omit } from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { authModalShow } from '@client/store/actions/auth.actions';
import {
  CHASE_CTA_CLICK,
  CHASE_LENDER_CTA_CLICK,
  CHASE_OPEN_IN_EXTERNAL_BROWSER,
  CHASE_WEB_REDIRECT,
  ChaseCTAClickAction,
  ChaseLenderCTAClickAction,
  ChaseOpenInExternalBrowser,
} from '@client/store/actions/cobranding.actions';
import { addToWatchListSaga } from '@client/store/sagas/watchlist.saga';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import {
  getCustomizationData,
  getIsInsideNativeApp,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  ChaseCTAEventParams,
  getIsAreaTypeParams,
  getIsPropertyTypeParams,
  getIsPropertyTypeWithNoPropertyIdParams,
} from '@client/store/types/chase-integration-data';
import { reportToSentry } from '@client/utils/error.utils';
import { watchEvery } from '@client/utils/saga.utils';
import { PropertyType } from './queries/types';

export const PROPERTY_TYPE_TO_CHASE_PROPERTY_TYPE = {
  /* Our GQL property types to property types accepted by the Chase JSBridge */
  COMMERCIAL: 'commercial' as 'commercial',
  COOP: 'coop' as 'coop',
  SINGLE_FAMILY_ATTACHED: 'single_family_attached' as 'single_family_attached',
  VACANT_LOT: 'vacant_lot' as 'vacant_lot',
  MANUFACTURED_HOME: 'manufactured' as 'manufactured',
  MULTI_FAMILY: 'multi_family' as 'multi_family',
  TIMESHARE: 'timeshare' as 'timeshare',
  SINGLE_FAMILY_DETACHED: 'single_family_detached' as 'single_family_detached',
  CONDO: 'condo' as 'condo',
  OTHER: 'other' as 'other',
};

const handleWebRedirect = (url: string): void => {
  window.location.href = url;
};

function* showChaseAuthModalThenRedirect(url: string) {
  const shouldRedirectToCTADestination = yield select(
    getIsFeatureEnabled('chase_auth_modal_redirects_to_cta_destinations')
  );

  if (shouldRedirectToCTADestination) {
    yield put(
      /* Note: we're overloading the standard afterAuthAction paradigm within the Chase cobrand for a flow unique to Chase:
       * Rather than dispatching this action after auth, we'll be acting on it with AuthModalChase.tsx when the user clicks
       * through the modal. The user will be taken to the CTA's configured destination rather than the generic Chase login page. */
      authModalShow({
        afterAuthAction: {
          type: CHASE_WEB_REDIRECT,
          payload: {
            url,
          },
        },
      })
    );
  } else {
    yield put(authModalShow());
  }
}

export const getJSBridgePropertyTypeForPropertyType = (
  propertyType: PropertyType | null
):
  | (typeof PROPERTY_TYPE_TO_CHASE_PROPERTY_TYPE)[keyof typeof PROPERTY_TYPE_TO_CHASE_PROPERTY_TYPE]
  | null => {
  // rename?
  return propertyType
    ? PROPERTY_TYPE_TO_CHASE_PROPERTY_TYPE[propertyType]
    : null;
};

interface RedirectUrl {
  propertyId?: string;
  hostname: string | null;
  isTestChaseCtaDest?: boolean;
}

export const getSeeLoanOptionsRedirectUrl = ({
  propertyId,
  hostname,
  isTestChaseCtaDest,
}: RedirectUrl) => {
  const propertyIdQueryParam =
    propertyId !== undefined ? `;chPropertyId=${propertyId}` : '';

  /**
   * Note:
   * This url will be different on demo.
   * Dev, QA, and Prod will have the same URL => ".../web/auth/dashboard#/dashboard/myHome/cmhFinanceOptions/finance;propertyFound=..."
   * Demo => ".../web/auth/dashboard#/dashboard/myhomerates/mortgageCalculator/purchase?propertyFound.."
   * Once Chase validate in Demo, then we'll likely be making a change to
   * their production CTA destination URL structure (likely mid-May)
   */
  if (isTestChaseCtaDest) {
    return `${hostname}/web/auth/dashboard#/dashboard/myhomerates/mortgageCalculator/purchase?propertyFound=${
      propertyId !== undefined
    }${propertyId !== undefined ? `&chPropertyId=${propertyId}` : ''}`;
  } else {
    return `${hostname}/web/auth/dashboard#/dashboard/myHome/cmhFinanceOptions/finance;propertyFound=${
      propertyId !== undefined
    }${propertyIdQueryParam}`;
  }
};

const getOfferLetterRedirectUrl = ({ propertyId, hostname }: RedirectUrl) =>
  `${hostname}/web/auth/myhomehub#/myhomehub/myHome/approvalLetter/index;propertyFound=true;chPropertyId=${propertyId}`;

export const getOverviewRedirectUrl = ({
  hostname,
}: {
  hostname: string | null;
}) => `${hostname}/web/auth/nav?navKey=requestHomeOverview`;

const getNavKeyRedirectUrl = ({
  navKey,
  hostname,
}: {
  navKey: ChaseCTAEventParams['destination'];
  hostname: string | null;
}) => `${hostname}/web/auth/nav?navKey=${navKey}`;

/* This Chase-supplied URL allows a Chase user to sign in on Chase's site. After signing in, Chase then
 * redirects the user back to ComeHome via the SAML idP flow */
export const getAuthenticatonRedirectUrl = ({
  hostname,
}: {
  hostname: string | null;
}) => `${hostname}/web/auth/nav?navKey=requestChaseMyHouseCanary`;

const getChaseRedirectUrl = ({
  params,
  hostname,
  isTestChaseCtaDest,
}: {
  params: ChaseCTAEventParams;
  hostname: string | null;
  isTestChaseCtaDest?: boolean;
}): string | undefined => {
  switch (params.destination) {
    case 'view_offer_letter':
      /* case when navigating to see letter url from PDP */
      if (getIsPropertyTypeParams(params) && params.ch_property_id) {
        return getOfferLetterRedirectUrl({
          propertyId: params.ch_property_id,
          hostname,
        });
      }
      return;
    case 'LOAN_OPTIONS':
      /* case when navigating to see loans option url from CantFindAddressModal or NoMLSModalChase */
      if (
        (getIsPropertyTypeWithNoPropertyIdParams(params) &&
          !params.ch_property_id) ||
        getIsAreaTypeParams(params)
      ) {
        return getSeeLoanOptionsRedirectUrl({ hostname, isTestChaseCtaDest });
        /* case when navigating to see loan option url from PDP */
      } else if (getIsPropertyTypeParams(params) && params.ch_property_id) {
        return getSeeLoanOptionsRedirectUrl({
          propertyId: params.ch_property_id,
          hostname,
          isTestChaseCtaDest,
        });
      }
      return;
    case 'requestChaseMyHomeManualAddress':
      return getNavKeyRedirectUrl({ navKey: params.destination, hostname });
    default:
      return;
  }
};

/**
 * Handle click of a Chase-specific CTA, either redirecting to a Chase URL if in the web experience or invoking
 * a JSBridge method if inside Chase's native app
 */
export function* handleChaseCTAClick(action: ChaseCTAClickAction) {
  const params = action.payload.params;
  const isJSBridgeEventTypeEnabled = yield select(
    getIsFeatureEnabled('jsbridge_event_type')
  );
  const isTestChaseCtaDest = (yield select(
    getIsFeatureEnabled('test_chase_cta_dest')
  )) as boolean;
  const effectiveParams = isJSBridgeEventTypeEnabled
    ? params
    : omit(params, ['event_type']);
  const isLoggedIn = (yield select(getIsLoggedIn)) as boolean;
  const isInsideNativeApp = (yield select(getIsInsideNativeApp)) as boolean;
  const { cobrand_cta_redirect_hostname } = (yield select(
    getCustomizationData
  )) as ReturnType<typeof getCustomizationData>;

  if (getIsPropertyTypeParams(params) && params.ch_property_id && isLoggedIn) {
    yield call(addToWatchListSaga, {
      payload: { slug: params.ch_property_id },
    });
  }

  /* If we're within an iOS or Android native app */
  if (
    isInsideNativeApp &&
    typeof ThirdPartyWVToNativeChaseJSBridgeHandler !== 'undefined'
  ) {
    /* Update params with expected structure and stringify response */
    const propertyId = effectiveParams['ch_property_id'];
    const jsBridgeParams = {
      navKey: effectiveParams.destination,
      propertyId,
      propertyFound: propertyId !== undefined,
      params: effectiveParams,
    };

    yield call(
      [
        ThirdPartyWVToNativeChaseJSBridgeHandler,
        ThirdPartyWVToNativeChaseJSBridgeHandler.goToNativePage,
      ],
      jsBridgeParams
    );
  } else {
    /* All Chase CTAs will redirect to some Chase Url
     * - Chase's auth modal will open if user is logged out, after which redirect will occur when modal is clicked through
     * - Redirect will happen immediately if user is logged in
     */
    const url = getChaseRedirectUrl({
      params,
      hostname: cobrand_cta_redirect_hostname as string | null,
      isTestChaseCtaDest,
    });
    if (!url) {
      reportToSentry('No redirect URL found for a Chase CTA click', { params });
      return;
    }

    yield isLoggedIn
      ? handleWebRedirect(url)
      : showChaseAuthModalThenRedirect(url);
  }
}

/**
 * Handle clicks on "Lender CTA" & "Grant CTA" CTAs (which have web-app redirect URLs and native app nav keys configured in Parcon),
 * either redirecting to a Chase URL if in the web experience or invoking a JSBridge method if inside Chase's native app
 *
 * Note: Currently Chase is only making use of the "SRP" Lender CTA, as Chase uses a custom CTA component on the PDP
 * and doesn't have the homeowner portion of the site enabled. This may change in the future.
 */
export function* handleChaseLenderCTAClick(action: ChaseLenderCTAClickAction) {
  const { webRedirectUrl, nativeAppNavKey } = action.payload;
  const isLoggedIn = (yield select(getIsLoggedIn)) as boolean;
  const isInsideNativeApp = (yield select(getIsInsideNativeApp)) as boolean;

  /* If we're within an iOS or Android native app */
  if (
    isInsideNativeApp &&
    typeof ThirdPartyWVToNativeChaseJSBridgeHandler !== 'undefined'
  ) {
    const jsBridgeParams = {
      navKey: nativeAppNavKey,
    };

    yield call(
      [
        ThirdPartyWVToNativeChaseJSBridgeHandler,
        ThirdPartyWVToNativeChaseJSBridgeHandler.goToNativePage,
      ],
      jsBridgeParams
    );
  } else {
    yield isLoggedIn
      ? handleWebRedirect(webRedirectUrl)
      : showChaseAuthModalThenRedirect(webRedirectUrl);
  }
}

/**
 * Handles the action to open a Chase URL in an external browser.
 * @generator
 * @param {ChaseOpenInExternalBrowser} action - The action to handle.
 * @yields {CallEffect} The effect to call the appropriate JS bridge handler to open the URL in an external browser.
 * @yields {WindowOpenEffect} The effect to open the URL in a new window if not inside the native app.
 */
export function* handleChaseOpenInExternalBrowser(
  action: ChaseOpenInExternalBrowser
) {
  const isInsideNativeApp = (yield select(getIsInsideNativeApp)) as boolean;
  const { url } = action.payload;
  // Chase has told us that speedBump is always false, so it is hardcoded here
  const openInExternalBrowserProps = { url, speedBump: false };
  const isExternalBrowserEnabled = yield select(
    getIsFeatureEnabled(
      'chase_brokerage_page_externalbrowser_jsbridge_function'
    )
  );

  if (
    isExternalBrowserEnabled &&
    isInsideNativeApp &&
    typeof ThirdPartyWVToNativeChaseJSBridgeHandler !== 'undefined'
  ) {
    yield call(
      [
        ThirdPartyWVToNativeChaseJSBridgeHandler,
        ThirdPartyWVToNativeChaseJSBridgeHandler.externalBrowser,
      ],
      openInExternalBrowserProps
    );
  } else {
    window.open(url, '_blank');
  }
}

export default function registerSharePropertySaga(sagaMiddleware) {
  watchEvery(sagaMiddleware, {
    [CHASE_CTA_CLICK]: handleChaseCTAClick,
    [CHASE_LENDER_CTA_CLICK]: handleChaseLenderCTAClick,
    [CHASE_OPEN_IN_EXTERNAL_BROWSER]: handleChaseOpenInExternalBrowser,
  });
}
