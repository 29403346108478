import { View } from '@client/routes/constants';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { ReduxState } from '@client/store/types/redux-state';
import { createSelector } from 'reselect';

/* These selectors get values from `cobranding` Redux state, and don't mess with `cobrand-utils.ts`,
 * which could introduce circular dependencies */
export const getCobrandingState = (state: ReduxState) => state.cobranding;

export const getCobrandId = createSelector(
  getCobrandingState,
  (cobrandingState) => cobrandingState.id
);

export const getDomainTheme = createSelector(
  getCobrandingState,
  (cobrandingState) => cobrandingState.domainScheme
);

export const getYourTeam = createSelector(
  getDomainTheme,
  (cobrandingState) => cobrandingState.yourTeam
);

export const getCobrandDisplayName = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.displayName
);

export const getCobrandAuthScheme = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.authScheme
);

export const getBackToSiteLinkAndText = createSelector(
  getDomainTheme,
  (domainScheme) => ({
    homeSiteUrl: domainScheme.homeSiteUrl,
    backToSiteText: domainScheme.backToSiteText,
  })
);

export const getNavLinkLabels = createSelector(
  getDomainTheme,
  (domainScheme) => ({
    navLinkSearchLabel: domainScheme.navLinkSearchLabel,
    navLinkHomeownerLabel: domainScheme.navLinkHomeownerLabel,
    navLinkWatchlistLabel: domainScheme.navLinkWatchlistLabel,
    navLinkAlertsLabel: domainScheme.navLinkAlertsLabel,
  })
);

export const getLenderCtasConfig = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.lenderCtasConfig
);

export const getLoCardConfig = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.loCardConfig
);

export const getGenericLOInfo = createSelector(
  getDomainTheme,
  (domainScheme) => {
    const genericLOInfo = domainScheme.genericLOInfo;
    return {
      name: domainScheme.displayName,
      email: genericLOInfo?.email || null,
      phone: genericLOInfo?.phone || null,
      nmlsId: genericLOInfo?.nmlsId ? `NMLS #${genericLOInfo.nmlsId}` : null,
    };
  }
);

export const getPartialHeaderLinks = createSelector(
  getDomainTheme,
  (domainScheme) => {
    const headerLinks = domainScheme.headerLinks;
    if (headerLinks) {
      // filter out headerLinks with empty text & url
      let headerLinksArr = headerLinks.filter((item) => item.text && item.url);
      if (headerLinks.length > 3) {
        /**
         * API used to create headerLinks does not have a max limit
         * On the UI, we can only support maximum 3 links with current designs
         */
        headerLinksArr = headerLinks.slice(0, 3);
      }
      return headerLinksArr.map((item) => {
        return {
          title: item.text,
          key: item.text,
          link: item.url,
          /* route defined to ease typing */
          route: View.SEARCH,
        };
      });
    } else {
      return null;
    }
  }
);

export const getFooterSubsectionDetails = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.footer
);

export const getDisclosureText = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.disclosureText
);

export const getEstimatedPaymentDisclosureText = createSelector(
  getDisclosureText,
  (disclosureText) => disclosureText.estimatedPayment
);

export const getMortgageCalculatorDisclosureText = createSelector(
  getDisclosureText,
  (disclosureText) => disclosureText.mortgageCalculator
);

export const getRefiCalculatorDisclosureText = createSelector(
  getDisclosureText,
  (disclosureText) => disclosureText.refiCalculator
);

const getCustomDisclaimers = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme?.customDisclaimers
);

export const getCustomEstimatedValueDisclaimer = createSelector(
  getCustomDisclaimers,
  (disclaimers) => ({
    label: disclaimers.estimated_value_disclaimer_label,
    content: disclaimers.estimated_value_disclaimer_content,
  })
);

export const getHasEstimatedValueDisclaimer = createSelector(
  getCustomDisclaimers,
  (disclaimers) =>
    disclaimers.estimated_value_disclaimer_content &&
    disclaimers.estimated_value_disclaimer_label
);

export const getCustomMortgageCalculatorDisclaimer = createSelector(
  getCustomDisclaimers,
  (disclaimers) => ({
    label: disclaimers.mortgage_calculator_disclaimer_label,
    content: disclaimers.mortgage_calculator_disclaimer_content,
  })
);

export const getHasMortgageCalculatorDisclaimer = createSelector(
  getCustomDisclaimers,
  (disclaimers) =>
    disclaimers.mortgage_calculator_disclaimer_content &&
    disclaimers.mortgage_calculator_disclaimer_label
);

/* Whether the app is running inside a webview inside a native iOS or Android app */
export const getIsInsideNativeApp = createSelector(
  getCobrandingState,
  (cobrandingState) => cobrandingState.isInsideNativeApp
);

export const getShouldAuthenticateViaNativeAppOnMount = createSelector(
  getCobrandingState,
  (cobrandingState) => cobrandingState.authenticateViaNativeAppOnMount
);

export const getSupportUrl = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.supportUrl
);

export const getCustomizationData = createSelector(
  getDomainTheme,
  (domainScheme) => domainScheme.customizationData
);

export const getDomainThemeWhiteColor = createSelector(
  getDomainTheme,
  (theme) => theme.whiteColor
);

export const getDomainThemePrimaryColor = createSelector(
  getDomainTheme,
  (theme) => theme.primaryColor
);

export const getMapPlaceBoundaryLayerLineColor = createSelector(
  getDomainTheme,
  (theme) => theme.mapPlaceBoundaryLayerLineColor
);

export const getSearchAdCardContent = createSelector(
  getCustomizationData,
  (customizationData) => customizationData.search_ad_card_content
);

export const getYourTeamCustomButtonText = createSelector(
  getCustomizationData,
  (customizationData) => customizationData.your_team_button_custom_text
);

export const getIsSearchListViewDefault = createSelector(
  getCustomizationData,
  (customizationData) => customizationData.is_search_list_view_default
);

export const getShouldShowLocalActivitiesBreakoutSection = createSelector(
  getCustomizationData,
  (customizationData) =>
    customizationData.should_show_local_activities_breakout_section
);

export const getShouldDisplayEmailIssueBanners = createSelector(
  getCustomizationData,
  (customizationData) => customizationData.should_display_email_issue_banners
);

export const getShouldShowConnectWithATopLocalAgent = createSelector(
  getCustomizationData,
  (customizationData) =>
    customizationData.should_show_connect_with_a_top_local_agent
);

export const getShouldUsePatternForMLSCoverageLayer = createSelector(
  getCustomizationData,
  (customizationData) =>
    customizationData.should_use_pattern_for_mls_coverage_layer
);

export const getLenderSpecificRedirectUrlForAddressNotFound = createSelector(
  getCustomizationData,
  (customizationData) =>
    customizationData.lender_specific_redirect_url_for_address_not_found
);

export const getSessionKeepAliveUrl = createSelector(
  getCustomizationData,
  (customizationData) => customizationData.session_keep_alive_url
);

export const getSessionLogoutUrl = createSelector(
  getCustomizationData,
  (customizationData) => customizationData.session_logout_url
);

export const getTopLocalAgentAdDetails = createSelector(
  getCustomizationData,
  (customizationData) => customizationData.top_local_agent_ad_details
);

/* Needed for use in AutoComplete.tsx to avoid needing to cobrand the entire component, which would
 * add significant complexity */
export const getUseChaseSpecificCantFindAddressRedirect = createSelector(
  getCobrandId,
  (cobrandId) => cobrandId === COBRAND_IDS.CHASE
);

/* Note: Please don't follow this pattern. Instead, use custom cobranded components or Parcon-defined
 * config data when possible */
export const getIsWellsFargoCobrand = createSelector(
  getCobrandId,
  (cobrandId) =>
    cobrandId === COBRAND_IDS.SHOPFORHOMES ||
    cobrandId === COBRAND_IDS.SHOPFORHOMESLO ||
    cobrandId === COBRAND_IDS.SFHTWO ||
    cobrandId === COBRAND_IDS.WELLSFARGO
);

/* Note: Please don't follow this pattern. Instead, use custom cobranded components or Parcon-defined
 * config data when possible */
export const getIsComehomeBrandedLODirect = createSelector(
  getCobrandId,
  (cobrandId) => cobrandId === COBRAND_IDS.WWW
);

export const getGrantProgramConfig = createSelector(
  getDomainTheme,
  (theme) => theme.grantProgram
);

export const getPropertyDetailPageConfig = createSelector(
  getDomainTheme,
  (theme) => theme.pdp
);

export const selectContactBrokerage = createSelector(
  getDomainTheme,
  (theme) => theme.contactBrokerage
);

export const getWatchlistPageConfig = createSelector(
  getDomainTheme,
  (theme) => theme.watchlist
);

export const getSearchResultsPageConfig = createSelector(
  getDomainTheme,
  (theme) => theme.searchResultsPage
);

export const getSaveSearchButtonConfig = createSelector(
  getDomainTheme,
  (theme) => {
    const { saveSearchButtonIcon, saveSearchButtonLabel } =
      theme.searchResultsPage;
    return {
      saveSearchButtonIcon,
      saveSearchButtonLabel,
    };
  }
);

export const selectHomeownerPageConfig = createSelector(
  getDomainTheme,
  (theme) => theme.homeownerWelcomePage
);

export const selectBreakoutSectionOrder = createSelector(
  getPropertyDetailPageConfig,
  (pdp) => {
    if (pdp.breakoutSections) {
      return pdp.breakoutSections[0].section_content_ids;
    }
    return null;
  }
);
