import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { debounce, get } from 'lodash';
import React from 'react';
import { findDOMNode } from 'react-dom';

import renderOnMount from '@client/hocs/render-on-mount';
import CloseIcon from '@client/inline-svgs/close';
import LocationIcon from '@client/inline-svgs/location';
import SearchSvg from '@client/inline-svgs/search';
import { SEARCH_CITY_ADDRESS_INPUT_PLACEHOLDER } from '@client/store/constants';
import {
  AddressResult,
  isAddressResult,
  isAddressWithNonNullText,
  isPlaceResult,
  isPlaceWithNonNullText,
  isRecentPropertyItem,
  isSavedSearch,
  isStaticElement,
  PlaceResult,
  Result,
  SavedSearch,
  StaticElement,
} from '@client/store/types/search';
import {
  buildHighlightedText,
  getCommonUnitIndicatorFromSearchResults,
  getQueryStringMatchers,
  replaceAccentedCharacters,
} from '@client/utils/address-autocomplete.utils';
import { localStorageUtil } from '@client/utils/local-storage.utils';

import TextInput from '@client/components/generic/TextInput';
import defaultTheme from '@client/css-modules/AutoComplete.css';
import { ActiveAddressSearchLabelPosition } from '@client/store/types/cobranding';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  disableBodyScroll,
  enableBodyScroll,
} from '@client/utils/body-scroll-lock';
import { generateGenericUid } from '@client/utils/component.utils';

import AutoCompleteCantFindAddressLinkCobranded from '@client/components/AutoCompleteCantFindAddressLink/AutoCompleteCantFindAddressLinkCobranded';
import AutoCompleteNoResultsLinkCobranded from '@client/components/AutoCompleteNoResultsLink/AutoCompleteNoResultsLinkCobranded';
import ChevronIconCobranded from '@client/components/ChevronIcon/ChevronIconCobranded';
import CobrandedStyles from '@client/components/CobrandedStyles';
import ConditionalFeature from '@client/components/ConditionalFeature';
import RecentPropertyWithGQLFetch from '@client/components/RecentUserActivity/RecentPropertyWithGQLFetch';
import RecentUserActivityCTA from '@client/components/RecentUserActivity/RecentUserActivityCTA';
import ScrollActiveElementIntoView from '@client/components/ScrollActiveElementIntoView';
import { RecentPropertyResponseItem } from '@client/store/slices/recent-user-activity.slice';

/* Generates a list of results based on user input and executes a
 * callback function when one of those results are selected using the
 * keyboard or mouse.
 */
const REQUEST_DEBOUNCE_DELAY = 300;
const DEFAULT_MIN_CHARS = 2;
const DROPDOWN_ANIMATION_VARIANTS = {
  animate: {
    height: 'auto',
    transition: {
      duration: 0.35,
      easing: 'easeInOut',
    },
  },
  exit: {
    height: 0,
    transition: {
      duration: 0,
      easing: 'easeInOut',
    },
  },
};
const RESULT_ANIMATION_VARIANTS = {
  exit: {
    opacity: 0,
    y: '-50%',
  },
  animate: {
    opacity: 1,
    y: '0%',
    transition: {
      delay: 0.2,
      duration: 0.5,
      easing: 'easeIn',
    },
  },
};
const NO_RESULTS_LINK_ANIMATION_VARIANTS = {
  exit: {
    opacity: 0,
    y: '-50%',
  },
  animate: {
    opacity: 1,
    y: '0%',
    transition: {
      delay: 0.4,
      duration: 0.5,
      easing: 'easeIn',
    },
  },
};

type SuggestionProp = {
  theme: Theme;
  isActive: boolean;
  count?: number | null;
  text: string;
  queryStringMatchers: RegExp[];
  children?: React.ReactNode;
};

const Suggestion: React.FC<SuggestionProp> = ({
  theme,
  isActive,
  count,
  text,
  queryStringMatchers,
  children,
}) => {
  return (
    <ScrollActiveElementIntoView theme={theme} isActive={isActive}>
      <div
        className={classNames(theme.Suggestion, {
          [theme['SuggestionActive']]: isActive,
        })}
      >
        {children}
        {text && (
          <span className={theme.SuggestionText}>
            {buildHighlightedText(text, queryStringMatchers)}
          </span>
        )}
        {count && (
          <div className={theme.SuggestionMultiUnit}>
            Multi Unit
            <ChevronIconCobranded className={theme.SuggestionChevronIcon} />
          </div>
        )}
      </div>
    </ScrollActiveElementIntoView>
  );
};

export type AutoCompleteProps = {
  id?: string;
  handleFetchRecentProperties?: () => void;
  recentProperties?: RecentPropertyResponseItem[];
  isRecentUserActivityEnabled?: boolean;
  addressResults: AddressResult[];
  activeAddressSearchLabelPosition?: ActiveAddressSearchLabelPosition;
  placeResults: PlaceResult[];
  minCharsStart?: number;
  /* placeholder defaults to SEARCH_CITY_ADDRESS_INPUT_PLACEHOLDER unless `null` is passed */
  placeholder?: string | null;
  invalidCharactersText?: string;
  isLoadingResults: boolean;
  getAddressListGroupedByBuilding?: () => void;
  handleClearPlaces: () => void;
  handleFetchPlaces: (rawValue: string, buildingId?: number | null) => void;
  handleFetchSavedSearches: () => void;
  /* handleNavigateToResult is functionality handled within the container, while onResultClick is passed as ownProps */
  handleNavigateToResult: (item: Result, activePropertySlug?: string) => void;
  activePropertySlug?: string;
  onResultClick?: (item: Result | null) => void;
  handleSavedSearchClick: (searchId: number) => void;
  focusInputOnMount?: boolean;
  /* Callback that executes external functionality upon clearing the input when populated with text */
  handleClearPopulatedInput?: () => void;
  /* Callback that executes external functionality upon clearing the input when empty */
  handleClearEmptyInput?: () => void;
  /* Provides the ability to define the input value externally */
  prefilledUserInput?: string;
  shouldDisplayCurrentLocationOption?: boolean;
  shouldHidePDPModalOnFocus?: boolean;
  shouldPersistResultsOnBlur: boolean;
  hidePlaceResults?: boolean;
  handleSelectCurrentLocation: () => void;
  handleFocus: (
    e: React.FocusEvent<HTMLInputElement>,
    arg: {
      shouldHidePDPModalOnFocus?: boolean;
      shouldHideMultiUnitPropertiesOnFocus?: boolean;
    }
  ) => void;
  handleBlur: <E extends React.SyntheticEvent>(
    e: E,
    shouldInvalidate?: boolean
  ) => void;
  handleShowCantFindAddressModal: () => void;
  handleClickSearchButton?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  clearAndCloseWithOneClick?: boolean;
  handleKeyDown?: (key: number | string) => void;
  marginFromPageBottom?: number;
  isStaticallyPositioned?: boolean;
  preventDefaultResultClickFunctionality?: boolean;
  onInputMouseDown?: () => void;
  /* Callback fired after no results, doesn't effect functionality  */
  handleNoResultsForQuery?: (userInput: string) => void;
  handleEnterKeypress: () => void;
  handleViewSearchActivityClick?: () => void;
  handleInputError?: (errObj: object) => void;
  onInputChangeCallback?: (input: string, reportEvent: boolean) => void;
  shouldInvalidateMapSearchMapSizeOnSearchFieldBlur: boolean;
  shouldHideMultiUnitPropertiesOnFocus?: boolean;
  theme: Theme;
  shouldShowBackgroundScreenBehindResults?: boolean;
  withoutBackgroundScreen?: boolean;
  useMaxHeightFallback?: boolean;
  dataHcName?: string;
  belowResultsComponent?: JSX.Element;
  /* to determine if the DisableBodyScroll will be set when results list doesn't extend or not */
  shouldNotDisableBodyScroll?: boolean;
  hasVisibleLabel?: boolean;
  lenderSpecificRedirectUrlForAddressNotFound?: string | null;
  useBackgroundScreenForBlur?: boolean;
  shouldCalculateMaxHeight?: boolean;
  hideSavedSearches?: boolean;
  hideSubmitButton?: boolean;
  hideSearchButton?: boolean;
  noSearchIconBackground?: boolean;
  /* Only used if hideSavedSearches is false */
  savedSearches?: SavedSearch[];
  className?: string;
  useChaseCantFindAddressRedirect?: boolean;
  handleChaseCantFindAddressRedirect?: () => void;
  label?: string;
  alwaysShowLabel?: boolean;
  inputStyle?: React.CSSProperties;
  inputStyleWithDropdown?: React.CSSProperties;
  searchButtonStyle?: React.CSSProperties;
  searchButtonWithDropdown?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  disabled?: boolean;
  inputId?: string;
  closeButtonId?: string;
  submitButtonId?: string;
};

type AutoCompleteState = {
  userInput: string;
  activeResult: Result | SavedSearch | RecentPropertyResponseItem | null;
  isShowingNoResultsHelperLink: boolean;
  userInputHasInvalidCharacters: boolean;
  selectedBuildingId: number | null;
  isFocused: boolean;
  showAccessibilityBorder: boolean;
  isAriaMessagingReady: boolean;
  resultsText: string;
};

export class AutoComplete extends React.Component<
  AutoCompleteProps,
  AutoCompleteState
> {
  autoCompleteTextInputRef: React.RefObject<HTMLInputElement> =
    React.createRef();

  state: AutoCompleteState = {
    userInput: this.props.prefilledUserInput || '',
    activeResult: null,
    isShowingNoResultsHelperLink: false,
    userInputHasInvalidCharacters: false,
    selectedBuildingId: null,
    // Track whether input is currently focused.
    isFocused: false,
    showAccessibilityBorder: false,
    isAriaMessagingReady: false,
    resultsText: '',
  };

  dropdownEleUid = generateGenericUid();
  inputEleUid = generateGenericUid();
  cantFindAddressEleUid = generateGenericUid();
  currentLocationEleUid = generateGenericUid();
  viewSearchActivityEleUid = generateGenericUid();

  debounceMakeSearchRequest = debounce(
    (rawValue: string, buildingId?: number | null): void => {
      this.props.handleFetchPlaces(
        replaceAccentedCharacters(rawValue),
        buildingId
      );
      this.setState({
        isShowingNoResultsHelperLink: false,
        selectedBuildingId: buildingId || null,
      });
    },
    REQUEST_DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  );
  invalidateMapSearchMapSizeTimeout: number = 0;
  fakeInputEle: HTMLElement | null = null;

  // Whether we want to trigger a new search on focus.  Needed to prevent this
  // from happening in the midst of selecting a multi-unit property.
  triggerSearchOnFocus = true;
  setResultsMaxHeightTimeout: number = 0;

  // Timeout to control if aria messaging is ready. Need a slight delay from when
  // focus is set to when the messaging is ready so any messaging needed will be
  // read on initial focus.
  ariaMessagingReadyTimeout: number = 0;

  // Whether we want to hide results when the input field is blurred. Needed
  // to prevent the results from being hidden before a result click has completed.
  hideResultsOnInputBlur = true;
  selectFirstResultAfterLoad = false;
  resultsEle: HTMLElement | null = null;
  debouncedHandleNoResultsForQuery = this.props.handleNoResultsForQuery
    ? debounce(this.props.handleNoResultsForQuery, 300, {
        leading: false,
        trailing: true,
      })
    : null;

  componentDidMount() {
    const {
      focusInputOnMount,
      prefilledUserInput,
      handleFetchSavedSearches,
      onInputChangeCallback,
    } = this.props;

    /* Get this upfront so that we can pass to places search API call */
    handleFetchSavedSearches();
    /* Focus on the search input once a user clicks on the search icon to open the search input box */
    if (focusInputOnMount) {
      this.triggerFocus();
    }

    if (onInputChangeCallback && prefilledUserInput) {
      onInputChangeCallback(prefilledUserInput, false);
    }
  }

  componentDidUpdate(
    prevProps: AutoCompleteProps,
    prevState: AutoCompleteState
  ) {
    const {
      addressResults,
      placeResults,
      prefilledUserInput,
      recentProperties,
      savedSearches,
    } = this.props;
    const { userInput } = this.state;
    const resultsHaveJustLoaded =
      prevProps.isLoadingResults && !this.props.isLoadingResults;

    // If user presses Enter as results are still loading, select first result after load
    if (resultsHaveJustLoaded && this.selectFirstResultAfterLoad) {
      this.selectFirstResultAfterLoad = false;
      this.determineAndSelectResult();
    }

    // If a search result returns no results, show an indicator in the UI
    if (
      resultsHaveJustLoaded &&
      !addressResults.length &&
      !placeResults.length
    ) {
      this.setState({ isShowingNoResultsHelperLink: true, resultsText: '' });

      if (this.debouncedHandleNoResultsForQuery) {
        this.debouncedHandleNoResultsForQuery(userInput);
      }
    }
    if (
      addressResults !== prevProps.addressResults ||
      placeResults !== prevProps.placeResults ||
      recentProperties !== prevProps.recentProperties ||
      savedSearches !== prevProps.savedSearches
    ) {
      this.setState({ resultsText: this.buildInstructionalText() });
    }

    // If a user clicks on a multi-unit property, append the unit designator (i.e. "Apt")
    // to the input field value after the unit list loads
    if (
      resultsHaveJustLoaded &&
      addressResults.length &&
      this.state.selectedBuildingId &&
      this.state.userInput
    ) {
      const commonUnitIndicator =
        getCommonUnitIndicatorFromSearchResults(addressResults);
      if (commonUnitIndicator) {
        this.setInputValue(`${this.state.userInput} ${commonUnitIndicator} `);
      }
    }

    /* Update input value only if seeking to add or remove the value */
    if (
      prefilledUserInput &&
      prefilledUserInput !== prevProps.prefilledUserInput
    ) {
      this.setInputValue(prefilledUserInput);
    } else if (!prefilledUserInput && prevProps.prefilledUserInput) {
      this.setInputValue();
    }

    if (prevState.isFocused && !this.state.isFocused) {
      window.clearTimeout(this.ariaMessagingReadyTimeout);
      this.setState({ isAriaMessagingReady: false });
    }
  }

  // Prevent any `setState` calls from occurring after component is unmounted
  componentWillUnmount() {
    window.clearTimeout(this.invalidateMapSearchMapSizeTimeout);
    window.clearTimeout(this.setResultsMaxHeightTimeout);
    window.clearTimeout(this.ariaMessagingReadyTimeout);
    this.debounceMakeSearchRequest?.cancel();

    if (this.resultsEle && !this.props.isStaticallyPositioned) {
      enableBodyScroll(this.resultsEle, {
        uId: this.getBodyLockEleUId(this.resultsEle),
      });
    }
  }

  getBodyLockEleUId = (ele: Element): string =>
    `autocomplete-results-ele-${ele.id}`;

  getIsShowingDropdownStatus = (): boolean => {
    const {
      addressResults,
      placeResults,
      savedSearches,
      recentProperties,
      shouldDisplayCurrentLocationOption,
    } = this.props;
    const { userInputHasInvalidCharacters, isFocused } = this.state;
    const hasResults = !!(addressResults.length || placeResults.length);
    const isShowingDropdown =
      (isFocused && hasResults) ||
      (isFocused && savedSearches && !!savedSearches.length) ||
      (isFocused && recentProperties && !!recentProperties.length) ||
      (isFocused && shouldDisplayCurrentLocationOption) ||
      (isFocused && this.state.isShowingNoResultsHelperLink) ||
      userInputHasInvalidCharacters;

    return isShowingDropdown;
  };

  setInputValue = (rawQueryString?: string | null): void => {
    const { onInputChangeCallback } = this.props;
    const oldUserInput = this.state.userInput;

    if (!rawQueryString || !rawQueryString.trim().length) {
      this.setState({ userInput: '' });
      return;
    }
    const userInput = replaceAccentedCharacters(rawQueryString);
    this.setState({ userInput: userInput.replace(/\s{2,}/g, ' ') });

    if (onInputChangeCallback) {
      onInputChangeCallback(userInput, userInput !== oldUserInput);
    }
  };

  clearResults = (stayFocused?: boolean) => {
    this.props.handleClearPlaces();
    this.setState({
      activeResult: null,
      isShowingNoResultsHelperLink: false,
      selectedBuildingId: null,
      isFocused: !!stayFocused,
      userInputHasInvalidCharacters: false,
    });
    if (!stayFocused && this.resultsEle && !this.props.isStaticallyPositioned) {
      enableBodyScroll(this.resultsEle, {
        uId: this.getBodyLockEleUId(this.resultsEle),
      });
    }
  };

  getAllResults = (): Result[] => [
    ...this.props.placeResults,
    ...this.props.addressResults,
  ];

  getResultsChecksum = (results: Result[]) =>
    results.map((item) => item.text).join(',');

  getResultsHaveChanged = (newResults: Result[], oldResults: Result[]) => {
    return (
      this.getResultsChecksum(newResults) !==
      this.getResultsChecksum(oldResults)
    );
  };

  selectSavedSearch = (item: SavedSearch) => {
    // Execute outside functionality
    this.props.handleSavedSearchClick(item.searchId);
    // Hide result list after select
    this.clearResults();
  };

  selectResult = (item: Result): void => {
    const { activePropertySlug } = this.props;
    const { current: autoCompleteTextInput } = this.autoCompleteTextInputRef;
    // If multi-unit
    if (
      isAddressResult(item) &&
      item.count &&
      item.count > 0 &&
      item.partialLine
    ) {
      // If a selected address contains multiple units, search again without grouping by building
      this.debounceMakeSearchRequest(item.partialLine, item.buildingId);
      this.triggerSearchOnFocus = false;
      autoCompleteTextInput && autoCompleteTextInput.focus();
      // Ensure event handler runs before resetting value
      window.setTimeout(() => {
        this.triggerSearchOnFocus = true;
      }, 0);
      //  Replace input value with selected item
      this.setInputValue(item.partialLine);
      // If address or place
    } else {
      // Hide result list after select
      this.clearResults();
      // Dismiss the mobile keyboard after selecting a result via the Enter key
      autoCompleteTextInput && autoCompleteTextInput.blur();
      // Replace input value with selected item
      this.setInputValue(item.text);
      if (!this.props.preventDefaultResultClickFunctionality) {
        // Execute outside functionality
        this.props.handleNavigateToResult(item, activePropertySlug);
      } else if (this.props.onResultClick) {
        this.props.onResultClick(item);
      }
    }
    this.setState({ isShowingNoResultsHelperLink: false });
  };

  // Called on enter key press or 'search' button press
  determineAndSelectResult = (): void => {
    const {
      handleEnterKeypress,
      handleInputError,
      isLoadingResults,
      handleViewSearchActivityClick,
    } = this.props;
    let eleToSelect = isLoadingResults
      ? null
      : this.state.activeResult || this.getAllResults()[0];
    const isCantFindAddressLinkSelected =
      eleToSelect &&
      isStaticElement(eleToSelect) &&
      eleToSelect.eleId === this.cantFindAddressEleUid;
    const isCurrentLocationItemSelected =
      eleToSelect &&
      isStaticElement(eleToSelect) &&
      eleToSelect.eleId === this.currentLocationEleUid;
    const isViewSearchActivitySelected =
      eleToSelect &&
      isStaticElement(eleToSelect) &&
      eleToSelect.eleId === this.viewSearchActivityEleUid;

    if (isViewSearchActivitySelected) {
      this.clearResults();
      this.setInputValue('');
      handleViewSearchActivityClick?.();
    } else if (isCurrentLocationItemSelected) {
      this.localHandleSelectCurrentLocation();
    } else if (isCantFindAddressLinkSelected) {
      this.onNoResultsLinkClick();
    } else if (eleToSelect && isSavedSearch(eleToSelect)) {
      this.selectSavedSearch(eleToSelect);
    } else if (eleToSelect && isRecentPropertyItem(eleToSelect)) {
      this.clearResults();
    } else if (eleToSelect) {
      this.selectResult(eleToSelect);
    } else {
      if (this.state.userInput) {
        // If results are still loading and the user presses Enter,
        // wait until results load, then select the first one
        if (isLoadingResults) {
          this.selectFirstResultAfterLoad = true;
        } else {
          // If user presses enter after no results have been returned, this is no-op
        }
        // No input was entered
      } else if (handleInputError) {
        handleInputError({
          status: 500,
          message: 'address_not_supplied',
          userInput: this.state.userInput,
        });
      }
    }
    handleEnterKeypress();
  };

  resetValue = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.preventDefault();

    const {
      clearAndCloseWithOneClick,
      handleClearPopulatedInput,
      handleClearEmptyInput,
    } = this.props;
    const { userInput } = this.state;

    if (clearAndCloseWithOneClick) {
      this.setInputValue('');
      this.clearResults();
      if (handleClearPopulatedInput) {
        handleClearPopulatedInput();
      }
      if (handleClearEmptyInput) {
        handleClearEmptyInput();
      }
      this.triggerFocus();
    } else {
      if (userInput.length === 0) {
        this.clearResults();
        if (handleClearEmptyInput) {
          handleClearEmptyInput();
        }
      } else {
        this.setInputValue('');
        if (handleClearPopulatedInput) {
          handleClearPopulatedInput();
        }
        this.triggerFocus();
      }
    }
  };

  triggerFocus = () => {
    const { current: autoCompleteTextInput } = this.autoCompleteTextInputRef;
    /* On iOS devices, input focus can only be triggered during an event following a user
     * interaction OR when an input element is already focused.  So, first focus a hidden
     * input during this click handler's execution, then when we're ready transfer the focus
     * to the actual input */
    this.fakeInputEle && this.fakeInputEle.focus();
    /* Wait until next run loop since focusing causes the current input value to be
     * set on state, canceling the effect of clearing the input's value */
    window.setTimeout(() => {
      autoCompleteTextInput && autoCompleteTextInput.focus();
    }, 0);
  };

  onClickSearchButton = (e) => {
    const { handleClickSearchButton, hideSubmitButton } = this.props;
    const { userInput } = this.state;

    // If hideSearchButton is true, default to old behavior.
    // else the search button is a submit button. Perform search
    // with userInput and select the first result. Since debounceMakeSearchRequest
    // has a 300ms delay, we need to delay selecting a result until the search has
    // started.
    if (hideSubmitButton) {
      this.triggerFocus();
    } else {
      this.debounceMakeSearchRequest(userInput);
      setTimeout(() => {
        this.determineAndSelectResult();
      }, REQUEST_DEBOUNCE_DELAY);
    }
    if (handleClickSearchButton) {
      handleClickSearchButton(e);
    }
  };

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { handleClearPopulatedInput } = this.props;
    let userInput = e.target.value;

    this.setInputValue(userInput);
    this.setState({
      isShowingNoResultsHelperLink: false,
    });

    if (userInput.length === 0) {
      if (handleClearPopulatedInput) {
        handleClearPopulatedInput();
      }
      this.clearResults(true);
      return;
    }

    if (!this.getHasValidCharacters(userInput) && userInput.length > 0) {
      this.clearResults(true);
      this.setState({ userInputHasInvalidCharacters: true });
    } else {
      this.setState({ userInputHasInvalidCharacters: false });
      // Only allow request if minimum character count is met
      if (userInput.length >= (this.props.minCharsStart || DEFAULT_MIN_CHARS)) {
        this.debounceMakeSearchRequest(userInput);
      }
    }
  };

  createStaticEleDataForKeydownSelect = (
    uid: string,
    shouldShow: boolean,
    text?: string
  ) => (shouldShow ? [{ eleId: uid, text: text }] : []);

  // Handle arrow keys, esc, and enter
  onInputKeyDown = (e: React.KeyboardEvent): void => {
    /**
     * e.keyCode is deprecated
     * keeping deprecated e.keyCode logic for smooth transition
     * when no longer supported, falling back to e.key
     * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
     */
    const key = e.keyCode || e.key;
    const isUsingDeprecatedKeyCode = typeof key === 'number';
    const KEYS = {
      UP: isUsingDeprecatedKeyCode ? 38 : 'ArrowUp',
      DOWN: isUsingDeprecatedKeyCode ? 40 : 'ArrowDown',
      ESC: isUsingDeprecatedKeyCode ? 27 : 'Escape',
      ENTER: isUsingDeprecatedKeyCode ? 13 : 'Enter',
    };

    if (this.props.handleKeyDown) {
      this.props.handleKeyDown(key);
    }

    if (key === KEYS.UP || key === KEYS.DOWN) {
      e.preventDefault();
      e.stopPropagation();
      this.handleKeyUpOrDown(key === KEYS.UP);
    } else if (key === KEYS.ESC) {
      this.clearResults();
    } else if (key === KEYS.ENTER) {
      /* Prevent enter key from triggering any default, browser-specific input functionality since
       * this might interfere with the multi-unit lookup flow.  */
      e.preventDefault();
      this.determineAndSelectResult();
    }
  };

  handleKeyUpOrDown = (isKeyUp: boolean): void => {
    const { activeResult } = this.state;
    const allResults = this.getAllResultsToDisplay();

    if (allResults.length > 0) {
      const currentIndex =
        activeResult !== null
          ? isStaticElement(activeResult)
            ? allResults.findIndex(
                (result) =>
                  (result as StaticElement).eleId === activeResult.eleId
              )
            : allResults.indexOf(activeResult)
          : -1;
      const direction = isKeyUp ? -1 : 1;
      let nextEle;
      // Up arrow pressed when no currentIndex is set.
      if (currentIndex + direction < -1) {
        nextEle = allResults[allResults.length - 1];
      }
      // Up arrow pressed at list top or bottom
      else if (currentIndex + direction < 0) {
        const shiftAmount = currentIndex + direction;
        nextEle = allResults[allResults.length + shiftAmount];
        // Down arrow pressed at list bottom
      } else if (currentIndex + direction >= allResults.length) {
        nextEle = allResults[0];
      } else {
        nextEle = allResults[currentIndex + direction];
      }

      this.setInputValueOfNextEle(nextEle);
      this.setState({ activeResult: nextEle });
    }
  };

  getAllResultsToDisplay = () => {
    const {
      shouldDisplayCurrentLocationOption,
      savedSearches,
      addressResults,
      placeResults,
      isRecentUserActivityEnabled,
      recentProperties,
    } = this.props;
    const { isShowingNoResultsHelperLink } = this.state;
    const hasResults = !!(addressResults.length || placeResults.length);
    const isShowingNoResultsLink =
      (isShowingNoResultsHelperLink && !hasResults) ||
      (!isShowingNoResultsHelperLink && hasResults);

    return [
      /* Current Location */
      ...this.createStaticEleDataForKeydownSelect(
        this.currentLocationEleUid,
        !!shouldDisplayCurrentLocationOption
      ),
      /* Address Search and Place Search  */
      ...this.getAllResults(),
      /* Saved Searches */
      ...(this.getIsShowingSavedSearches() ? savedSearches! : []),
      ...(this.getIsShowingRecentlyViewed() &&
      isRecentUserActivityEnabled &&
      recentProperties
        ? recentProperties
        : []),
      ...this.createStaticEleDataForKeydownSelect(
        this.viewSearchActivityEleUid,
        this.getIsShowingRecentlyViewed() && !!isRecentUserActivityEnabled
      ),
      /* Can't find your address */
      ...this.createStaticEleDataForKeydownSelect(
        this.cantFindAddressEleUid,
        isShowingNoResultsLink
      ),
    ];
  };

  /* Only executed when navigating results by arrow key */
  setInputValueOfNextEle = (
    nextEle: AddressResult | PlaceResult | SavedSearch | StaticElement
  ) => {
    // Update input field
    // For a standard address, place the full line in the search input
    if (isAddressResult(nextEle) || isPlaceResult(nextEle)) {
      if (!nextEle.count) {
        this.setInputValue(nextEle.text);
        // For a multi-unit address, place only the partial line in the search
        // input so that we're prepared to display multiple units in the results
      } else {
        this.setInputValue(`${nextEle.partialLine} `);
      }
    }
  };

  /* TODO we should migrate away from the `blur` input element event to hide the results list and
   * toward using the background screen to do this via the `useBackgroundScreenForBlur` prop as it's
   * simpler and less prone to race conditions */
  onInputBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (
      this.hideResultsOnInputBlur &&
      /* SECURITY: localStorage does not store sensitive information here */
      /* eslint-disable-next-line custom/explain-localstorage */
      !localStorageUtil.getItem('addressSearchDebug') &&
      !this.props.shouldPersistResultsOnBlur &&
      !this.props.useBackgroundScreenForBlur
    ) {
      this.clearResults();
    }
    if (this.props.handleBlur && !this.props.useBackgroundScreenForBlur) {
      this.invalidateMapSearchMapSizeTimeout = window.setTimeout(() => {
        this.props.handleBlur(
          e,
          this.props.shouldInvalidateMapSearchMapSizeOnSearchFieldBlur
        );
      }, 300);
    }
  };

  onInputFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const {
      shouldHidePDPModalOnFocus,
      shouldHideMultiUnitPropertiesOnFocus,
      isRecentUserActivityEnabled,
      handleFetchRecentProperties,
      addressResults,
      placeResults,
    } = this.props;
    const isAccessibilityFocusAllowed =
      document.body.className.indexOf('no-focus-outline') < 0;

    this.props.handleFocus(e, {
      shouldHidePDPModalOnFocus,
      shouldHideMultiUnitPropertiesOnFocus,
    });
    if (this.triggerSearchOnFocus) {
      this.onInputChange(e);
    }

    const hasResults = !!(addressResults.length || placeResults.length);
    if (isRecentUserActivityEnabled && !hasResults) {
      handleFetchRecentProperties?.();
    }

    this.setState({
      showAccessibilityBorder: isAccessibilityFocusAllowed,
      isFocused: true,
    });

    this.ariaMessagingReadyTimeout = window.setTimeout(() => {
      this.setState({ isAriaMessagingReady: true });
    }, 270);
  };

  onBackgroundScreenClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.props.shouldPersistResultsOnBlur) {
      this.clearResults();
    }
    if (this.props.handleBlur) {
      this.props.handleBlur(
        e,
        this.props.shouldInvalidateMapSearchMapSizeOnSearchFieldBlur
      );
    }
  };

  /* On mouse down, indicate that we don't want to hide results on input blur,
   * as this can prevent the result click from completing */
  onResultsMouseDown = (): void => {
    this.hideResultsOnInputBlur = false;
  };

  onResultsMouseUp = (): void => {
    this.hideResultsOnInputBlur = true;
  };

  renderNoResultsHelperLink = (
    message: React.ReactNode,
    color: string,
    theme: Theme,
    activeId: string | number | undefined
  ): JSX.Element => {
    const isSelected = this.cantFindAddressEleUid === activeId;
    return (
      <ScrollActiveElementIntoView theme={theme} isActive={isSelected}>
        <button
          type="button"
          onKeyDown={onEnterOrSpaceKey(this.onNoResultsLinkClick)}
          onClick={this.onNoResultsLinkClick}
          className={classNames(
            theme.ResultsListItem,
            theme.ResultsListItemNoResultsLink,
            {
              [theme.SuggestionActive]: isSelected,
            }
          )}
        >
          <span
            className={theme.NoResultsLink}
            style={{ color }}
            data-hc-name="cant-find-link"
          >
            {/* Do NOT wrap this in @reach/alert since this places it outside of Redux context */}
            {message}
          </span>
        </button>
      </ScrollActiveElementIntoView>
    );
  };

  onNoResultsLinkClick = (): void => {
    const {
      lenderSpecificRedirectUrlForAddressNotFound,
      handleShowCantFindAddressModal,
      useChaseCantFindAddressRedirect,
      handleChaseCantFindAddressRedirect,
    } = this.props;
    this.clearResults();
    this.setInputValue('');

    /* Chase-specific functionality. Terrible pattern, but better than the alternatives of creating a
     * cobranded version of this large, complex component or creating a dedicated saga for this logic */
    if (useChaseCantFindAddressRedirect && handleChaseCantFindAddressRedirect) {
      handleChaseCantFindAddressRedirect();
    } else if (lenderSpecificRedirectUrlForAddressNotFound) {
      window.location.href = lenderSpecificRedirectUrlForAddressNotFound;
    } else {
      handleShowCantFindAddressModal();
    }
  };

  getHasValidCharacters = (str: string): boolean => {
    const isInvalid = /[^a-zA-Z\d\s\-,#/.]/;
    return !isInvalid.test(str);
  };

  getMarginFromBottom = (): number => {
    return this.props.marginFromPageBottom === undefined
      ? 10
      : this.props.marginFromPageBottom;
  };

  setResultsHeight = (element: HTMLElement | null): void => {
    const { useMaxHeightFallback } = this.props;
    const { current: autoCompleteTextInput } = this.autoCompleteTextInputRef;

    if (autoCompleteTextInput) {
      const { top, height } = autoCompleteTextInput.getBoundingClientRect();
      const marginFromPageBottom = this.getMarginFromBottom();

      const iOSKeyboardHeight = 260;
      const fallbackHeight = useMaxHeightFallback
        ? window.innerHeight - iOSKeyboardHeight
        : window.innerHeight;
      /* VisualViewport API accounts for onscreen keyboard on iOS devices, but not supported
       * on IE11 and Edge */
      const viewportHeight = window.visualViewport
        ? window.visualViewport.height
        : fallbackHeight;
      if (element !== null) {
        element.style.maxHeight = `${Math.floor(
          viewportHeight - top - height - marginFromPageBottom
        )}px`;
      }
    }
  };

  recordResultsNode = (ele: HTMLElement): void => {
    if (ele) {
      const {
        isStaticallyPositioned,
        shouldNotDisableBodyScroll,
        shouldCalculateMaxHeight,
      } = this.props;

      /* We know the this element to be an HTML <section> element */
      this.resultsEle = findDOMNode(ele) as HTMLElement | null;

      if (
        !isStaticallyPositioned &&
        !shouldNotDisableBodyScroll &&
        this.resultsEle
      ) {
        disableBodyScroll(this.resultsEle, {
          uId: this.getBodyLockEleUId(this.resultsEle),
        });
      }
      if (
        this.resultsEle &&
        ((!isStaticallyPositioned && !shouldNotDisableBodyScroll) ||
          shouldCalculateMaxHeight)
      ) {
        this.setResultsMaxHeightTimeout = window.setTimeout(() => {
          this.setResultsHeight(this.resultsEle);
        }, 800 /* Delay to allow onscreen keyboard to open on mobile devices */);
      }
    }
  };

  localHandleSelectCurrentLocation = (): void => {
    this.clearResults();
    this.setInputValue('');
    this.setState({ isShowingNoResultsHelperLink: false });
    this.props.handleSelectCurrentLocation();
  };

  getIsShowingSavedSearches = (): boolean => {
    const { hideSavedSearches, addressResults, placeResults, savedSearches } =
      this.props;

    return (
      !hideSavedSearches &&
      addressResults.length === 0 &&
      placeResults.length === 0 &&
      (savedSearches || []).length > 0
    );
  };

  getIsShowingRecentlyViewed = (): boolean => {
    const { addressResults, placeResults, recentProperties } = this.props;
    return (
      addressResults.length === 0 &&
      placeResults.length === 0 &&
      (recentProperties || []).length > 0
    );
  };

  buildInstructionalText = (): string => {
    const {
      addressResults,
      placeResults,
      recentProperties,
      savedSearches,
      hidePlaceResults,
    } = this.props;
    let textArray: string[] = [];

    if (this.getIsShowingSavedSearches() && savedSearches) {
      textArray.push(
        `${savedSearches?.length} saved search${
          savedSearches?.length > 1 ? 'es' : ''
        }`
      );
    }
    if (this.getIsShowingRecentlyViewed() && recentProperties) {
      textArray.push(
        `${recentProperties?.length} recently viewed home${
          recentProperties.length > 1 ? 's' : ''
        }`
      );
    }
    if (!hidePlaceResults && placeResults.length > 0) {
      textArray.push(
        `${placeResults.length} cit${placeResults.length > 1 ? 'ies' : 'y'}`
      );
    }
    if (addressResults.length > 0) {
      textArray.push(
        `${addressResults.length} address${
          addressResults.length > 1 ? 'es' : ''
        }`
      );
    }

    let text = '';
    if (textArray.length === 0) {
      return ''; // Handle empty array case
    } else if (textArray.length === 1) {
      text = textArray[0]; // Handle single item array case
    } else {
      const firstPart = textArray.slice(0, textArray.length - 1).join(', ');
      const lastPart = `and ${textArray[textArray.length - 1]}`;

      text = `${firstPart}, ${lastPart}`;
    }
    text +=
      ' found for your search. Use the down or up arrow keys to access your search results.';
    return text;
  };

  render() {
    const {
      id,
      addressResults,
      activeAddressSearchLabelPosition,
      placeResults,
      placeholder,
      theme,
      invalidCharactersText,
      savedSearches,
      hidePlaceResults,
      hideSubmitButton,
      hideSearchButton,
      noSearchIconBackground,
      shouldDisplayCurrentLocationOption,
      shouldShowBackgroundScreenBehindResults,
      isStaticallyPositioned,
      onInputMouseDown,
      withoutBackgroundScreen,
      dataHcName,
      belowResultsComponent,
      hasVisibleLabel,
      useBackgroundScreenForBlur,
      recentProperties,
      className,
      label,
      alwaysShowLabel,
      inputStyle,
      inputStyleWithDropdown,
      dropdownStyle,
      searchButtonStyle,
      searchButtonWithDropdown,
      disabled,
      inputId,
      closeButtonId,
      submitButtonId,
    } = this.props;

    const {
      activeResult,
      userInput,
      userInputHasInvalidCharacters,
      isShowingNoResultsHelperLink,
      showAccessibilityBorder,
      isAriaMessagingReady,
      resultsText,
    } = this.state;
    const hasResults = !!(addressResults.length || placeResults.length);
    const isShowingDropdown = this.getIsShowingDropdownStatus();
    const userInputMatchers = getQueryStringMatchers(userInput);
    const SearchIcon = <SearchSvg className={theme.SearchIcon} aria-hidden />;
    const ResetButton = <CloseIcon className={theme.CloseIcon} aria-hidden />;
    const activeId =
      get(activeResult, 'placeId') ||
      get(activeResult, 'slug') ||
      get(activeResult, 'address_id') ||
      get(activeResult, 'searchId') ||
      get(activeResult, 'eleId');
    /* Require placeholder to be intentionally `null` in order to not pass a default value */
    const effectivePlaceholder =
      placeholder === null
        ? undefined
        : placeholder || SEARCH_CITY_ADDRESS_INPUT_PLACEHOLDER;

    return (
      <CobrandedStyles>
        {({
          linkColor,
          primaryButtonFillColor,
          accessibilityFocusColor,
          autoCompleteDropdownColor,
        }) => (
          <>
            <div
              id={id}
              data-hc-name={dataHcName}
              key="AutoCompleteContainer"
              data-tooltip-anchor-id={'search_bar'}
              className={classNames(
                theme.AutoComplete,
                theme.AutoCompleteWithSearchButton,
                {
                  [theme.AutoCompleteWithDropdown]: isShowingDropdown,
                  [theme.AutoCompleteWithValue]: userInput,
                  ...(className ? { [className!]: !!className } : {}),
                }
              )}
            >
              <TextInput
                activeAddressSearchLabelPosition={
                  activeAddressSearchLabelPosition
                }
                key={`AutoCompleteInput${inputId || this.inputEleUid}`}
                wrappedUID={inputId || this.inputEleUid}
                value={userInput}
                type="search"
                label={label || effectivePlaceholder || ''}
                theme={theme}
                disabled={disabled}
                placeholder={effectivePlaceholder}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                onKeyDown={this.onInputKeyDown}
                onFocus={this.onInputFocus}
                forwardedRef={this.autoCompleteTextInputRef}
                offScreenLabel={
                  alwaysShowLabel ? false : !(userInput && hasVisibleLabel)
                }
                onMouseDown={onInputMouseDown}
                /* Prevent displaying a list of recently entered addresses in Chrome */
                name={`comehome-address-search-${Math.random()}`}
                hasVisibleLabel={hasVisibleLabel}
                role="combobox"
                aria-autocomplete="list"
                aria-activedescendant={activeId}
                aria-haspopup="listbox"
                aria-expanded={isShowingDropdown}
                aria-owns={this.dropdownEleUid}
                aria-controls={this.dropdownEleUid}
                style={{
                  ...inputStyle,
                  ...(isShowingDropdown && inputStyleWithDropdown),
                }}
              />
              {!useBackgroundScreenForBlur &&
                !withoutBackgroundScreen &&
                isShowingDropdown &&
                !isStaticallyPositioned &&
                shouldShowBackgroundScreenBehindResults && (
                  <div className={theme.BackgroundScreen} />
                )}
              {!hideSearchButton && userInput && (
                <button
                  id={closeButtonId}
                  key={'AutocompleteCompleteResetButton'}
                  aria-label="Clear your search"
                  data-hc-name="auto-complete-reset-button"
                  data-event-name="click_search_reset"
                  className={theme.ResetButton}
                  onMouseDown={this.onResultsMouseDown}
                  onMouseUp={this.onResultsMouseUp}
                  onClick={this.resetValue}
                  disabled={disabled}
                >
                  {ResetButton}
                </button>
              )}

              {hideSearchButton || (hideSubmitButton && userInput) ? null : (
                <button
                  id={submitButtonId}
                  type="submit"
                  key={'AutocompleteCompleteSubmitButton'}
                  aria-label={effectivePlaceholder}
                  data-hc-name="auto-complete-reset-button"
                  data-event-name={null}
                  className={theme.SearchButton}
                  onMouseDown={this.onResultsMouseDown}
                  onMouseUp={this.onResultsMouseUp}
                  onClick={this.onClickSearchButton}
                  disabled={disabled}
                  style={{
                    backgroundColor: noSearchIconBackground
                      ? undefined
                      : primaryButtonFillColor,
                    ...searchButtonStyle,
                    ...(isShowingDropdown && searchButtonWithDropdown),
                  }}
                >
                  {SearchIcon}
                </button>
              )}

              <div
                className={
                  isShowingDropdown
                    ? undefined
                    : theme.ScreenReaderVisuallyHidden
                }
                id={this.dropdownEleUid}
              >
                <AnimatePresence>
                  {isShowingDropdown && !disabled && (
                    <motion.section
                      key="AutoCompleteDropdownAnimation"
                      variants={DROPDOWN_ANIMATION_VARIANTS}
                      exit="exit"
                      initial="exit"
                      animate="animate"
                      className={theme.Results}
                      onMouseDown={this.onResultsMouseDown}
                      onMouseUp={this.onResultsMouseUp}
                      style={{
                        ...dropdownStyle,
                        ...(showAccessibilityBorder
                          ? {
                              borderWidth: '2px',
                              borderColor: accessibilityFocusColor,
                            }
                          : {}),
                        ...(showAccessibilityBorder
                          ? { borderTopWidth: '1px' }
                          : {}),
                        ...(isStaticallyPositioned
                          ? { position: 'static' }
                          : {}),
                      }}
                      ref={this.recordResultsNode}
                    >
                      <ul role="listbox" className={theme.ResultsList}>
                        {
                          /*
                           * Current location selector
                           */
                          shouldDisplayCurrentLocationOption && (
                            <li
                              aria-label="my current location"
                              className={theme.ResultsList}
                              role="option"
                              aria-selected={
                                activeId === this.currentLocationEleUid
                              }
                              tabIndex={0}
                              id={this.currentLocationEleUid}
                              onKeyDown={onEnterOrSpaceKey(
                                this.localHandleSelectCurrentLocation
                              )}
                              onClick={this.localHandleSelectCurrentLocation}
                            >
                              <div
                                className={classNames(theme.LocationItem, {
                                  [theme.SuggestionActive]:
                                    activeId === this.currentLocationEleUid,
                                })}
                              >
                                <ScrollActiveElementIntoView
                                  theme={theme}
                                  isActive={
                                    activeId === this.currentLocationEleUid
                                  }
                                >
                                  <LocationIcon />
                                  My Current Location
                                </ScrollActiveElementIntoView>
                              </div>
                            </li>
                          )
                        }
                        {
                          /*
                           * Place results
                           */
                          !hidePlaceResults && placeResults.length > 0 && (
                            <>
                              <motion.li
                                variants={RESULT_ANIMATION_VARIANTS}
                                exit="exit"
                                initial="exit"
                                animate="animate"
                                role="presentation"
                                className={theme.ResultsList}
                                key={`AutoCompletePlaceContentAnimationHeader`}
                              >
                                <h4 className={theme.ResultsCategoryLabel}>
                                  City
                                </h4>
                              </motion.li>
                              {placeResults
                                .filter(isPlaceWithNonNullText)
                                .map((item, idx) => {
                                  const isSelected =
                                    !!activeResult &&
                                    isPlaceResult(activeResult) &&
                                    activeResult.placeId === item.placeId;
                                  return (
                                    <motion.li
                                      variants={RESULT_ANIMATION_VARIANTS}
                                      exit="exit"
                                      initial="exit"
                                      animate="animate"
                                      key={`AutoCompletePlaceContentAnimation${item.placeId}`}
                                      role="option"
                                      tabIndex={0}
                                      aria-label={`City: ${item.text}`}
                                      id={item.placeId}
                                      aria-selected={isSelected}
                                      onClick={() => this.selectResult(item)}
                                      data-hc-name="header-search-results-places-list-item"
                                    >
                                      <Suggestion
                                        theme={theme}
                                        key={item.placeId}
                                        isActive={isSelected}
                                        text={item.text}
                                        queryStringMatchers={userInputMatchers}
                                      />
                                    </motion.li>
                                  );
                                })}
                            </>
                          )
                        }
                        {
                          /*
                           * Address results
                           */
                          addressResults.length > 0 && (
                            <>
                              <motion.li
                                variants={RESULT_ANIMATION_VARIANTS}
                                exit="exit"
                                initial="exit"
                                animate="animate"
                                role="presentation"
                                aria-label="search results with address"
                                className={theme.ResultsList}
                                key={`AutoCompleteAddressContentAnimationHeader`}
                              >
                                {!hidePlaceResults && (
                                  <h4
                                    className={theme.ResultsCategoryLabel}
                                    aria-level={2}
                                  >
                                    Address
                                  </h4>
                                )}
                              </motion.li>
                              {addressResults
                                .filter(isAddressWithNonNullText)
                                .map((item, idx) => {
                                  const isSelected =
                                    !!activeResult &&
                                    isAddressResult(activeResult) &&
                                    activeResult.slug === item.slug;
                                  return (
                                    <motion.li
                                      variants={RESULT_ANIMATION_VARIANTS}
                                      exit="exit"
                                      initial="exit"
                                      animate="animate"
                                      key={`AutoCompleteAddressContentAnimation${item.slug} ${item.count}`}
                                      role="option"
                                      tabIndex={0}
                                      aria-label={`Address: ${item.text} ${
                                        item.count ? 'Multi Unit' : ''
                                      }`}
                                      id={item.slug}
                                      aria-selected={isSelected}
                                      onClick={() => this.selectResult(item)}
                                      data-hc-name="header-search-results-address-list-item"
                                    >
                                      <Suggestion
                                        theme={theme}
                                        key={`${item.slug} ${item.count}`}
                                        isActive={isSelected}
                                        text={item.text}
                                        count={item.count}
                                        queryStringMatchers={userInputMatchers}
                                      />
                                    </motion.li>
                                  );
                                })}
                            </>
                          )
                        }
                        {
                          /*
                           * Saved Search results
                           */
                          this.getIsShowingSavedSearches() && (
                            <>
                              <motion.li
                                role="presentation"
                                aria-label="my saved searches"
                                className={classNames(
                                  theme.ResultsList,
                                  theme.ResultsListSeparated
                                )}
                              >
                                <h4
                                  className={theme.ResultsCategoryLabel}
                                  aria-level={2}
                                >
                                  My Saved Searches
                                </h4>
                              </motion.li>
                              {savedSearches?.map((item, idx) => {
                                const isSelected =
                                  !!activeResult &&
                                  isSavedSearch(activeResult) &&
                                  activeResult.searchId === item.searchId;
                                return (
                                  <motion.li
                                    variants={RESULT_ANIMATION_VARIANTS}
                                    exit="exit"
                                    initial="exit"
                                    animate="animate"
                                    key={`AutoCompleteSavedSearchesContentAnimation${item.searchId}`}
                                    role="option"
                                    tabIndex={0}
                                    aria-label={`Saved search: ${item.displayName}`}
                                    id={`${item.searchId}`}
                                    aria-selected={isSelected}
                                    onClick={() => this.selectSavedSearch(item)}
                                  >
                                    <Suggestion
                                      theme={theme}
                                      key={`${item.searchId} ${item.name}`}
                                      text={item.displayName}
                                      queryStringMatchers={[]}
                                      isActive={isSelected}
                                    />
                                  </motion.li>
                                );
                              })}
                            </>
                          )
                        }
                        {
                          /* Viewed Homes results */
                          this.getIsShowingRecentlyViewed() && (
                            <ConditionalFeature
                              renderIfFeaturesEnabled={['recent_user_activity']}
                            >
                              <motion.li
                                aria-label="Viewed Homes"
                                className={classNames(
                                  theme.ResultsList,
                                  theme.ResultsListSeparated
                                )}
                              >
                                <h4
                                  className={theme.ResultsCategoryLabel}
                                  aria-level={2}
                                >
                                  Viewed Homes
                                </h4>
                              </motion.li>
                              {(recentProperties || []).map((item, idx) => {
                                const isSelected =
                                  !!activeResult &&
                                  isRecentPropertyItem(activeResult) &&
                                  activeResult.address_id === item.address_id;
                                return (
                                  <motion.li
                                    role="option"
                                    tabIndex={0}
                                    id={`${item.address_id}`}
                                    variants={RESULT_ANIMATION_VARIANTS}
                                    exit="exit"
                                    initial="exit"
                                    animate="animate"
                                    key={`AutoCompleteViewedHomesContentAnimation${idx}`}
                                    aria-selected={isSelected}
                                    // Empty click handler needed here for
                                    // Voiceover to read the option as "clickable"
                                    onClick={() => {}}
                                  >
                                    <Suggestion
                                      key={`${item.address_id}`}
                                      theme={theme}
                                      text=""
                                      queryStringMatchers={[]}
                                      isActive={isSelected}
                                    >
                                      <RecentPropertyWithGQLFetch
                                        addressId={item.address_id}
                                        dataEventNameSaveClick="click_recent_activity_search_dropdown_save_property"
                                        dataEventNamePropertyClick="click_recent_activity_search_dropdown_click_property"
                                        onPropertyClick={() => {
                                          this.clearResults();
                                          this.setInputValue('');
                                        }}
                                      />
                                    </Suggestion>
                                  </motion.li>
                                );
                              })}
                              <motion.li
                                role="option"
                                variants={RESULT_ANIMATION_VARIANTS}
                                exit="exit"
                                initial="exit"
                                animate="animate"
                                key="recent-search-activity-cta"
                                aria-selected={
                                  activeId === this.viewSearchActivityEleUid
                                }
                                id={this.viewSearchActivityEleUid}
                                className={classNames(theme.Suggestion, {
                                  [theme['SuggestionActive']]:
                                    activeId === this.viewSearchActivityEleUid,
                                })}
                                onClick={() => {
                                  this.clearResults();
                                  this.setInputValue('');
                                }}
                              >
                                <ScrollActiveElementIntoView
                                  theme={theme}
                                  isActive={
                                    activeId === this.viewSearchActivityEleUid
                                  }
                                >
                                  <RecentUserActivityCTA
                                    theme={theme}
                                    dataEventName="click_recent_activity_search_dropdown_view_search_activity"
                                  />
                                </ScrollActiveElementIntoView>
                              </motion.li>
                            </ConditionalFeature>
                          )
                        }
                        <motion.li
                          role="option"
                          tabIndex={0}
                          variants={NO_RESULTS_LINK_ANIMATION_VARIANTS}
                          exit="exit"
                          initial="exit"
                          animate="animate"
                          key="AutoCompleteLinkAnimation"
                          className={theme.AutoCompleteLinkAnimation}
                          aria-selected={
                            this.cantFindAddressEleUid === activeId
                          }
                          id={this.cantFindAddressEleUid}
                          onClick={this.onNoResultsLinkClick}
                        >
                          {
                            /* Component to show at the bottom of the results list */
                            /* Always show this whenever not showing the NoResults component */
                            !isShowingNoResultsHelperLink && hasResults && (
                              <div
                                className={theme.ResultsList}
                                data-hc-name="header-search-results-list"
                              >
                                {this.renderNoResultsHelperLink(
                                  <AutoCompleteCantFindAddressLinkCobranded />,
                                  linkColor,
                                  theme,
                                  activeId
                                )}
                              </div>
                            )
                          }
                          {
                            /* Component to show if no results are in the list */
                            isShowingNoResultsHelperLink && !hasResults && (
                              <div
                                className={theme.ResultsList}
                                data-hc-name="header-search-results-list"
                              >
                                {this.renderNoResultsHelperLink(
                                  <AutoCompleteNoResultsLinkCobranded />,
                                  linkColor,
                                  theme,
                                  activeId
                                )}
                              </div>
                            )
                          }
                          {userInputHasInvalidCharacters && (
                            <div
                              role="presentation"
                              className={theme.ResultsList}
                              data-hc-name="header-search-results-list"
                            >
                              <div
                                className={theme.ResultsListItem}
                                role="presentation"
                              >
                                <span
                                  className={theme.InvalidCharactersText}
                                  data-hc-name="InvalidCharactersText"
                                >
                                  {invalidCharactersText ||
                                    'Please enter a valid address'}
                                </span>
                              </div>
                            </div>
                          )}
                          {belowResultsComponent}
                        </motion.li>
                      </ul>
                    </motion.section>
                  )}
                </AnimatePresence>
              </div>
              <input
                className={theme.HiddenInputForIOSFocusing}
                type="text"
                ref={(ele) => {
                  this.fakeInputEle = ele;
                }}
                tabIndex={-1}
                aria-hidden="true"
                aria-label="Hidden Search Input"
                disabled={disabled}
              />
              <div
                className={theme.ScreenReaderVisuallyHidden}
                aria-live="polite"
              >
                {userInputHasInvalidCharacters && isAriaMessagingReady && (
                  <span>Error: Please enter a valid address</span>
                )}
                {!userInputHasInvalidCharacters &&
                  isShowingDropdown &&
                  isAriaMessagingReady &&
                  resultsText}
              </div>
            </div>
            {useBackgroundScreenForBlur && isShowingDropdown && (
              <div
                className={theme.BackgroundScreen}
                onClick={this.onBackgroundScreenClick}
              />
            )}
          </>
        )}
      </CobrandedStyles>
    );
  }
}

const ThemedAutoComplete = themr('AutoComplete', defaultTheme)(AutoComplete);
export default renderOnMount(ThemedAutoComplete);
