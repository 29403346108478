import React from 'react';

import CaratIcon from '@client/components/CaratIcon';
import CobrandedStyles from '@client/components/CobrandedStyles';
import defaultTheme from '@client/css-modules/BreakoutSections.css';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

type PreviewSectionProps = {
  title?: string;
  onClick: () => void;
  theme: Theme;
  'data-section-key': string;
  dataEventName: string;
  children?: React.ReactNode;
};

const PreviewSection: React.FC<PreviewSectionProps> = ({
  title,
  children,
  onClick,
  theme,
  dataEventName,
  ['data-section-key']: dataSectionKey,
  ...rest
}) => (
  <CobrandedStyles>
    {({
      breakoutAndPreviewSectionsTitleFontSize,
      breakoutAndPreviewSectionsTitleFontWeight,
    }) => (
      <button
        className={theme.PreviewSection}
        data-hc-name={'avm-breakout-item'}
        type="button"
        onClick={onClick}
        onKeyDown={onEnterOrSpaceKey(onClick)}
        data-section-key={dataSectionKey}
        data-event-name={dataEventName}
        {...rest}
      >
        <span className={theme.PreviewSectionColumn}>
          <h2
            className={theme.PreviewSectionTitle}
            data-hc-name={'avm-breakout-item-header'}
            style={{
              fontSize: breakoutAndPreviewSectionsTitleFontSize,
              fontWeight: breakoutAndPreviewSectionsTitleFontWeight,
            }}
          >
            {title}
          </h2>
          <span
            className={theme.PreviewSectionBody}
            data-hc-name={'avm-breakout-item-body'}
          >
            {children}
          </span>
        </span>
        <span
          className={theme.PreviewSectionButton}
          data-hc-name={'avm-breakout-item-button'}
        >
          <CaratIcon className={theme.PreviewSectionCaratIcon} />
        </span>
      </button>
    )}
  </CobrandedStyles>
);

const ThemedPreviewSection = themr(
  'BreakoutSections',
  defaultTheme
)(PreviewSection);

export default ThemedPreviewSection;
