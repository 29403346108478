import React from 'react';
import classNames from 'classnames';
import { themr } from '@friendsofreactjs/react-css-themr';

import { PillButtonProps } from '@client/store/types/cobranded-components/pill-button';
import defaultTheme from '@client/css-modules/PillButton.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

function PillButton(props: PillButtonProps) {
  const {
    onClick,
    children,
    className,
    secondary,
    deemphasized,
    tertiary,
    customBackgroundColor,
    customTextColor,
    customBorderRadius,
    disabled,
    ariaLabel = '',
    ariaLive,
    theme,
    title,
    type,
    style,
    small,
    forwardedRef,
    onKeyDown,
    isAnimatingToDark,
    onBlur,
    onFocus,
    id,
  } = props;
  const {
    pillButtonPrimaryBackgroundColor,
    pillButtonSecondaryBackgroundColor,
    pillButtonTertiaryBackgroundColor,
    pillButtonPrimaryTextColor,
    pillButtonSecondaryTextColor,
    pillButtonTertiaryTextColor,
    pillButtonDeemphasizedBackgroundColor,
    pillButtonDeemphasizedTextColor,
    pillButtonSecondaryButtonBorderRadius,
    pillButtonPrimaryButtonBorderRadius,
    pillButtonCustomButtonMinWidth,
    pillButtonCustomButtonWidth,
  } = useCobrandStyles();

  const borderRadiusValue = customBorderRadius
    ? customBorderRadius
    : tertiary || secondary
    ? pillButtonSecondaryButtonBorderRadius
    : pillButtonPrimaryButtonBorderRadius;

  return (
    <button
      data-hc-name={props['data-hc-name'] || props.dataHcName}
      data-event-name={props['data-event-name']}
      data-parent-event-name={props['data-parent-event-name']}
      data-event-data-json={props['data-event-data-json']}
      title={title}
      type={type}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      aria-live={ariaLive ? ariaLive : undefined}
      aria-label={ariaLabel ? ariaLabel : undefined}
      disabled={disabled}
      ref={forwardedRef}
      style={{
        ...{
          background:
            (customBackgroundColor && customBackgroundColor) ||
            (secondary && pillButtonSecondaryBackgroundColor) ||
            (tertiary && pillButtonTertiaryBackgroundColor) ||
            (deemphasized && pillButtonDeemphasizedBackgroundColor) ||
            pillButtonPrimaryBackgroundColor,
          color:
            (customTextColor && customTextColor) ||
            (secondary && pillButtonSecondaryTextColor) ||
            (tertiary && pillButtonTertiaryTextColor) ||
            (deemphasized && pillButtonDeemphasizedTextColor) ||
            pillButtonPrimaryTextColor,
        },
        ...(borderRadiusValue && { borderRadius: borderRadiusValue }),
        ...(pillButtonCustomButtonMinWidth && {
          minWidth: pillButtonCustomButtonMinWidth,
        }),
        ...(pillButtonCustomButtonWidth && {
          width: pillButtonCustomButtonWidth,
        }),
        ...style,
      }}
      className={classNames(theme.PillButton, {
        [className || '']: !!className,
        [theme.Small]: small,
        [theme.Disabled]: disabled,
        [theme.isAnimating]: isAnimatingToDark,
      })}
      id={id}
    >
      {/* NOTE: This keeps string text above the color animation so
       * that it doesn't change color. For children that are not
       * just a string, you will need to manually add a z-index and
       * positioning */}
      {typeof children === 'string' && isAnimatingToDark ? (
        <div className={theme.AnimChildWrapper}>{children}</div>
      ) : (
        children
      )}
    </button>
  );
}

const ThemedPillButton = themr('PillButton', defaultTheme)(PillButton);
export default ThemedPillButton;
