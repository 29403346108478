import Cookies from 'js-cookie';
import { get } from 'lodash';
import { call, select } from 'redux-saga/effects';

import HC_CONSTANTS from '@client/app.config';
import { handleResponse, HttpClient } from '@client/services/http-client';
import {
  FETCH_NEW_ACCESS_TOKEN_FAILURE,
  FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  fetchNewAccessToken,
} from '@client/store/actions/auth.actions';
import { SubmitAgentLeadReferralNetworkPayload } from '@client/store/actions/find-agent.actions';
import { SESSION_STORAGE_KEYS } from '@client/store/constants';
import { getAlertsMarkAsReadUrl } from '@client/store/selectors/alerts.selectors';
import {
  getAccessToken,
  getCurrentUser,
  getUserId,
} from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getActivePropertyTableId,
  getActivePropertyZipCode,
} from '@client/store/selectors/homeowner.selectors';
import { getSelectedSavedSearch } from '@client/store/selectors/saved-search.selectors';
import {
  fetchAdminAccessTokenSuccess,
  fetchAdminPortalAccessToken,
  selectAdminPortalAccessToken,
} from '@client/store/slices/admin-portal.slice';
import { LoDirectActivity } from '@client/store/slices/lo-direct.slice';
import { SavedSearchAPIFields } from '@client/store/types/saved-searches';
import { LODirectLeadMessage } from '@client/store/types/your-team';
import { reportToSentry } from '@client/utils/error.utils';
import { callSaga } from '@client/utils/saga.utils';
import { getConsumerAPILinkHeaderValues } from '@client/utils/string.utils';

const CONSUMER_API_URL = HC_CONSTANTS.CONSUMER_API_URL;
const httpClient = new HttpClient({
  fetchNewAccessTokenActionObject: fetchNewAccessToken(),
  fetchNewAccessTokenSuccessActionString: FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  fetchNewAccessTokenFailureActionString: FETCH_NEW_ACCESS_TOKEN_FAILURE,
  getAccessTokenSelector: getAccessToken,
});
const adminPortalHttpClient = new HttpClient({
  fetchNewAccessTokenActionObject: fetchAdminPortalAccessToken(),
  fetchNewAccessTokenSuccessActionString: fetchAdminAccessTokenSuccess.type,
  fetchNewAccessTokenFailureActionString: '',
  getAccessTokenSelector: selectAdminPortalAccessToken,
});

class ConsumerApiClient {
  /* Fetch any homes claimed by the current user, whether an invited user, signed up user, or anonymous user
   * (no homes will be returned for anonymous users) */
  *getClaimedHomesData() {
    const autoClaimSelector = yield call(
      getIsFeatureEnabled,
      'auto_claim_invited_user_home'
    );
    if (yield select(autoClaimSelector)) {
      const autoClaimOptions = {
        url: `${CONSUMER_API_URL}/homeowner`,
        requestOptions: { method: 'GET' },
      };
      const autoClaimHomeownerResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        autoClaimOptions
      );
      return yield call(handleResponse, autoClaimHomeownerResponse);
    } else {
      const userId = yield select(getUserId);
      if (userId) {
        const options = {
          url: `${CONSUMER_API_URL}/users/${userId}/homeowner`,
          requestOptions: { method: 'GET' },
        };
        const homeownerResponse = yield call(
          [httpClient, httpClient.makeAuthRequest],
          options
        );
        return yield call(handleResponse, homeownerResponse);
      } else {
        return [];
      }
    }
  }

  /* Update the claimed home data for the currently viewed home for all user types */
  *updateClaimedHomesData(data) {
    const activePropertyId = yield select(getActivePropertyTableId);
    const autoClaimSelector = yield call(
      getIsFeatureEnabled,
      'auto_claim_invited_user_home'
    );

    if (yield select(autoClaimSelector)) {
      const autoClaimOptions = {
        url: `${CONSUMER_API_URL}/homeowner/${activePropertyId}`,
        data,
        requestOptions: {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        },
      };
      const autoClaimUpdatedHomeResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        autoClaimOptions
      );
      return yield call(handleResponse, autoClaimUpdatedHomeResponse);
    } else {
      const userId = yield select(getUserId);
      if (userId) {
        if (!activePropertyId) {
          throw new Error(
            `Attempting to update claimed home with no active homeowner property ID available. activePropertyId: ${activePropertyId}`
          );
        }
        const options = {
          url: `${CONSUMER_API_URL}/users/${userId}/homeowner/${activePropertyId}`,
          data,
          requestOptions: {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          },
        };
        const updatedHomeResponse = yield call(
          [httpClient, httpClient.makeAuthRequest],
          options
        );
        return yield call(handleResponse, updatedHomeResponse);
      } else {
        return [];
      }
    }
  }

  // Add a property to the users claimed homes
  *associateClaimedHomeWithUser(data: object) {
    const userId = yield select(getUserId);
    if (userId && (get(data, 'slug') || get(data, 'address_id'))) {
      const options = {
        url: `${CONSUMER_API_URL}/users/${userId}/homeowner/`,
        data,
        requestOptions: {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        },
      };
      const associatedHomeResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, associatedHomeResponse);
    }
  }

  // Remove a home from users claimed homes
  *disassociateClaimedHomeFromUser(tableId: string) {
    const userId = yield select(getUserId);

    if (userId && tableId) {
      const options = {
        url: `${CONSUMER_API_URL}/users/${userId}/homeowner/${tableId}`,
        requestOptions: { method: 'DELETE' },
      };
      const disAssociatedHomeResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, disAssociatedHomeResponse);
    }
  }

  // Get verification response to render questions for verifying the property
  *getVerificationObject() {
    const userId = yield select(getUserId);
    const tableId = yield select(getActivePropertyTableId);

    if (userId && tableId) {
      const options = {
        url: `${CONSUMER_API_URL}/users/${userId}/homeowner/${tableId}/verify`,
        requestOptions: { method: 'GET' },
      };
      const verifyHomeResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, verifyHomeResponse);
    } else {
      reportToSentry(
        'Missing Information unable to fetch homeowner verification questions.',
        { userId, tableId },
        'info'
      );
    }
  }

  *submitVerificationAnswers(answers: { question_id: string; text: string }[]) {
    const userId = yield select(getUserId);
    const tableId = yield select(getActivePropertyTableId);

    if (userId && tableId) {
      const options = {
        url: `${CONSUMER_API_URL}/users/${userId}/homeowner/${tableId}/verify`,
        requestOptions: {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        },
        data: {
          answers,
        },
      };

      const verifyHomeAnswersResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, verifyHomeAnswersResponse);
    }
  }

  *optInBuyerEmails() {
    const options = {
      url: `${CONSUMER_API_URL}/invite/self/opt_in_buyer_emails`,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };

    const optInBuyerEmailsResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, optInBuyerEmailsResponse);
  }

  *agreeToTerms() {
    const options = {
      url: `${CONSUMER_API_URL}/invite/self/tou_accepted`,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };

    const touAcceptedResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, touAcceptedResponse);
  }

  *contactLoanOfficer(data) {
    const options = {
      url: `${CONSUMER_API_URL}/loanofficer/email`,
      data,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };

    const contactLoanOfficerResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, contactLoanOfficerResponse);
  }

  *getWatchListData() {
    const userId = yield select(getUserId);
    /**
     * Guarding in case - userId is undefined when user is not logged in
     * bookmarks call comes back with a 401
     */
    if (userId) {
      const options = {
        url: `${CONSUMER_API_URL}/users/${userId}/bookmarks`,
        requestOptions: { method: 'GET' },
      };
      const watchListResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, watchListResponse);
    } else {
      return [];
    }
  }

  /* Sets a new updated date for the watchlist item */
  *patchWatchListPropertyUpdatedDate(slug) {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/bookmarks/${slug}?by_slug=1`,
      requestOptions: { method: 'PATCH' },
    };
    const patchResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, patchResponse);
  }

  *getMapLegendBreaksData(
    sourceKeyword: string,
    integerZoom: number,
    tileRangeURLSegment: string
  ) {
    const options = {
      url: `${HC_CONSTANTS.GEOTILE_DATA_URL}/${sourceKeyword}/${integerZoom}/${tileRangeURLSegment}/stats`,
      requestOptions: {
        method: 'GET',
        headers: {
          'X-platform': '',
        },
      },
    };
    const mapLegendBreaksResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, mapLegendBreaksResponse);
  }

  *getGeoLocation() {
    const options = {
      url: `${HC_CONSTANTS.GEOLOCATION_ENDPOINT}`,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      },
    };
    const geoLocationResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    return yield call(handleResponse, geoLocationResponse);
  }

  *createSavedSearch(data) {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/searches`,
      data,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };
    const createSavedSearchResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, createSavedSearchResponse);
  }

  *updateSavedSearch(data) {
    const currentSearch = yield select(getSelectedSavedSearch);
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/searches/${currentSearch}`,
      data,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
      },
    };
    const updateSavedSearchResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, updateSavedSearchResponse);
  }

  *getSavedSearchData() {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/searches`,
      requestOptions: { method: 'GET' },
    };
    const savedSearchDataResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, savedSearchDataResponse);
  }

  *deleteSavedSearchData(searchId) {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/searches/${searchId}`,
      requestOptions: { method: 'DELETE' },
    };
    const deleteSavedSearchResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, deleteSavedSearchResponse);
  }

  *getLastSearch() {
    const options = {
      url: `${CONSUMER_API_URL}/searches/last_search`,
      requestOptions: { method: 'GET' },
    };
    const getLastSearchResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, getLastSearchResponse);
  }

  *saveLastSearch(data) {
    const options = {
      url: `${CONSUMER_API_URL}/searches/last_search`,
      data,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };
    const saveLastSearchResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, saveLastSearchResponse);
  }

  *addToWatchList(slug: string) {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/bookmarks/${slug}?by_slug=1`,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
      },
    };
    const addToWatchListResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, addToWatchListResponse);
  }

  *removeFromWatchList(slug: string) {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/bookmarks/${slug}?by_slug=1`,
      requestOptions: { method: 'DELETE' },
    };
    const removeFromWatchListResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, removeFromWatchListResponse);
  }

  *getWatchListEntryForProperty(slug: string) {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/bookmarks/${slug}?by_slug=1`,
      requestOptions: { method: 'GET' },
    };
    const watchListEntry = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    // 404 is expected - handle 404
    if (watchListEntry.status === 404) {
      return { statusCode: 404 };
    } else {
      return yield call(handleResponse, watchListEntry);
    }
  }

  *markAllAlertsAsRead() {
    const url = yield select(getAlertsMarkAsReadUrl);
    const options = {
      url,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };
    if (url) {
      const markAllAlertsResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, markAllAlertsResponse);
    }
  }

  *getUnreadAlertsCount() {
    const userId = yield select(getUserId);
    const options = {
      url: `${CONSUMER_API_URL}/users/${userId}/notifications/unread_count`,
      requestOptions: { method: 'GET' },
    };
    const unreadAlertsCountResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, unreadAlertsCountResponse);
  }

  *getAlerts(action) {
    const userId = yield select(getUserId);
    const url =
      get(action, ['payload', 'nextPageUrl']) ||
      `${CONSUMER_API_URL}/users/${userId}/notifications`;
    const options = {
      url,
      requestOptions: { method: 'GET' },
    };
    const getAlertsResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    // alerts pagination is enabled when user has over 100 alerts,
    //  all other case we won't be seeing Link header on this call
    const linkHeaderValue = getAlertsResponse.headers.get('Link');
    const parsedUrlValues = linkHeaderValue
      ? getConsumerAPILinkHeaderValues(linkHeaderValue)
      : {};
    const alerts = yield call(handleResponse, getAlertsResponse);
    return { alerts, parsedUrlValues };
  }

  *getMortgageSummary(params: {
    activePropertySlug: string;
    userChosenHomePrice?: number | null;
    userChosenInterestRate?: number | string | null;
    userChosenLoanTerm?: string | null;
    userChosenDownpaymentPct?: number | null;
  }) {
    const {
      activePropertySlug,
      userChosenHomePrice,
      userChosenInterestRate,
      userChosenLoanTerm,
      userChosenDownpaymentPct,
    } = params;
    let interestRateNum = userChosenInterestRate;
    /**
     * interest rate is string for FAM DBA's to achieve desired
     * formatting and precision
     */
    if (typeof userChosenInterestRate === 'string') {
      interestRateNum = parseFloat(userChosenInterestRate);
    }
    let url = `${HC_CONSTANTS.CONSUMER_API_URL}/mortgage/summary?slug=${activePropertySlug}`;

    // interest rate can be edited to zero by users on UI
    if (typeof interestRateNum === 'number') {
      url = `${url}&interest_rate=${interestRateNum}`;
    }

    if (userChosenLoanTerm) {
      url = `${url}&mortgage_id=${userChosenLoanTerm}`;
    }

    if (userChosenDownpaymentPct) {
      url = `${url}&down_payment_pct=${userChosenDownpaymentPct}`;
    }

    if (userChosenHomePrice) {
      url = `${url}&home_price=${userChosenHomePrice}`;
    }

    const options = {
      url,
      requestOptions: { method: 'GET' },
    };
    const mortgageSummaryResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, mortgageSummaryResponse);
  }

  *getLoanTerms() {
    let url = `${HC_CONSTANTS.CONSUMER_API_URL}/mortgage/loanterms`;
    const options = {
      url,
      requestOptions: { method: 'GET' },
    };
    const loanTermsResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, loanTermsResponse);
  }

  *calculateMortgage(payload) {
    const options = {
      url: `${CONSUMER_API_URL}/mortgage/calculate`,
      data: payload,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };

    const calculateMortgageResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, calculateMortgageResponse);
  }

  *submitFeedback(emailInfo) {
    const userId = yield select(getUserId);
    const cookieId = Cookies.get('hcid');
    const visitedPropertiesString =
      window.sessionStorage &&
      window.sessionStorage.getItem(SESSION_STORAGE_KEYS.VISITED_PROPERTIES);

    if (cookieId) {
      emailInfo.message = emailInfo.message + ' \n\nCOOKIE-ID: ' + cookieId;
    } else if (userId) {
      emailInfo.message = emailInfo.message + ' \n\nUSER-ID: ' + userId;
    }

    if (visitedPropertiesString) {
      emailInfo.message =
        emailInfo.message +
        ' \n\nVISITED PROPERTIES: ' +
        visitedPropertiesString;
    }
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/feedback/email`,
      data: emailInfo,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };
    const submitFeedbackResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, submitFeedbackResponse);
  }

  *shareProperty(slug: string, payload) {
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/listings/${slug}/share/email`,
      data: payload,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };
    const sharePropertyResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, sharePropertyResponse);
  }

  *mortgageSchedule(payload) {
    const options = {
      url: `${CONSUMER_API_URL}/mortgage/schedule`,
      data: payload,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };

    const mortgageScheduleResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, mortgageScheduleResponse);
  }

  *getHomeAdvisorEstimatedCost(taskKey: string) {
    const zip = yield select(getActivePropertyZipCode);
    if (zip && taskKey) {
      const url = `${HC_CONSTANTS.CONSUMER_API_URL}/homeadvisor/cost/${zip}/${taskKey}`;
      const options = {
        url,
        requestOptions: { method: 'GET' },
      };
      const homeAdvisorCostResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, homeAdvisorCostResponse);
    }
  }

  *registerEmailForMLSNotifications(payload) {
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/mlsnotify`,
      data: payload,
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };
    const registerEmailResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, registerEmailResponse);
  }

  *getHomeAdvisorReferrals(formData) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/homeadvisor/profinder/match`;
    const options = {
      url,
      data: formData,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const homeAdvisorCostResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, homeAdvisorCostResponse);
  }

  *submitFindAgentForm(urlPath: string, formData) {
    const options = {
      url: CONSUMER_API_URL + urlPath,
      data: formData,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const findAgentFormSubmitResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, findAgentFormSubmitResponse);
  }

  *requestHomeAdvisorQuote({ projectId, businessId }) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/homeadvisor/profinder/quote/${projectId}/${businessId}`;
    const options = {
      url,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const homeAdvisorQuoteResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, homeAdvisorQuoteResponse);
  }

  *requestHomeAdvisorBusinessReviews(businessId) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/homeadvisor/profinder/details/${businessId}`;
    const options = {
      url,
      requestOptions: {
        method: 'GET',
      },
    };
    const homeAdvisorReviewsResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, homeAdvisorReviewsResponse);
  }

  *unsubscribeFromEmailNotifications(inviteId: string) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/invite/${inviteId}/unsubscribe`;
    const options = {
      url,
      requestOptions: { method: 'PUT' },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *unsubscribeUserFromEmailNotifications(userId: string, token: string) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/users/unsubscribe?user_id=${userId}&token=${token}`;
    const options = {
      url,
      requestOptions: { method: 'PUT' },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *contactListingAgent({
    agentId,
    email,
    message,
    phone,
    slug,
    request_type,
    loan_officer_id,
    email_type,
    first_name,
    last_name,
  }) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/agents/${agentId}/contact`;
    const options = {
      url,
      data: {
        email,
        message,
        phone,
        slug,
        request_type,
        loan_officer_id,
        email_type,
        first_name,
        last_name,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *contactYourTeamAgent(agent_id: string, message: string) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/contact/agent`;
    const options = {
      url,
      data: {
        message,
        agent_id,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *contactYourTeamLoanOfficer(lo_id: string, message: string) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/contact/loan-officer`;
    const options = {
      url,
      data: {
        message,
        loan_officer_id: lo_id,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *contactYourTeamLODirectUser({ message, ui_context }: LODirectLeadMessage) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/contact/lo-user`;
    const options = {
      url,
      data: {
        message,
        ui_context,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *getYourTeamConnectedAgent() {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/connected-agent`,
        requestOptions: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  *getYourTeamGenericAgent() {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/generic-agent`,
        requestOptions: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  *postLenderAgentSelection(agentId: string) {
    const user = (yield select(getCurrentUser)) as ReturnType<
      typeof getCurrentUser
    >;
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/connect/agent/${agentId}`,
        data: {
          user_update: {
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
            phone: user?.phone,
          },
        },
        requestOptions: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  *postPreviousAgentSelection(data) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/connect/agent`,
        data,
        requestOptions: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  /* Associates a user and an existing agent in our system (i.e. an agent identified via the agentid query param) */
  *associateUserAndAgent({
    userId,
    agentId,
  }: {
    userId: string;
    agentId: string;
  }) {
    return yield call(
      handleResponse,
      yield* callSaga([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/users/${userId}/agent`,
        data: { agent_id: agentId },
        requestOptions: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  *deleteAgentSelection() {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/connected-agent`,
        requestOptions: { method: 'DELETE' },
      })
    );
  }

  *getYourTeamPreviousAgent() {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/previous-agent`,
        requestOptions: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  *getYourTeamLenderAgent(loId: string, zipcode: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/your-team/lender-agent?loid=${loId}&zipcode=${zipcode}`,
        requestOptions: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  *uploadPartnerAgentImage({ data }) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/agents/image`;
    const options = {
      url,
      data,
      requestOptions: { method: 'POST' },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *createPartnerAgent(data) {
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/agents`;
    const options = {
      url,
      data,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *searchLoanOfficers(query, limit) {
    const defaultLimit = (limit as number) || (10 as number);
    const url = `${HC_CONSTANTS.CONSUMER_API_URL}/loanofficer/?search=${query}&limit=${defaultLimit}`;
    const options = {
      url,
      requestOptions: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *resetUnreadAlerts() {
    const userId = yield select(getUserId);
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL.slice(
        0,
        -3
      )}/demo/${userId}/renew-alerts`,
      requestOptions: {
        method: 'POST',
      },
    };
    const fullResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, fullResponse);
  }

  *fetchEquityDataFromAPI(addressId: number) {
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/mortgage/equity/${addressId}`,
      requestOptions: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *putEquityDataOnAPI(data) {
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/homeowner/equity`,
      data,
      requestOptions: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *getStreetViewUrlForGivenFullAddress(fullAddress, imageHeight, imageWidth) {
    const options = {
      url:
        `${HC_CONSTANTS.GOOGLE_STREETVIEW_IMAGE_ENDPOINT}` +
        `?size=${imageWidth}x${imageHeight}` +
        `&location=${encodeURIComponent(fullAddress)}` +
        '&fov=90' +
        '&pitch=0' +
        `&key=${HC_CONSTANTS.GOOGLE_API_KEY}` +
        '&source=outdoor',
      requestOptions: { method: 'GET' },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *postRequestATourToAgent(data) {
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/tour-request/agent`,
      data,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *postRequestATourFinancing(data) {
    const options = {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/tour-request/loan-officer`,
      data,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const response = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, response);
  }

  *fetchCustomerPortalDashboardUrl() {
    return yield call(
      handleResponse,
      yield call(
        [
          adminPortalHttpClient,
          adminPortalHttpClient.makeAdminPortalAuthRequest,
        ],
        {
          url: `${HC_CONSTANTS.CONSUMER_API_URL}/customer_portal/dashboard_url`,
          requestOptions: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          },
          accessToken: yield select(selectAdminPortalAccessToken),
        }
      )
    );
  }

  *getIsAdminAccessTokenValid() {
    return yield call(
      handleResponse,
      yield call(
        [
          adminPortalHttpClient,
          adminPortalHttpClient.makeAdminPortalAuthRequest,
        ],
        {
          url: `${HC_CONSTANTS.CONSUMER_API_URL}/customer_portal/validate`,
          requestOptions: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          },
          accessToken: yield select(selectAdminPortalAccessToken),
        }
      )
    );
  }

  *postRecentSearch(userId: string, data: Partial<SavedSearchAPIFields>) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/users/${userId}/searches/recent`,
      data,
      requestOptions: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  *getRecentSearches(userId: string) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/users/${userId}/searches/recent`,
      requestOptions: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  *postRecentProperties(userId: string, addressIds: { address_ids: number[] }) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/users/${userId}/properties/recent`,
      data: addressIds,
      requestOptions: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  *getRecentProperties(userId: string) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/users/${userId}/properties/recent`,
      requestOptions: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  *fetchPartnerAPICredential(token: string) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.PARTNER_ENDPOINT}/apikeys/from_email`,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    });
    return yield call(handleResponse, response);
  }

  *getLoDirectClients(userId: string) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients`,
      requestOptions: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  *postLoDirectClients(userId, data) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients`,
      data,
      requestOptions: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  *patchLoDirectClient(userId, data) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients`,
      data,
      requestOptions: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  *fetchCSVTemplate(userId) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients/sample`,
      requestOptions: { method: 'GET' },
    });
    return yield call(handleResponse, response);
  }

  *putLODirectBulkUpload(file: File, userId: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients`,
      requestOptions: { method: 'PUT' },
      data: formData,
    });
    return yield call(handleResponse, response);
  }

  *postLODirectSupportEmail(subject: string, message: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/feedback/louser-email`,
        data: { subject, message },
        requestOptions: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
      })
    );
  }

  *getOriginalLoanAmount(addressId: number) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/loan-amount?address_id=${addressId}`,
        requestOptions: { method: 'GET' },
      })
    );
  }

  *getLoDirectLeads(userId) {
    const options = {
      url: `${CONSUMER_API_URL}/lousers/${userId}/leads`,
      requestOptions: { method: 'GET' },
    };
    const leadsDataResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, leadsDataResponse);
  }

  *postLoMessageRead(messageId, userId) {
    const options = {
      url: `${CONSUMER_API_URL}/lousers/${userId}/leads/${messageId}/read`,
      requestOptions: { method: 'POST' },
    };
    const leadsMessageResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, leadsMessageResponse);
  }

  *deleteLoDirectClient(clientId: string, userId: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients/${clientId}`,
        requestOptions: { method: 'DELETE' },
      })
    );
  }

  *fetchLoDirectIncreasedActivity(userId: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients/increased-activity?top=5`,
        requestOptions: { method: 'GET' },
      })
    );
  }

  *fetchLoDirectActivity(userId: string, start: string, end: string) {
    return (yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/clients/activity?start=${start}&end=${end}`,
        requestOptions: { method: 'GET' },
      })
    )) as LoDirectActivity[];
  }

  *sendAgentLeadReferralEmail(data: SubmitAgentLeadReferralNetworkPayload) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${CONSUMER_API_URL}/leads/email`,
        requestOptions: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
        data,
      })
    );
  }

  *fetchCensusTract() {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${CONSUMER_API_URL}/censusgrant/sheet`,
        requestOptions: { method: 'GET' },
      })
    );
  }
}

export const consumerApiClient = new ConsumerApiClient();
