import { ModalKey } from '@client/store/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getBestSchool,
  getSchoolsSummary,
} from '@client/store/selectors/property-details.selectors';
import { Theme } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';
import PreviewSection from './PreviewSection';

interface Props {
  openSectionModal: (modalKey: ModalKey) => void;
  theme: Theme;
}

const Schools = ({ openSectionModal, theme }: Props) => {
  const isSchoolDataEnabled = useSelector(getIsFeatureEnabled('school_data'));
  const bestSchool = !!useSelector(getBestSchool);
  const schoolsSummary = useSelector(getSchoolsSummary);

  return (
    <>
      {isSchoolDataEnabled && bestSchool && schoolsSummary && (
        <PreviewSection
          data-section-key={'schools'}
          dataEventName={'click_property_details_schools'}
          onClick={() => openSectionModal('schools')}
          title="Schools - Percentile"
        >
          <div
            className={theme.SchoolsSummary}
            /* The HTML for this section comes from propertyGraph, and is trusted as we are authenticating all interactions: */
            dangerouslySetInnerHTML={{ __html: schoolsSummary }}
          />
        </PreviewSection>
      )}
    </>
  );
};

export default Schools;
