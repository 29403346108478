import React from 'react';
import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/Checkbox.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type CheckboxProps = {
  label?: string;
  name?: string;
  isChecked: boolean;
  // Hide the label off screen so that it's not visible to users
  // but visible to accessibility screen readers
  offScreenLabel?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  style?: React.CSSProperties;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  theme: Theme;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  /* For event reporting */
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
  ['data-hc-name']?: string;
};

const getUncheckedBoxStyle = () => {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones */
  const isInternetExplorer =
    ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1;

  /* setting display:none to Internet Explorer (IE)'s checkbox with our css blows out the border for checkbox but border could be emulated with boxShadow this way */
  return isInternetExplorer
    ? {
        boxShadow:
          '0.0625rem 0 0 0 #4a4a4a, 0 0.0625rem 0 0 #4a4a4a, 0.0625rem 0.0625rem 0 0 #4a4a4a, 0.0625rem 0 0 0 #4a4a4a inset, 0 0.0625rem 0 0 #4a4a4a inset',
      }
    : {};
};

const Checkbox = ({
  ['data-hc-name']: dataHcName,
  ['data-event-name']: dataEventName,
  ['data-parent-event-name']: dataParentEventName,
  isChecked,
  label,
  onClick,
  onKeyDown,
  onFocus,
  offScreenLabel,
  theme,
  ariaLabelledBy,
  ariaDescribedBy,
  style,
  tabIndex = 0,
}: CheckboxProps) => {
  const shouldUseOwnLabel = !!(!ariaLabelledBy && label);
  const { checkboxCheckedColor } = useCobrandStyles();
  return (
    <AccessibleElementUniqueId>
      {({ uid }) => (
        <div className={theme.Checkbox}>
          <div className={theme.CheckboxInputWrapper} onClick={onClick}>
            <input
              className={theme.CheckboxInput}
              type="checkbox"
              data-hc-name={dataHcName}
              data-event-name={dataEventName}
              data-parent-event-name={dataParentEventName}
              /* Firefox ignores the aria attributes and uses the "checked" attribute instead */
              checked={isChecked}
              id={uid}
              tabIndex={tabIndex}
              onKeyDown={onKeyDown}
              onChange={() => void 0}
              onFocus={onFocus}
              aria-labelledby={ariaLabelledBy}
              aria-describedby={ariaDescribedBy}
              style={
                isChecked
                  ? {
                      background: checkboxCheckedColor,
                      borderColor: checkboxCheckedColor,
                      ...style,
                    }
                  : getUncheckedBoxStyle()
              }
            />
            {isChecked && <div className={theme.CheckboxCheck} />}
          </div>
          {shouldUseOwnLabel && (
            <label
              className={classNames(theme.CheckboxLabel, {
                [theme.OffScreenLabel]: !!offScreenLabel,
              })}
              htmlFor={uid}
            >
              {label}
            </label>
          )}
        </div>
      )}
    </AccessibleElementUniqueId>
  );
};

export default themr('ThemedCheckbox', defaultTheme)(Checkbox);
