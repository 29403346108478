import { Component } from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import ScrollPicker from '@client/components/generic/ScrollPicker';
import { FilterKey, LABEL_DEFAULTS } from '@client/store/filter-constants';
import defaultTheme from '@client/css-modules/MinMaxPicker.css';
import { FilterPickerValue } from '@client/store/types/filters';

export type MinMaxPickerProps = {
  theme: Theme;
  filterKey: FilterKey;
  values: FilterPickerValue[];
  setValueForStateFormatter: (val: FilterPickerValue) => number;
  getValueForControlFormatter: (currentVal: FilterPickerValue) => number;
  labelFormatter: (
    val: FilterPickerValue,
    minOrMax: string
  ) => string | JSX.Element;
  onChange: (
    value: [FilterPickerValue | null, FilterPickerValue | null]
  ) => void;
  handleReportValueSelection: (
    position: 'min' | 'max',
    value: FilterPickerValue
  ) => void;
  currentValues: [FilterPickerValue | null, FilterPickerValue | null];
  itemHeight?: number;
  height?: number;
  ariaLabelledBy: string;
  confirmIfGreaterThanValue?: number;
  confirmForOptionGroup: string;
  confirmationText: string;
  onConfirmationConfirm: () => void;
  onConfirmationCancel: () => void;
};

type State = {
  valuesMin: FilterPickerValue[];
  valuesMax: FilterPickerValue[];
  min: FilterPickerValue;
  max: FilterPickerValue;
};

class MinMaxPicker extends Component<MinMaxPickerProps, State> {
  constructor(props: MinMaxPickerProps) {
    super(props);
    const { currentValues, getValueForControlFormatter, values } = props;
    const formattedCurrentValues = [
      currentValues[0] && getValueForControlFormatter(currentValues[0]),
      currentValues[1] && getValueForControlFormatter(currentValues[1]),
    ];
    const initialMin = values.indexOf(formattedCurrentValues[0] || -999);
    const initialMax = values.indexOf(formattedCurrentValues[1] || -999);
    /* allow full range for initialValsMin as long as max value isn't set */
    const initialValsMin = values.slice(
      0,
      initialMax === -1 ? values.length : initialMax
    );
    const initialValsMax = values.slice(initialMin + 1, values.length);
    this.state = {
      valuesMin: [LABEL_DEFAULTS.NO_MIN, ...initialValsMin],
      valuesMax: [LABEL_DEFAULTS.NO_MAX, ...initialValsMax],
      min: formattedCurrentValues[0] || LABEL_DEFAULTS.NO_MIN,
      max: formattedCurrentValues[1] || LABEL_DEFAULTS.NO_MAX,
    };
  }

  handleValueChange = (
    type: string,
    value: FilterPickerValue | number
  ): void => {
    const {
      onChange,
      setValueForStateFormatter,
      values,
      handleReportValueSelection,
    } = this.props;
    let i;
    if (typeof value === 'number') {
      // Remove the min values from the max display, and vice-versa
      i = values.indexOf(value);
    }

    let maxVals = values;
    let minVals = values;
    const min = type === 'min' ? value : this.state.min;
    const max = type === 'max' ? value : this.state.max;

    if (type === 'min') {
      if (i > -1) {
        maxVals = values.slice(i + 1, minVals.length);
      }
      this.setState({
        valuesMax: [LABEL_DEFAULTS.NO_MAX, ...maxVals],
        min,
      });
    } else {
      if (i > -1) {
        minVals = values.slice(0, i);
      }
      this.setState({
        valuesMin: [LABEL_DEFAULTS.NO_MIN, ...minVals],
        max,
      });
    }
    onChange([setValueForStateFormatter(min), setValueForStateFormatter(max)]);
    handleReportValueSelection(type as 'min' | 'max', value);
  };

  generateLabels = (
    v: FilterPickerValue,
    minOrMax: string
  ): string | JSX.Element => {
    if (v === LABEL_DEFAULTS.NO_MIN || v === LABEL_DEFAULTS.NO_MAX) {
      return v;
    } else {
      return this.props.labelFormatter(v, minOrMax);
    }
  };

  render() {
    const {
      theme,
      height,
      itemHeight,
      confirmIfGreaterThanValue,
      confirmForOptionGroup,
      confirmationText,
      onConfirmationConfirm,
      onConfirmationCancel,
      ariaLabelledBy,
    } = this.props;
    const { valuesMin, valuesMax, min, max } = this.state;

    return (
      <div className={theme.MinMaxPicker}>
        <ScrollPicker
          dataHcName="min-max-picker"
          height={height}
          itemHeight={itemHeight}
          labelFormatter={this.generateLabels}
          valueGroups={{
            min: min,
            max: max,
          }}
          optionGroups={{
            min: valuesMin,
            max: valuesMax,
          }}
          onChange={this.handleValueChange}
          confirmIfGreaterThanValue={confirmIfGreaterThanValue}
          confirmForOptionGroup={confirmForOptionGroup}
          confirmationText={confirmationText}
          onConfirmationConfirm={onConfirmationConfirm}
          onConfirmationCancel={onConfirmationCancel}
          ariaLabelledBy={ariaLabelledBy}
        />
      </div>
    );
  }
}

export default themr('MinMaxPicker', defaultTheme)(MinMaxPicker);
