import { ModalKey } from '@client/store/constants';
import { abbrNumberFormatter } from '@client/utils/string.utils';
import { Theme } from '@friendsofreactjs/react-css-themr';
import PreviewSection from './PreviewSection';

interface Props {
  theme: Theme;
  openSectionModal: (modalKey: ModalKey) => void;
  shouldDisplayNeighborhoodInsights: boolean;
  hasHistoValuePerSqFt: boolean;
  hasHistoBuildingArea: boolean;
  insightTextData: {
    buildingArea: {
      high: number | null;
      low: number | null;
    };
    valuePerSqFt: {
      high: number | null;
      low: number | null;
    };
  };
}

const NeighborhoodInsights = ({
  theme,
  openSectionModal,
  shouldDisplayNeighborhoodInsights,
  hasHistoBuildingArea,
  hasHistoValuePerSqFt,
  insightTextData: { buildingArea, valuePerSqFt },
}: Props) => (
  <>
    {shouldDisplayNeighborhoodInsights && (
      <PreviewSection
        data-section-key={'neighborhood_insights'}
        dataEventName={'click_property_details_neighborhood_insights'}
        onClick={() => openSectionModal('neighborhood_insights')}
        title="Neighborhood Insights"
      >
        <div>
          {'In this area, '}
          {hasHistoValuePerSqFt && (
            <span>
              {'price per square foot ranges from '}
              <span className={theme.HighlightedValue}>
                ${abbrNumberFormatter(valuePerSqFt.low)} - $
                {abbrNumberFormatter(valuePerSqFt.high)}
              </span>
            </span>
          )}
          {hasHistoBuildingArea && hasHistoValuePerSqFt && ' and '}
          {hasHistoBuildingArea && (
            <span>
              {'size ranges from '}
              <span className={theme.HighlightedValue}>
                {buildingArea.low} - {buildingArea.high}
              </span>
              {' square feet'}
            </span>
          )}
          {'.'}
        </div>
      </PreviewSection>
    )}
  </>
);

export default NeighborhoodInsights;
