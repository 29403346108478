import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Theme } from '@friendsofreactjs/react-css-themr';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import { AddressResult, PlaceResult } from '@client/store/types/search';
import AutoComplete from '@client/components/AutoComplete';
import {
  searchFetchPlaces,
  searchClearPlaces,
  searchFetchPlaceDetails,
  searchGetUserLocationViaSelection,
  searchInvalidateMapSize,
  searchRouteChangeToHidePDPModal,
  searchSetActiveMultiUnitProperties,
} from '@client/store/actions/search.actions';
import {
  getPlaceSearchAddressResults,
  getPlaceSearchPlaceResults,
  getPlaceSearchStatus,
  getSearchIsMobileMapPropertyCardActive,
  getSearchActiveMultiUnitProperties,
} from '@client/store/selectors/search.selectors';
import { STATUSES } from '@client/store/constants';
import {
  reportSearchAddress,
  reportSearchLocation,
  reportSearchInputEnterPress,
  reportSearchClickCurrentLocation,
  reportSearchNoAddressResultsForQuery,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import {
  openModal,
  showCantFindAddressModal,
} from '@client/store/actions/modals.actions';
import { View } from '@client/routes/constants';
import { getTopNSavedSearches } from '@client/store/selectors/saved-search.selectors';
import {
  selectSavedSearch,
  fetchSavedSearchesIfNecessary,
} from '@client/store/actions/saved-search.actions';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { ActiveAddressSearchLabelPosition } from '@client/store/types/cobranding';
import {
  getLenderSpecificRedirectUrlForAddressNotFound,
  getUseChaseSpecificCantFindAddressRedirect,
} from '@client/store/selectors/cobranding.selectors';
import { updateContextualSearchId } from '@client/store/slices/analytics-data.slice';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  fetchRecentProperties,
  selectRecentProperties,
} from '@client/store/slices/recent-user-activity.slice';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { chaseCTAClick } from '@client/store/actions/cobranding.actions';

type Result = AddressResult | PlaceResult;
type OwnProps = {
  activeAddressSearchLabelPosition?: ActiveAddressSearchLabelPosition;
  clearAndCloseWithOneClick?: boolean;
  focusInputOnMount?: boolean;
  hasVisibleLabel?: boolean;
  hidePlaceResults?: boolean;
  hideSavedSearches?: boolean;
  hideSearchButton?: boolean;
  isStaticallyPositioned?: boolean;
  preventDefaultResultClickFunctionality?: boolean;
  shouldDisplayCurrentLocationOption?: boolean;
  withoutBackgroundScreen?: boolean;
  prefilledUserInput?: string;
  /* Overrides the default margin from bottom when applying a max height to the results list */
  marginFromPageBottom?: number;
  /* The arg is null when selecting a saved-search, otherwise contains the result */
  onResultClick?: (item: Result | null) => void;
  handleInputFocus?: () => void;
  handleInputBlur?: () => void;
  /* Executes upon clearing the input when populated with text */
  handleClearPopulatedInput?: () => void;
  /* Executes upon clearing the input when empty */
  handleClearEmptyInput?: () => void;
  /* Executes when clicking the search button/icon that displays when the search input is empty */
  handleClickSearchButton?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onInputMouseDown?: (e: React.MouseEvent) => void;
  dataHcName?: string;
  theme: Theme;
  shouldNotDisableBodyScroll?: boolean;
  isHomeownerSearch?: boolean;
  shouldNavigateToSRP?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const isSmallScreen = getIsSmallSize(state);
  const currentView = getCurrentView(state);

  return {
    recentProperties: selectRecentProperties(state)?.slice(0, 3) || [],
    isRecentUserActivityEnabled:
      getIsFeatureEnabled('recent_user_activity')(state) &&
      getIsLoggedIn(state),
    addressResults: getPlaceSearchAddressResults(state),
    placeResults: getPlaceSearchPlaceResults(state),
    isLoadingResults: getPlaceSearchStatus(state) === STATUSES.LOADING,
    minCharsStart: 3,
    savedSearches: ownProps.hideSavedSearches
      ? []
      : getTopNSavedSearches(3)(state),
    shouldInvalidateMapSearchMapSizeOnSearchFieldBlur:
      isSmallScreen && currentView === View.SEARCH,
    shouldHidePDPModalOnFocus:
      isSmallScreen &&
      currentView === View.SEARCH &&
      getSearchIsMobileMapPropertyCardActive(state),
    shouldHideMultiUnitPropertiesOnFocus:
      currentView === View.SEARCH &&
      !!getSearchActiveMultiUnitProperties(state),
    shouldShowBackgroundScreenBehindResults: isSmallScreen,
    useMaxHeightFallback: true,
    lenderSpecificRedirectUrlForAddressNotFound:
      getLenderSpecificRedirectUrlForAddressNotFound(state),
    shouldPersistResultsOnBlur: false,
    useChaseCantFindAddressRedirect:
      getUseChaseSpecificCantFindAddressRedirect(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
  handleViewSearchActivityClick: () => {
    dispatch(openModal('recent-user-activity'));
  },
  handleNavigateToResult: (item) => {
    /* Navigate to new instance of property page */
    if (item.slug) {
      /* Update the contextual search ID for property clicks so we don't tie the click to current analytics search-id  */
      dispatch(updateContextualSearchId());

      dispatch(reportSearchAddress(item.slug));
      dispatch(
        routeChange({
          view: ownProps.isHomeownerSearch
            ? View.HOMEOWNER_PROPERTY_DETAILS
            : View.PROPERTY_DETAILS,
          params: { slug: item.slug },
        })
      );
      /* Reposition the map (or navigate to the search-map route) to display the place */
    } else {
      dispatch(reportSearchLocation(item.text));
      dispatch(
        searchFetchPlaceDetails({
          id: item.placeId,
          type: item.placeType,
          text: item.text,
        })
      );
    }
    if (ownProps.onResultClick) {
      ownProps.onResultClick(item);
    }
  },
  handleFetchPlaces: (term, buildingId) =>
    dispatch(searchFetchPlaces(term, buildingId)),
  handleClearPlaces: () => dispatch(searchClearPlaces()),
  handleSavedSearchClick: (id) => {
    dispatch(selectSavedSearch(id, 'click_search_field_saved_search'));
    if (ownProps.onResultClick) {
      ownProps.onResultClick(null);
    }
  },
  handleFocus: (
    e: React.FocusEvent<HTMLInputElement>,
    {
      shouldHidePDPModalOnFocus,
      shouldHideMultiUnitPropertiesOnFocus,
    }: {
      shouldHidePDPModalOnFocus?: boolean;
      shouldHideMultiUnitPropertiesOnFocus?: boolean;
    }
  ): void => {
    if (shouldHidePDPModalOnFocus) {
      dispatch(searchRouteChangeToHidePDPModal());
    }
    if (shouldHideMultiUnitPropertiesOnFocus) {
      dispatch(searchSetActiveMultiUnitProperties(null));
    }
    if (ownProps.handleInputFocus) {
      ownProps.handleInputFocus();
    } else {
      dispatch(reportEvent('click_search_bar'));
    }
  },
  handleEnterKeypress: () => dispatch(reportSearchInputEnterPress()),
  handleFetchSavedSearches: () => dispatch(fetchSavedSearchesIfNecessary()),
  handleSelectCurrentLocation: () => {
    if (ownProps.shouldNavigateToSRP) {
      dispatch(routeChange({ view: View.SEARCH }));
    }
    dispatch(searchGetUserLocationViaSelection());
    dispatch(reportSearchClickCurrentLocation());
  },
  handleBlur: (e, shouldInvalidateMapSearchMapSize) => {
    if (shouldInvalidateMapSearchMapSize) {
      dispatch(searchInvalidateMapSize());
    }
    if (ownProps.handleInputBlur) {
      ownProps.handleInputBlur();
    } else {
      dispatch(reportEvent('click_search_bar_exit'));
    }
  },
  handleShowCantFindAddressModal: () => dispatch(showCantFindAddressModal()),
  handleNoResultsForQuery: (userInput) => {
    if (userInput) {
      dispatch(reportSearchNoAddressResultsForQuery(userInput));
    }
  },
  handleFetchRecentProperties: () => dispatch(fetchRecentProperties()),
  handleChaseCantFindAddressRedirect: () => {
    dispatch(
      chaseCTAClick({
        destination: 'requestChaseMyHomeManualAddress',
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete);
