import { Route, View } from '@client/routes/constants';
import { STATUSES } from '@client/store/constants';
import { AuthState } from '@client/store/reducers/auth.reducer';
import { EmailSettingFrequency, isErrorObj } from '@client/store/types/auth';
import { ReduxState } from '@client/store/types/redux-state';
import {
  getCurrentView,
  getPreviousParams,
  getPreviousRoute,
  getPreviousView,
} from '@src/redux-saga-router-plus/selectors';
import { trim } from 'lodash';
import { createSelector } from 'reselect';

export function getAuthState(state: ReduxState): AuthState {
  return state.auth;
}

export const getHasMultipleHomes = createSelector(
  getAuthState,
  (auth) => auth.hasMultipleHomes
);

export const getLoginErrorMessage = createSelector(
  getAuthState,
  (auth) => auth.loginErrorMessage
);

export const getSignUpErrorMessage = createSelector(
  getAuthState,
  (auth) => auth.signUpErrorMessage
);

export const getValidationErrors = createSelector(
  getAuthState,
  (auth) => auth.validationErrors
);

export const getAuthStatus = createSelector(
  getAuthState,
  (auth) => auth.status
);

/* pendingUser is used to store userDetails so that we can
prefill them for the user next they come to the same form */
export const getPendingUser = createSelector(
  getAuthState,
  (auth) => auth.pendingUser
);

/* Get the referrer on the initial page load GET request (passed via SSR) */
export const getReferrer = createSelector(getAuthState, (authState) => {
  return authState?.referrer;
});

export const getAuthModalState = createSelector(
  getAuthState,
  (authState) => authState.authModal
);

/* Get action to dispatch immediately after successful authentication */
export const getAuthModalAfterAuthAction = createSelector(
  getAuthModalState,
  (authModalState) => authModalState.afterAuthAction
);

export const getAuthModalStartingPage = createSelector(
  getAuthModalState,
  (authModal) => authModal.startingPage
);

export const getAuthModalSubHeadingText = createSelector(
  getAuthModalState,
  (authModal) => authModal.subHeadingText
);

export const getAuthModalHeadingText = createSelector(
  getAuthModalState,
  (authModal) => authModal.headingText
);

export const getCurrentUser = createSelector(getAuthState, (auth) => {
  return auth.user;
});

/* These are the minimum user data requirements necessary for hitting several endpoints, including at minimum:
 * /your-team/contact/agent
 * /your-team/contact/loan-officer
 */
export const getUserHasFirstnameLastnameAndEmail = createSelector(
  getCurrentUser,
  (user) => !!(user?.email && user.first_name && user.last_name)
);

export const getAccountDetailsIsLoading = createSelector(
  getAuthStatus,
  (status) => status === STATUSES.LOADING || status === STATUSES.INIT
);

export const getUserEmailsBounced = createSelector(getCurrentUser, (user) => {
  return !!user?.email_bounced;
});

export const getIsUserEmailConfirmed = createSelector(
  getCurrentUser,
  (user) => {
    return !!user?.confirmed;
  }
);

export const getUserCreatedDate = createSelector(getCurrentUser, (user) => {
  return user?.created;
});

export const getUserForProfilePage = createSelector(getCurrentUser, (user) => {
  const userInfo = {
    email: user?.email || null,
    firstName: user?.first_name || null,
    lastName: user?.last_name || null,
    phone: user?.phone || null,
    city: user?.city || null,
    state: user?.state || null,
    zipcode: user?.zip_code || null,
  };
  return userInfo;
});

/* TODO refactor to return null if user is null */
export const getUserContactInfo = createSelector(getCurrentUser, (user) => {
  const userInfo = {
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
    phone: user?.phone || '',
  };
  return userInfo;
});

export const getAccessToken = createSelector(
  getAuthState,
  (auth) => auth.token
);

export const getChapiPartnerInviteId = createSelector(
  getAuthState,
  (auth) => auth.chapiPartnerInviteId || null
);

export const getUserFirstName = createSelector(
  getCurrentUser,
  (user) => user?.first_name || null
);

export const getUserLastName = createSelector(
  getCurrentUser,
  (user) => user?.last_name || null
);

export const getUserEmailAddress = createSelector(
  getCurrentUser,
  (user) => user?.email || null
);

export const getUserPhoneNumber = createSelector(
  getCurrentUser,
  (user) => user?.phone
);

export const getUserId = createSelector(getCurrentUser, (user) => user?.id);

export const getUserPartnerUserId = createSelector(
  getCurrentUser,
  (user) => user?.partner_user_id || null
);

export const getUserTermsAcceptedDate = createSelector(
  getCurrentUser,
  (user) => user?.terms_accepted
);

export const getUserSpecialUserType = createSelector(
  getCurrentUser,
  (user) => user?.special_user_type || null
);

export const getAllUserSettings = createSelector(getCurrentUser, (user) => {
  return {
    send_email: !!user?.send_email,
    send_email_search: !!user?.send_email_search,
    send_mobile: !!user?.send_mobile,
    send_mobile_search: !!user?.send_mobile_search,
    send_email_search_frequency:
      (user?.send_email_search_frequency as EmailSettingFrequency | null) ??
      'daily',
  };
});

export const getUserDetailsForSettingsPage = createSelector(
  getCurrentUser,
  (user) => {
    let name: string | null = null;
    let location: string | null = null;

    // takes array, removes null or blank strings, then joins the array with separator.
    const filterTrimAndJoin = (
      arr: Array<string | null | undefined>,
      separator: string
    ) =>
      arr
        .map((word) => trim(word || undefined))
        .filter((word) => !!word)
        .join(separator);

    if (user?.first_name || user?.last_name) {
      name = filterTrimAndJoin([user?.first_name, user?.last_name], ' ');
    }

    if (user?.city || user?.state || user?.zip_code) {
      let area = filterTrimAndJoin([user?.city, user?.state], ', ');
      location = filterTrimAndJoin([area, user?.zip_code], ' ');
    }

    return {
      email: user?.email,
      name,
      location,
    };
  }
);

export const getIsLoggedIn = createSelector(
  getAccessToken,
  getAuthStatus,
  getUserId,
  (token, authStatus, userId) => {
    return !!(authStatus === STATUSES.SUCCESS && token && userId);
  }
);

export const getRedirectPostLogin = createSelector(
  getCurrentView,
  getPreviousView,
  (state: ReduxState, routeAfterLoginSuccess) => routeAfterLoginSuccess as View,
  getPreviousRoute,
  (currView, prevView, routeAfterLoginSuccess, previousRoute) => {
    let redirect;
    if (routeAfterLoginSuccess) {
      redirect = { view: routeAfterLoginSuccess };
    }
    if (currView === View.LOGIN) {
      // do not redirect user to prevView when prevView is login, sign up and reset password
      const setPreviousRoute =
        !!prevView &&
        prevView !== View.LOGIN &&
        prevView !== View.SIGN_UP &&
        prevView !== View.RESET_PASSWORD;
      const routeObj = {
        view: setPreviousRoute ? previousRoute.view : View.SEARCH,
        params: setPreviousRoute ? previousRoute.params : '',
      };
      redirect = routeObj;
    }
    return redirect;
  }
);

export const getRedirectPostSignUp = createSelector(
  getCurrentView,
  getPreviousView,
  getPreviousParams,
  (currView, prevView, prevParams) => {
    let routeObj: Route | null = null;
    if (currView === View.SIGN_UP) {
      routeObj =
        !!prevView && prevView !== View.LOGIN && prevView !== View.SIGN_UP
          ? { view: prevView, params: prevParams }
          : { view: View.SEARCH };
    }
    return routeObj;
  }
);

export function getForgotPasswordState(state: ReduxState) {
  return state.forgotPassword;
}

export const getForgotPasswordTemporaryToken = createSelector(
  getForgotPasswordState,
  (forgotPasswordState) => forgotPasswordState.temporaryToken
);

export const getResetPasswordErrorMessage = createSelector(
  getForgotPasswordState,
  (forgotPasswordState) =>
    isErrorObj(forgotPasswordState.resetPasswordError)
      ? forgotPasswordState.resetPasswordError.messageRaw
      : forgotPasswordState.resetPasswordError
);

export const getUserBuyingPower = createSelector(
  getAuthState,
  (auth) => auth.userBuyingPower
);

export const getUserFullName = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else {
      return firstName || lastName;
    }
  }
);

export const getLenderCtaPartnerUserId = createSelector(
  getAuthState,
  (auth) => auth.lenderCtaPartnerUserId
);
