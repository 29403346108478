import { ModalKey } from '@client/store/constants';
import {
  getCounty,
  getCrimeRate,
  getIsCrimeDataMissing,
} from '@client/store/selectors/property-details.selectors';
import { percentFormatter } from '@client/utils/string.utils';
import { Theme } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';
import PreviewSection from './PreviewSection';

interface Props {
  openSectionModal: (modalKey: ModalKey) => void;
  theme: Theme;
}

const Crime = ({ openSectionModal, theme }: Props) => {
  const isCrimeDataMissing = useSelector(getIsCrimeDataMissing);
  const crimeRate = useSelector(getCrimeRate);
  const crimeCounty = useSelector(getCounty);

  return (
    <>
      {!isCrimeDataMissing && (
        <PreviewSection
          data-section-key={'crime'}
          dataEventName={'click_property_details_crime'}
          onClick={() => openSectionModal('crime')}
          title="Crime Reports"
        >
          <div>
            This block has a higher crime report rate than&nbsp;
            <span className={theme.HighlightedValue}>
              {percentFormatter(crimeRate)}
            </span>
            &nbsp;of the blocks in&nbsp;
            <span className={theme.HighlightedValue}>
              {crimeCounty} County.
            </span>
          </div>
        </PreviewSection>
      )}
    </>
  );
};

export default Crime;
