import React from 'react';

import { MissingLotLinesPlaceholderProps } from '@client/store/types/cobranded-components/missing-lot-lines';
import MissingLotLinesPin from '@client/inline-svgs/missing-lot-lines-pin';
import MissingLotLinesPinMobile from '@client/inline-svgs/missing-lot-lines-pin-mobile';
import { themr } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/MissingLotLinesPlaceholder.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const MissingLotLinesPlaceholder: React.FC<MissingLotLinesPlaceholderProps> = ({
  theme,
}) => {
  const { mapPinsColor, lotMapNullStateImageUrl } = useCobrandStyles();
  return (
    <div
      className={theme.LotMapNullState}
      style={{
        backgroundImage: `url(${lotMapNullStateImageUrl}`,
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .${theme.LotMapNullState} svg .element-to-fill  {
          fill: ${mapPinsColor} !important
          }
        `,
        }}
      />
      <p>Oops! Our supercomputers couldn’t find the lot lines</p>

      <MissingLotLinesPinMobile
        className={theme.MobilePinSVG}
        accessibilityLabel="Missing lot lines pin"
      />
      <MissingLotLinesPin
        className={theme.DesktopPinSVG}
        accessibilityLabel="Missing lot lines pin"
      />
    </div>
  );
};

const ThemedMissingLotLinesPlaceholder = themr(
  'MissingLotLinesPlaceholder',
  defaultTheme
)(MissingLotLinesPlaceholder);
export default ThemedMissingLotLinesPlaceholder;
