import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { Component } from 'react';

import ButtonMenuButtonCobranded from '@client/components/ButtonMenuButton/ButtonMenuButtonCobranded';
import ButtonMenu from '@client/components/generic/ButtonMenu';
import DropdownRange from '@client/components/generic/DropdownRange';
import MultiSelect from '@client/components/generic/MultiSelect';
import NumberAdjuster from '@client/components/generic/NumberAdjuster';
import Tooltip from '@client/components/generic/Tooltip';
import SaveSearchButton from '@client/components/SaveSearchButton';
import SearchPageFiltersContainer from '@client/containers/search-page-filters.container';
import defaultTheme from '@client/css-modules/SearchTopBarFilters.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import FilterIcon from '@client/inline-svgs/filter';
import {
  FilterKey,
  FilterPropertyTypes,
  FiltersState,
  PROPERTY_TYPE_LABELS,
} from '@client/store/filter-constants';
import {
  BEDS_FILTER_DEF as BedsFilter,
  getAllPropertyTypeOptions,
  getAllPropertyTypeValues,
  getDropdownRangeSourceValuesForListPrice,
  getPropertyTypeValues,
  LIST_PRICE_FILTER_DEF as ListPriceFilter,
  PROPERTY_TYPE_FILTER_DEF as PropertyTypeFilter,
} from '@client/utils/filters.utils';
import { abbrDollarsFormatter } from '@client/utils/string.utils';

type Props = {
  filterValues: FiltersState;
  activeFilterLabels: { key: FilterKey; label: string }[];
  hasSavedCurrentFilter: boolean;
  isSavingCurrentFilter: boolean;
  isAllowingSaveSearch: boolean;
  handleSaveSearch: () => void;
  handleFilterChange: (key: string, value: any) => void;
  handleToggleFilters: () => void;
  handleQuickFilterPropertyTypeClick: (value: any) => void;
  handleQuickFilterPriceClick: (value: any) => void;
  handleQuickFilterBedsClick: (value: any) => void;
  isDisplayMultiFamilySearchFiltersEnabled: boolean;
  userBuyingPower: number | null;
  theme: Theme;
  showFiltersOnInit: boolean;
  allFiltersButtonLabel: string;
};

type State = {
  isSearchTopBarFiltersOpen: boolean;
};

/**
 * Set of filters that live in the top bar on the map search page
 */
export class SearchTopBarFilters extends Component<Props, State> {
  state: State = {
    isSearchTopBarFiltersOpen: false,
  };

  handlePropertyTypeFilterButtonClick = (): void => {
    const { filterValues, isDisplayMultiFamilySearchFiltersEnabled } =
      this.props;
    const allPropertyTypeOptions = getAllPropertyTypeOptions(
      isDisplayMultiFamilySearchFiltersEnabled
    );

    const allPropertyTypeValues = getAllPropertyTypeValues(
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const filteredPropertyTypes =
      PropertyTypeFilter.getValueForControlFormatter(
        filterValues[PropertyTypeFilter.key],
        allPropertyTypeValues
      );
    const value =
      filteredPropertyTypes.length > 0 &&
      filteredPropertyTypes.length < allPropertyTypeOptions.length - 1
        ? filteredPropertyTypes
        : allPropertyTypeValues;

    this.props.handleQuickFilterPropertyTypeClick(value);
  };

  handleListPriceFilterButtonClick = (): void => {
    const value = this.props.filterValues[ListPriceFilter.key];
    this.props.handleQuickFilterPriceClick(value);
  };

  handleBedsFilterButtonClick = (): void => {
    const value = this.props.filterValues[BedsFilter.key][0] || 0;
    this.props.handleQuickFilterBedsClick(value);
  };

  toggleSearchTopBarFiltersOpen = (): void => {
    this.setState({
      isSearchTopBarFiltersOpen: !this.state.isSearchTopBarFiltersOpen,
    });
  };

  getPropertyTypeFilterButtonLabel = (
    filterValue: FilterPropertyTypes[]
  ): string => {
    const { isDisplayMultiFamilySearchFiltersEnabled } = this.props;
    const allPropertyTypeValues = getAllPropertyTypeValues(
      isDisplayMultiFamilySearchFiltersEnabled
    );

    if (filterValue.length === allPropertyTypeValues.length) {
      return 'Property Type: All';
    } else if (filterValue.length === 1) {
      return `Property Type: ${PROPERTY_TYPE_LABELS[filterValue[0]]}`;
    } else {
      return `Property Type (${filterValue.length})`;
    }
  };

  getListPriceFilterButtonLabel = (
    filterValue: [number | null, number | null]
  ): string => {
    if (filterValue[0] && filterValue[1]) {
      return `${ListPriceFilter.title}: ${abbrDollarsFormatter(
        filterValue[0]
      )}-${abbrDollarsFormatter(filterValue[1])}`;
    } else if (filterValue[0]) {
      return `${ListPriceFilter.title}: ${abbrDollarsFormatter(
        filterValue[0]
      )} +`;
    } else if (filterValue[1]) {
      return `${ListPriceFilter.title}: Up to ${abbrDollarsFormatter(
        filterValue[1]
      )}`;
    } else {
      return `${ListPriceFilter.title}: Any`;
    }
  };

  getBedsFilterButtonLabel = (filterValue: number): string => {
    if (filterValue) {
      return `${BedsFilter.title}: ${filterValue}+`;
    } else {
      return `${BedsFilter.title}: Any`;
    }
  };

  render() {
    const {
      activeFilterLabels,
      filterValues,
      handleFilterChange,
      hasSavedCurrentFilter,
      isSavingCurrentFilter,
      isDisplayMultiFamilySearchFiltersEnabled,
      handleToggleFilters,
      handleSaveSearch,
      theme,
      userBuyingPower,
      showFiltersOnInit,
      allFiltersButtonLabel,
    } = this.props;

    const { isSearchTopBarFiltersOpen } = this.state;
    const allPropertyTypeOptions = getAllPropertyTypeOptions(
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const propertyTypeValues = getPropertyTypeValues(
      filterValues,
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const allPropertyTypeValues = getAllPropertyTypeValues(
      isDisplayMultiFamilySearchFiltersEnabled
    );

    return (
      <AccessibleElementUniqueId>
        {({ uid }) => (
          <div className={theme.SearchTopBarFilters}>
            <div className={theme.Filters} data-hc-name={'filter-buttons'}>
              <ButtonMenu
                dataHcName={'list-price-filter'}
                theme={theme}
                textButton={this.getListPriceFilterButtonLabel(
                  filterValues[ListPriceFilter.key]
                )}
                onClick={() => this.handleListPriceFilterButtonClick()}
                menuItems={[
                  <DropdownRange
                    className={classNames(theme.DropdownRange, {
                      [theme.UserBuyingPowerInListPriceFilter]:
                        !!userBuyingPower,
                    })}
                    theme={theme}
                    labelForLeftDropDown="Min Price"
                    labelForRightDropDown="Max Price"
                    values={filterValues[ListPriceFilter.key]}
                    sources={getDropdownRangeSourceValuesForListPrice(
                      ListPriceFilter.options,
                      filterValues[ListPriceFilter.key],
                      ListPriceFilter.labelFormatter,
                      userBuyingPower
                    )}
                    handleChange={(values) =>
                      handleFilterChange(
                        ListPriceFilter.key,
                        ListPriceFilter.setValueForStateFormatter([
                          values[0],
                          values[1],
                        ])
                      )
                    }
                    userBuyingPower={userBuyingPower}
                    /* Analytics handled in callback prop in container */
                    handleReportValueSelection={() => void 0}
                  />,
                ]}
              />
              <ButtonMenu
                dataHcName={'property-type-filter'}
                theme={theme}
                textButton={this.getPropertyTypeFilterButtonLabel(
                  filterValues[PropertyTypeFilter.key]
                )}
                onClick={() => this.handlePropertyTypeFilterButtonClick()}
                menuItems={[
                  <MultiSelect
                    theme={theme}
                    values={propertyTypeValues}
                    label={PropertyTypeFilter.title}
                    options={allPropertyTypeOptions}
                    onChange={(values, valueAdded) => {
                      handleFilterChange(
                        PropertyTypeFilter.key,
                        PropertyTypeFilter.setValueForStateFormatter(
                          values as FilterPropertyTypes[],
                          valueAdded,
                          allPropertyTypeValues
                        )
                      );
                    }}
                    /* Analytics handled in callback prop in container */
                    handleReportValueSelection={() => void 0}
                  />,
                ]}
              />
              <ButtonMenu
                dataHcName={'beds-filter'}
                theme={theme}
                textButton={this.getBedsFilterButtonLabel(
                  filterValues[BedsFilter.key][0] || 0
                )}
                onClick={() => this.handleBedsFilterButtonClick()}
                menuItems={[
                  <NumberAdjuster
                    offScreenLabel
                    label={BedsFilter.title}
                    theme={theme}
                    value={filterValues[BedsFilter.key][0] || 0}
                    minValue={BedsFilter.minValue}
                    maxValue={BedsFilter.maxValue}
                    labelFormatter={BedsFilter.labelFormatter}
                    onChange={(value) =>
                      handleFilterChange(
                        BedsFilter.key,
                        BedsFilter.setValueForStateFormatter(value)
                      )
                    }
                    /* Analytics handled in callback prop in container */
                    handleReportValueSelection={() => void 0}
                  />,
                ]}
              />
              <AccessibleElementUniqueId>
                {({ uid }) => (
                  <Tooltip
                    disableClosingTooltipWithEnterAndSpace={true}
                    dataHcName={'filters-filter'}
                    handleToggleTooltip={this.toggleSearchTopBarFiltersOpen}
                    shouldScrollIntoView={false}
                    contentWrapperId={`tooltip-${uid}`}
                    contentAriaLabelledBy={`trigger-${uid}`}
                    clickToTrigger
                    showOnInit={showFiltersOnInit}
                    shouldHideCloseButton
                    shouldHideFromScreenReader
                    hasTransparentScreen
                    preventViewportHeightOverflow
                    marginFromEdge={{
                      x: 20,
                      y: 80 /* Clear the bottom nav on mobile/tablet */,
                    }}
                    trigger={
                      <ButtonMenuButtonCobranded
                        id={`trigger-${uid}`}
                        className={theme.ShowFiltersButton}
                        isActive={isSearchTopBarFiltersOpen}
                        ariaControls={`tooltip-${uid}`}
                        onClick={handleToggleFilters}
                      >
                        <span className={theme.IconWrapper}>
                          <FilterIcon className={theme.FilterIcon} />
                          {allFiltersButtonLabel}
                        </span>
                        <span
                          className={theme.FilterCount}
                          style={
                            activeFilterLabels.length > 0
                              ? {}
                              : { visibility: 'hidden' }
                          }
                        >
                          {`(${activeFilterLabels.length})`}
                        </span>
                      </ButtonMenuButtonCobranded>
                    }
                    content={<SearchPageFiltersContainer />}
                    theme={theme}
                  />
                )}
              </AccessibleElementUniqueId>
            </div>
            <div className={classNames(theme.SaveFiltersButtonContainer)}>
              <SaveSearchButton
                hasSavedCurrentFilter={hasSavedCurrentFilter}
                isSavingCurrentFilter={isSavingCurrentFilter}
                handleSaveSearch={handleSaveSearch}
              />
            </div>
          </div>
        )}
      </AccessibleElementUniqueId>
    );
  }
}

export default themr(
  'ThemedSearchTopBarFilters',
  defaultTheme
)(SearchTopBarFilters);
