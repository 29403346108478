import { ModalKey } from '@client/store/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getRentalAvm } from '@client/store/selectors/property-details.selectors';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { useSelector } from 'react-redux';
import PreviewSection from './PreviewSection';

interface Props {
  openSectionModal: (modalKey: ModalKey) => void;
}

const RentalAvm = ({ openSectionModal }: Props) => {
  const rentalAVM = useSelector(getRentalAvm);
  const isShowingRentalEstimate = !useSelector(
    getIsFeatureEnabled('hide_rental_estimate_pdp')
  );

  return (
    <>
      {rentalAVM && isShowingRentalEstimate && (
        <PreviewSection
          data-section-key={'rental_avm'}
          dataEventName={'click_property_details_rental_avm'}
          onClick={() => openSectionModal('rental_avm')}
          title="Rental Estimate"
        >
          <span>{dollarsFormatter(rentalAVM)}/month</span>
        </PreviewSection>
      )}
    </>
  );
};

export default RentalAvm;
