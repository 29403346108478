import defaultTheme from '@client/css-modules/Slider.css';
import ChevronIconWithShadow from '@client/inline-svgs/chevron-with-shadow';
import { SVGIconProps } from '@client/inline-svgs/types';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { get, uniqBy } from 'lodash';
import React, { ReactElement } from 'react';
import { findDOMNode } from 'react-dom';

/* Number in pixels to add to the component's width when calculating the number of
 * children to iterate forward when incrementByComponentWidth=true */
const FORWARD_INCREMENT_COMPONENT_WIDTH_ADJUSTMENT = 10;
/* Number of pixels after which a drag will result in incrementing the slider */
const DRAG_INCREMENT_THRESHOLD = 100;

type SliderItemProps = {
  theme: Theme;
  uId: string | number;
  childIndex?: number;
  onTap?: (uId: string | number) => void;
  style?: object;
  ariaHidden: boolean;
  /* In some instances, it's more accessible for the sliders not to use their own tab-index,
   * so that the tab-index can go straight to its children instead. */
  removeSlideTabIndex?: boolean;
  tabIndex?: number;
  children?: React.ReactNode;
};

type ChildUId = number | string;

const checkIfValidChildren = (props) => {
  const componentName = 'Slider';
  const childArr = React.Children.toArray(props.children);
  if (childArr.length === 0) {
    throw new Error(`Each ${componentName} must contain one or more children`);
  }
  if (childArr.length < 2 && props.infinityMode) {
    throw new Error(
      `If infinityMode=true, ${componentName} must contain two or more children`
    );
  }
  if (
    childArr
      .map((child) => !!get((child as any).props, props.uIdField.split('.')))
      .indexOf(false) > -1
  ) {
    throw new Error(
      `Each ${componentName} child must have a ${props.uIdField} prop`
    );
  }
  if (
    uniqBy(childArr, (child) =>
      get((child as any).props, props.uIdField.split('.'))
    ).length !== childArr.length
  ) {
    throw new Error(
      `Each ${componentName} child must have a unique ${props.uIdField} prop`
    );
  }
  if (props.infinityMode && props.includePositionIndicator) {
    /* A few bugs still need to fixed to let these 2 props play well together, and it'll probably add complexity */
    throw new Error(
      'infinityMode and includePositionIndicator are not currently compatible props'
    );
  }
  if (props.incrementByComponentWidth && props.includePositionIndicator) {
    throw new Error(
      'incrementByComponentWidth and includePositionIndicator are not compatible props'
    );
  }
};

/* Wrapper for each passed-in child to be rendered in the slider */
class SliderItem extends React.PureComponent<SliderItemProps> {
  handleTapOrClick = (): void => {
    const { uId, onTap } = this.props;
    if (onTap) {
      onTap(uId);
    }
  };

  render() {
    const {
      theme,
      children,
      uId,
      childIndex,
      onTap,
      ariaHidden,
      removeSlideTabIndex,
      tabIndex,
      ...rest
    } = this.props;

    return (
      <div
        data-uid={uId}
        data-child-index={childIndex}
        className={theme.SliderItem}
        data-hc-name="preview-photo"
        onClick={this.handleTapOrClick}
        onKeyDown={onEnterOrSpaceKey(this.handleTapOrClick)}
        role={ariaHidden ? undefined : 'group'}
        aria-roledescription={ariaHidden ? undefined : 'carousel option'}
        aria-hidden={ariaHidden}
        tabIndex={tabIndex}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

type SliderProps = {
  theme: Theme;
  className?: string;
  dataHcName?: string;
  /* The field on the child to use as unique identifier for caching of child DOM properties */
  uIdField: string;
  onClickNext?: (nxtTrackIdx: number) => void;
  onClickPrev?: (prevTrackIdx: number) => void;
  onClick?: () => void;
  /* Callback fired when tapping or clicking a slider item. */
  onTap?: (uId: string | number) => void;
  /* Whether to enable infinity mode, causing the beginning and end of the slider to wrap */
  infinityMode?: boolean;
  /* On arrow button click, increment forward or backward by a component's width worth of children */
  incrementByComponentWidth?: boolean;
  incrementAnimationDuration?: number;
  /* hide the arrow controls */
  hideRightArrow?: boolean;
  hideLeftArrow?: boolean;
  /* The arrows shown in the controls */
  sliderControl?: React.ComponentType<SVGIconProps>;
  /* moves the slider forward if you click anywhere within the slider */
  incrementOnTrackClick?: boolean;
  /* includes dots under the slider that display which position you are at in the slideshow.
   * will not render if props.IncrementByComponentWidth is true, as skipping more than one item
   * at a time is more confusing with an indicator than it is without it */
  includePositionIndicator?: boolean;
  // for reporting analytics
  reportPositionIndicatorClick?: () => void;
  /* Whether to allow the track to swipeable (able to be incremented) via touchmove.  Needs to be disabled
   * for some cases like the deep dives where interaction takes place within the slides */
  isTrackSwipeable?: boolean;
  sliderTrackDataHcName?: string;
  /* In some instances, it's more accessible for the sliders not to use their own tab-index,
   * so that the tab-index can go straight to its children instead. */
  removeSlideTabIndex?: boolean;
  /* Optional callback function to handle when lastVisibleSlideIndex changes */
  onLastVisibleSlideIndexChange?: (lastVisibleSlideIndex: number) => void;
  children?: React.ReactNode;
};

type SliderState = {
  trackIndex: number;
  lastVisibleSlideIndex: number;
  isAtStart: boolean;
  isAtEnd: boolean;
  beforeEndPosition: number;
  beforeEndTrackIndex: number;
  currentTrackPositionX: number;
  trackIndexesToRender: number[];
  childUIdToRenderOffscreen: string | number;
  dragEndPosition: number | string | null;
  slideTrackWidth: number;
};

function getIsReactElement(
  reactChild: React.ReactNode | SliderItem
): reactChild is ReactElement {
  return (reactChild as ReactElement).props !== undefined;
}

/**
 * A slider allowing for variable-width children and infinite scrolling
 *
 * ◦ The same height must be provided for .Slider and .SliderItem via a theme to give the slider
 *   a height. For image children, best to give the child a width of auto and height 100% to
 *   have the child image fill its parent .SliderItem
 * ◦ When providing <img> children, it's recommended to pre-render the number of children that will
 *   be shown in the slider on initial load using <ImagePreloader> to avoid funky top-down loading appearances
 * ◦ If using <img> or <TransformedImage> components as children, please pass data-get-width-using-onload=true
 *   into the child to tell this component to use an `onLoad` callback to get the widths
 */
class Slider extends React.Component<SliderProps, SliderState> {
  state = {
    /* The track index of the left-most child in the slider at any given time */
    trackIndex: 0,
    /* The index of the right most, last visible child in the slider at any given */
    lastVisibleSlideIndex: 0,
    isAtStart: !this.props.infinityMode && true,
    isAtEnd: !this.props.infinityMode && false,
    /* The position of the slider before it's reached the end position (when the last
     * child is right-aligned with the right edge of the slider when not in infinity mode) */
    beforeEndPosition: 0,
    /* The current track index of the slider before it's reached the end position (when the last
     * child is right-aligned with the right edge of the slider when not in infinity mode) */
    beforeEndTrackIndex: 0,
    /* The transformed position in pixels of the slider track */
    currentTrackPositionX: 0,
    /* A mapping of child indexes to whether they should be rendered on the page */
    trackIndexesToRender: [0],
    /* Child uId to render offscreen to the left. Used to get width needed to determine
     * this child's left absolute positioning */
    childUIdToRenderOffscreen: '',
    dragEndPosition: null,
    /* Used to determine if more than one slide is showing, so we can use the appropriate
     * accessibility tags on all visible slides */
    slideTrackWidth: 0,
  };
  /* A cache of slider item widths, used to absolutely position future and previous slider items */
  childWidthByUId: { [uId: string]: number } = {};
  /* A cache of slider item positions */
  childPositionByTrackIndex: { [idx: number]: number | undefined } = { 0: 0 };
  /* A cache of slider item identifiers */
  childUIdByTrackIndex: { [idx: number]: number | string } = {};
  /* The position of the user's finger when beginning to drag the slider */
  sliderDragStartCursorPosX: number = 0;
  sliderTrackNode: null | HTMLElement = null;

  afterTouchEndTimeout: number = 0;
  renderChildrenAfterIncrementTimeout: number = 0;

  SliderControl = ({ direction, onClick, theme }): ReactElement => {
    const Arrow = this.props.sliderControl || ChevronIconWithShadow;
    return (
      <button
        type="button"
        className={classNames(
          theme.SliderArrow,
          theme[`SliderArrow--${direction}`]
        )}
        aria-label={`${
          direction.toLowerCase() === 'left' ? 'previous item' : 'next item'
        }`}
        data-hc-name={`${
          direction.toLowerCase() === 'left' ? 'previous' : 'next'
        }-photo`}
        onClick={() => onClick()}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onKeyDown={onEnterOrSpaceKey((e) => {
          e.preventDefault();
          onClick();
        })}
      >
        <Arrow />
      </button>
    );
  };

  componentDidMount() {
    /* First render the previous child hidden offscreen to obtain its width. This is needed to position
     * it previous to the first visible item in the slider.  The rest of the slider items are loaded
     * via this offscreen element's ref */
    this.renderPreviousChildOffscreen();
    checkIfValidChildren(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    /* Whenever the slider is incremented forward, check to see if additional children should be rendered */
    if (this.state.trackIndex > prevState.trackIndex) {
      this.setChildrenToRenderAfterIncrementAnimation();
    }
    /* Whenever the slider is incremented backward, check to see if additional children should be rendered */
    if (this.state.trackIndex < prevState.trackIndex) {
      this.renderPreviousChildOffscreen();
    }

    /* When trackIndexesToRender changes, update the lastVisibleSlideIndex */
    if (this.state.trackIndexesToRender !== prevState.trackIndexesToRender) {
      const lastVisibleSlideIndex = this.getLastVisibleSlideIndex();
      this.setState({ lastVisibleSlideIndex: lastVisibleSlideIndex });
      this.props.onLastVisibleSlideIndexChange &&
        this.props.onLastVisibleSlideIndexChange(lastVisibleSlideIndex);
    }
  }

  componentWillUnmount() {
    if (this.getIsTrackSwipeable()) {
      this.removeSliderTouchEventListeners();
    }
    window.clearTimeout(this.afterTouchEndTimeout);
    window.clearTimeout(this.renderChildrenAfterIncrementTimeout);
  }

  getIncrementAnimationDuration = (): number =>
    this.props.incrementAnimationDuration === undefined
      ? 0.3
      : this.props.incrementAnimationDuration;

  /* When incrementing, we want to postpone the heavy lifting of rendering future children until after browser
   * has animated the slider to ensure a smooth animation */
  setChildrenToRenderAfterIncrementAnimation = (): void => {
    this.renderChildrenAfterIncrementTimeout = window.setTimeout(
      this.setChildrenToRender,
      this.getIncrementAnimationDuration()
    );
  };

  /**
   * Determine which children should be rendered by iterating children and breaking when child would be positioned
   * completely outside of the viewport.
   * Note that only one additional forward child will be rendered at each invocation of this method (necessary due
   * to needing to know the width of the previous child before rendering the next).
   * The rendering of each additional child will cause this method to execute again via its ref.
   */
  setChildrenToRender = (): void => {
    const { trackIndex } = this.state;
    const {
      incrementByComponentWidth,
      infinityMode,
      includePositionIndicator,
      children,
    } = this.props;
    /* We know that this component's rendered element can't be of type Text */
    const node = findDOMNode(this) as Element | null;
    if (!node) {
      throw new Error('Unable to get node element of Slider component');
    }
    const componentWidth = node.clientWidth;
    /* Assume this to always be defined since we're always rendering the first child to start */
    const firstChildWidth = this.getChildWidthForTrackIndex(0) as number;
    const totalNumChildren = React.Children.toArray(children).length;
    const widthToRender = incrementByComponentWidth
      ? /* When incrementing by a whole component width, we need to render at least 1 component width ahead */
        componentWidth * 2
      : includePositionIndicator && !infinityMode
        ? /* When the position indicator is showing, we assume all children are the same width and we need
           * to render all children so that we can quickly jump to the end if desired */
          (totalNumChildren - trackIndex) * firstChildWidth
        : /* When incrementing by a single child at a time, just render 1 component width worth of children */
          componentWidth;
    let widthOfChildrenInViewport = 0;
    let widthOfPreviousChildren = 0;
    let trackIndexesToRender: number[] = [];

    /* Starting at the current track index, iterate forward, adding children to the render list.
     * Loop will break either once reaching an unrendered child or an offscreen position */
    for (let i = trackIndex; i < trackIndex + 1000; i++) {
      const childIndex = this.getChildIndexForTrackIndex(i);
      const childUId = this.getChildUIdForChildIndex(childIndex);
      const childWidth = this.getChildWidthForTrackIndex(i);
      const previousChildWidth = this.getChildWidthForTrackIndex(i - 1) || 0;
      const previousChildPosition = this.childPositionByTrackIndex[i - 1] || 0;
      const hasBeenPositioned =
        typeof this.childPositionByTrackIndex[i] === 'number' && childWidth;
      const allowItemRenderForMode =
        infinityMode || (!infinityMode && i < totalNumChildren);

      /* If we're within the width for which want to render children forward */
      if (
        widthOfChildrenInViewport - previousChildWidth < widthToRender &&
        allowItemRenderForMode
      ) {
        /* This should never happen but needed to keep things type-safe */
        if (!childUId) {
          throw new Error(
            'Unexpected null childUId when setting children to render'
          );
        }
        /* If child has already been positioned, add width to total and set to render */
        if (hasBeenPositioned && childWidth) {
          widthOfChildrenInViewport += childWidth;
          this.childUIdByTrackIndex[i] = childUId;
          trackIndexesToRender.push(i);
          /* If we've reached the first unrendered child, set to render */
        } else if (infinityMode || i > 0) {
          this.childUIdByTrackIndex[i] = childUId;
          this.childPositionByTrackIndex[i] =
            previousChildPosition + previousChildWidth;
          trackIndexesToRender.push(i);
          break;
        }
      } else {
        break;
      }
    }

    /* Keep a number of children rendered offscreen to the left so that we can calculate the
     * amount needed to increment backwards in the future */
    for (
      let prevIdx = trackIndex - 1;
      prevIdx > trackIndex - (incrementByComponentWidth ? 1000 : 2);
      prevIdx--
    ) {
      const childWidth = this.getChildWidthForTrackIndex(prevIdx);
      if (childWidth && widthOfPreviousChildren <= componentWidth) {
        if (this.childPositionByTrackIndex[prevIdx] === undefined) {
          const childPos = this.childPositionByTrackIndex[prevIdx + 1];
          if (childPos !== undefined) {
            this.childPositionByTrackIndex[prevIdx] = childPos - childWidth;
          } else {
            throw new Error(
              'Child position not found when positioning previous child'
            );
          }
        }
        widthOfPreviousChildren += childWidth;
        trackIndexesToRender.push(prevIdx);
      } else {
        break;
      }
    }
    /* Render children at the specified track indexes */
    this.setState({ trackIndexesToRender });
  };

  /**
   * Get the uId of the given child component
   * An error will be thrown in `checkIfValidChildren` if any children don't have a valid uID
   */
  getChildUIdForChild = (child: React.ReactNode): ChildUId =>
    get((child as React.ReactElement).props, this.props.uIdField.split('.'));

  /**
   * Get the uId for the given child index
   */
  getChildUIdForChildIndex = (index: number): ChildUId | null => {
    const child = React.Children.toArray(this.props.children)[index];
    return child ? this.getChildUIdForChild(child) : null;
  };

  /**
   * Get the child index of the child currently at the left-most position in the slider viewport
   * @return {number} index
   */
  getCurrentChildIndex = (): number => {
    return this.state.trackIndex % React.Children.count(this.props.children);
  };

  /**
   * Get the child index for the given track index
   */
  getChildIndexForTrackIndex = (trackIndex: number): number => {
    const childCount = React.Children.count(this.props.children);
    const index =
      trackIndex > 0
        ? trackIndex % childCount
        : childCount - (Math.abs(trackIndex) % childCount);
    return index === childCount ? 0 : index;
  };

  /**
   * Get child width for given track index
   */
  getChildWidthForTrackIndex = (trackIndex: number): number | null => {
    const childIndex = this.getChildIndexForTrackIndex(trackIndex);
    const childUId = this.getChildUIdForChildIndex(childIndex);
    return childUId ? this.childWidthByUId[childUId] : null;
  };

  /**
   * Get the child index of the last child
   */
  getLastChildIndex = (): number => {
    return React.Children.count(this.props.children) - 1;
  };

  /**
   * Render the previous child to the current leftmost child offscreen so that it
   * may be animated to when moving the slider backward
   */
  renderPreviousChildOffscreen = (): void => {
    const { trackIndex, childUIdToRenderOffscreen } = this.state;
    let previousChildUId = this.getChildUIdForChildIndex(
      this.getChildIndexForTrackIndex(trackIndex - 1)
    );

    /* If incrementByComponentWidth={true} and infinityMod={true} and the previous child
     * is same at the current child */
    if (childUIdToRenderOffscreen === previousChildUId) {
      previousChildUId = this.getChildUIdForChildIndex(
        this.getChildIndexForTrackIndex(trackIndex - 2)
      );
    }
    if (previousChildUId) {
      this.setState({ childUIdToRenderOffscreen: previousChildUId });
    }
  };

  /**
   * Increment the slider forward by getting the amount of the next forward increment.
   * If not in infinity mode, determine if the right-most partially-offscreen child is
   * the last child and return the value necessary to align it with the edge if so.
   * @param  {object} e - event object, passed if this method is not triggered manually
   */
  handleIncrementForward = (
    e?: React.MouseEvent | null,
    numOfSameWidthChildrenToIncrement?: number
  ): void => {
    if (e) {
      e.stopPropagation();
    }
    const {
      incrementByComponentWidth,
      onClickNext,
      infinityMode,
      includePositionIndicator,
      children,
    } = this.props;
    const { currentTrackPositionX, trackIndex } = this.state;
    const node = findDOMNode(this) as Element | null;
    if (!node) {
      throw new Error('Unable to get node element of Slider component');
    }
    const componentWidth = node.clientWidth;
    let widthOfChildrenInViewport = 0;
    let incrementAmount = 0;
    let numChildrenToIncrement = 0;
    let isAtEnd = false;

    if (incrementByComponentWidth) {
      for (let i = trackIndex; i < trackIndex + 1000; i++) {
        const childWidth = this.getChildWidthForTrackIndex(i);
        /* If we've passed the track index where we've rendered children, break; */
        if (!childWidth) {
          incrementAmount = widthOfChildrenInViewport;
          break;
        }
        if (infinityMode) {
          /* If the very next child is wider than the component, increment forward to it */
          if (i === trackIndex && childWidth > componentWidth) {
            incrementAmount = childWidth;
            numChildrenToIncrement = 1;
            break;
          }
          /* If we've found the child that's partially offscreen on the right-side,
           * set increment to the total width of children in the viewport */
          if (
            widthOfChildrenInViewport <= componentWidth &&
            widthOfChildrenInViewport + childWidth >= componentWidth
          ) {
            incrementAmount = widthOfChildrenInViewport;
            break;
          }
          numChildrenToIncrement++;
          /* Not infinity mode */
        } else {
          /* If we're at double the viewport, break since we'll have already set the increment */
          const nextChildWidth = this.getChildWidthForTrackIndex(i + 1);

          /* This should never happen but needed to keep things type-safe */
          if (!nextChildWidth) {
            throw new Error(
              "Unexpected null nextChildWidth when attempting to increment forward by a component's width"
            );
          }
          if (widthOfChildrenInViewport + nextChildWidth > componentWidth * 2) {
            break;
          }
          /* If we're at the last child, set increment amount to align the last child
           * with the right edge of the viewport */
          if (this.getChildIndexForTrackIndex(i) === this.getLastChildIndex()) {
            isAtEnd = true;
            incrementAmount =
              widthOfChildrenInViewport + childWidth - componentWidth;
            break;
          }
          /* If we've found the child that's partially offscreen on the right-side,
           * set increment amount but don't break since we still need to ensure that
           * the last child is NOT within a viewport's width forward */
          if (
            widthOfChildrenInViewport <=
              componentWidth + FORWARD_INCREMENT_COMPONENT_WIDTH_ADJUSTMENT &&
            widthOfChildrenInViewport + childWidth >
              componentWidth + FORWARD_INCREMENT_COMPONENT_WIDTH_ADJUSTMENT
          ) {
            incrementAmount = widthOfChildrenInViewport;
            isAtEnd =
              widthOfChildrenInViewport ===
              componentWidth + FORWARD_INCREMENT_COMPONENT_WIDTH_ADJUSTMENT;
          }
          if (incrementAmount === 0) {
            numChildrenToIncrement++;
          }
        }
        widthOfChildrenInViewport += childWidth;
      }
      /* When incrementing by number of same-width children */
    } else if (includePositionIndicator && numOfSameWidthChildrenToIncrement) {
      const childWidth = this.getChildWidthForTrackIndex(trackIndex);

      /* This should never happen but needed to keep things type-safe */
      if (!childWidth) {
        throw new Error(
          'Unexpected null childWidth when attempting to increment forward by a number of same-width children'
        );
      }

      numChildrenToIncrement = numOfSameWidthChildrenToIncrement;
      incrementAmount = childWidth * numOfSameWidthChildrenToIncrement;
      isAtEnd =
        Math.abs(currentTrackPositionX) + incrementAmount + childWidth ===
        childWidth * React.Children.toArray(children).length;

      /* When incrementing by a single child */
    } else {
      numChildrenToIncrement = 1;

      for (let b = trackIndex; b < trackIndex + 1000; b++) {
        const childWidth = this.getChildWidthForTrackIndex(b);

        /* This should never happen but needed to keep things type-safe */
        if (!childWidth) {
          throw new Error(
            'Unexpected null childWidth when attempting to increment forward by a single child'
          );
        }

        if (infinityMode) {
          /* Move track by the width of the current left-most slide */
          incrementAmount =
            this.childWidthByUId[this.childUIdByTrackIndex[trackIndex]];
          break;
        } else {
          /* If we've found the child that's partially offscreen on the right-side */
          if (
            widthOfChildrenInViewport <= componentWidth &&
            widthOfChildrenInViewport + childWidth > componentWidth
          ) {
            /* If the partially offscreen child is the last child, align the last child
             * with the right side */
            if (
              this.getChildIndexForTrackIndex(b) === this.getLastChildIndex()
            ) {
              isAtEnd = true;
              incrementAmount =
                widthOfChildrenInViewport + childWidth - componentWidth;
            } else {
              /* Move track by the width of the current left-most slide */
              incrementAmount =
                this.childWidthByUId[this.childUIdByTrackIndex[trackIndex]];
            }
            break;
          }
        }
        widthOfChildrenInViewport += childWidth;
      }
    }

    const newTrackIndex = trackIndex + numChildrenToIncrement;

    this.setState({
      trackIndex: newTrackIndex,
      currentTrackPositionX: currentTrackPositionX - incrementAmount,
      isAtStart: !infinityMode && false,
      isAtEnd,
      beforeEndPosition: isAtEnd ? this.state.currentTrackPositionX : 0,
      beforeEndTrackIndex: isAtEnd ? this.state.trackIndex : 0,
      dragEndPosition: null,
    });
    if (onClickNext) {
      onClickNext(this.getChildIndexForTrackIndex(newTrackIndex));
    }
  };

  /**
   * Increment the slider backward
   * @param  {object} e - event object, passed if this method is not triggered manually
   */
  handleIncrementBackward = (
    e?: React.MouseEvent | null,
    numOfSameWidthChildrenToIncrement?: number
  ) => {
    if (e) {
      e.stopPropagation();
    }
    const {
      incrementByComponentWidth,
      onClickPrev,
      infinityMode,
      includePositionIndicator,
    } = this.props;
    const {
      currentTrackPositionX,
      isAtEnd,
      beforeEndPosition,
      trackIndex,
      beforeEndTrackIndex,
    } = this.state;
    const node = findDOMNode(this) as Element | null;
    if (!node) {
      throw new Error('Unable to get node element of Slider component');
    }
    const componentWidth = node.clientWidth;
    const isGoingBackBySingleItemAtTheEnd =
      isAtEnd && !numOfSameWidthChildrenToIncrement;
    let numChildrenToIncrement = 0;
    let incrementAmount = 0;
    let widthOfChildrenBehindViewport = 0;

    if (incrementByComponentWidth && !isAtEnd) {
      /* Starting at the previous child, iterate backwards, looking for a child that's a component's
       * width to the left of the leftmost child */
      for (let i = trackIndex - 1; i > trackIndex - 1000; i--) {
        const childWidth = this.getChildWidthForTrackIndex(i);
        /* If we've passed the track index where we've rendered children, break; */
        if (!childWidth) {
          incrementAmount = widthOfChildrenBehindViewport;
          break;
        }
        /* If we're at the first child, set increment amount to align the first child
         * with the left edge of the viewport */
        if (!infinityMode && this.getChildIndexForTrackIndex(i) === 0) {
          incrementAmount = widthOfChildrenBehindViewport + childWidth;
          numChildrenToIncrement++;
          break;
        }
        /* If the preceding child is wider than the component, increment back to it */
        if (i === trackIndex - 1 && childWidth > componentWidth) {
          incrementAmount = childWidth;
          numChildrenToIncrement = 1;
          break;
        }
        /* If we've found the child that would be partially offscreen on the left-side
         * if we jumped backward by a component width, set the increment but don't break since
         * we still need to ensure that the first child is NOT within a component's width backward */
        if (
          widthOfChildrenBehindViewport <= componentWidth &&
          widthOfChildrenBehindViewport + childWidth >= componentWidth
        ) {
          incrementAmount = widthOfChildrenBehindViewport;
        }
        /* If we're more than a full component's width behind the leftmost child, break as we will
         * have already set an increment amount */
        if (widthOfChildrenBehindViewport + childWidth > componentWidth) {
          break;
        }

        numChildrenToIncrement++;
        widthOfChildrenBehindViewport += childWidth;
      }
      /* When incrementing by number of same-width children */
    } else if (includePositionIndicator && numOfSameWidthChildrenToIncrement) {
      const childWidth = this.getChildWidthForTrackIndex(trackIndex);
      if (!childWidth) {
        throw new Error(
          'Unexpected null childWidth when attempting to incrementing backward by a number of same-width children'
        );
      }

      numChildrenToIncrement = numOfSameWidthChildrenToIncrement;
      incrementAmount = childWidth * numOfSameWidthChildrenToIncrement;
      /* Incrementing back by only a single child */
    } else {
      numChildrenToIncrement = 1;

      const childWidth = this.getChildWidthForTrackIndex(trackIndex - 1);
      if (!childWidth) {
        throw new Error(
          'Unexpected null childWidth when attempting to incrementing backward by a single child'
        );
      }

      incrementAmount = childWidth;
    }

    const newTrackIndex = isGoingBackBySingleItemAtTheEnd
      ? /* If trying to go back a single item at the end */
        beforeEndTrackIndex
      : /* If trying to go back multiple items anywhere on the track */
        trackIndex - numChildrenToIncrement;
    const newChildIndex = this.getChildIndexForTrackIndex(newTrackIndex);

    this.setState({
      trackIndex: newTrackIndex,
      currentTrackPositionX: isGoingBackBySingleItemAtTheEnd
        ? beforeEndPosition
        : currentTrackPositionX + incrementAmount,
      isAtEnd: !infinityMode && false,
      beforeEndPosition: 0,
      beforeEndTrackIndex: 0,
      isAtStart: !infinityMode && newChildIndex === 0,
      dragEndPosition: null,
    });
    if (onClickPrev) {
      onClickPrev(newChildIndex);
    }
  };

  /**
   * Decorate a child component with an `onLoad` callback prop when necessary.
   * This is needed when passing <img> or <TransformImage> components as children
   * @param  {Component} child component
   * @param  {string} uId
   * @return {Component}
   */
  decorateWithOnLoadCallbackIfNecessary = (
    child: ReactElement,
    uId: string | number
  ) => {
    return child && child.props['data-get-width-using-onload']
      ? React.cloneElement(child, {
          onLoad: (e) => {
            if (e.target && uId) {
              /* Getting the width of the parent element, .SliderItem, since it will be sized to
               * contain the width of the child image.  And this way we can theme .SliderItem to
               * apply padding if we want */
              this.childWidthByUId[uId] = e.target.parentNode.clientWidth;
              this.setChildrenToRender();
            }
          },
        })
      : child;
  };

  /**
   * Cache a child component's width after rendering
   * @param {Component} component node
   */
  handleChildComponentRender = (node: SliderItem): void => {
    if (node && getIsReactElement(node)) {
      const uId = node.props.uId;
      const ele = findDOMNode(node) as Element | null;

      if (ele) {
        this.childWidthByUId[uId] = ele.clientWidth;
        this.setChildrenToRender();
      }
    }
  };

  /* On touch start, cache the current finger position */
  handleSliderTouchStart = (e: TouchEvent): void => {
    /* If touched with a single finger */
    if (e.touches.length === 1) {
      this.sliderDragStartCursorPosX = e.touches[0].pageX;
    }
  };

  /* When dragging, move the slider accordingly */
  handleSliderTouchMove = (e: TouchEvent): void => {
    e.preventDefault();
    const { currentTrackPositionX } = this.state;
    if (e.touches.length === 1 && this.sliderTrackNode) {
      const newTrackPosX =
        currentTrackPositionX -
        (this.sliderDragStartCursorPosX - e.touches[0].pageX);
      this.sliderTrackNode.style.transform = `translateX(${newTrackPosX}px)`;
    }
  };

  /* After touch release, increment the slider either forward or backward, or animate it back
   * to the position prior to the drag if not moved enough */
  handleSliderTouchEnd = (e: TouchEvent): void => {
    const { currentTrackPositionX } = this.state;
    const changedTouchesPosX =
      e.changedTouches && e.changedTouches[0] ? e.changedTouches[0].pageX : 0;
    const dragDelta = this.sliderDragStartCursorPosX - changedTouchesPosX;
    const newTrackPosX = currentTrackPositionX - dragDelta;
    if (this.sliderTrackNode) {
      this.sliderTrackNode.style.transform = `translateX(${newTrackPosX}px)`;
    }

    /* Tell Pose to "animate" the slider to the ending drag position (with duration=0)
     * so that we can then animate it either to the next or previous child */
    this.setState({ dragEndPosition: newTrackPosX });

    /* Wait slightly to allow Pose to finish the above animation, allowing it to animate
     * again a few moments later (unfortunately using a `setState` callback won't work for this) */
    this.afterTouchEndTimeout = window.setTimeout(() => {
      const {
        includePositionIndicator,
        hideLeftArrow,
        hideRightArrow,
        infinityMode,
        children,
      } = this.props;

      const childrenArray = React.Children.toArray(children);
      const currentChild = childrenArray[this.state.trackIndex];
      const lastChild = childrenArray[childrenArray.length - 1];
      const firstChild = childrenArray[0];

      const hasLessThanThreeChildrenOnModal =
        !includePositionIndicator && hideLeftArrow && hideRightArrow;
      const isSwipingForward = dragDelta > DRAG_INCREMENT_THRESHOLD;
      const isSwipingBackward = dragDelta < -DRAG_INCREMENT_THRESHOLD;
      const swipingPastUpperBound =
        currentChild === lastChild && isSwipingForward;
      const swipingPastLowerBound =
        currentChild === firstChild && isSwipingBackward;
      const isOnFiniteModeSwipingPastUpperOrLowerBound =
        !infinityMode &&
        (swipingPastUpperBound ||
          swipingPastLowerBound ||
          hasLessThanThreeChildrenOnModal);

      if (isOnFiniteModeSwipingPastUpperOrLowerBound) {
        this.setState({ dragEndPosition: null });
      } else if (isSwipingForward) {
        this.handleIncrementForward();
      } else if (isSwipingBackward) {
        this.handleIncrementBackward();
        /* Animate back to current track index */
      } else {
        this.setState({ dragEndPosition: null });
      }
    }, 15);

    this.sliderDragStartCursorPosX = 0;
  };

  recordSliderTrackNode = (ele: HTMLDivElement): void => {
    /* Note: for some reason, a `ref` on a Pose element fires during local development
     * but does not fire after doing a prod build.  Due to this, need to select the child
     * element here to get the slider track */
    const trackNode = ele && (ele.children[0] as HTMLElement);
    if (trackNode) {
      this.sliderTrackNode = trackNode;
      this.setState({
        slideTrackWidth: this.sliderTrackNode.getBoundingClientRect().width,
      });

      if (this.getIsTrackSwipeable()) {
        this.addSliderTouchEventListeners();
      }
    }
  };

  addSliderTouchEventListeners = (): void => {
    if (this.sliderTrackNode) {
      this.sliderTrackNode.addEventListener(
        'touchstart',
        this.handleSliderTouchStart
      );
      this.sliderTrackNode.addEventListener(
        'touchmove',
        this.handleSliderTouchMove
      );
      this.sliderTrackNode.addEventListener(
        'touchend',
        this.handleSliderTouchEnd
      );
    }
  };

  removeSliderTouchEventListeners = (): void => {
    if (this.sliderTrackNode) {
      this.sliderTrackNode.removeEventListener(
        'touchstart',
        this.handleSliderTouchStart
      );
      this.sliderTrackNode.removeEventListener(
        'touchmove',
        this.handleSliderTouchMove
      );
      this.sliderTrackNode.removeEventListener(
        'touchend',
        this.handleSliderTouchEnd
      );
    }
  };

  handlePositionIndicatorClick = (dotIndex: number): void => {
    const { trackIndex } = this.state;
    const { reportPositionIndicatorClick } = this.props;
    const childIndex = this.getChildIndexForTrackIndex(trackIndex);
    let numToIncrement = Math.abs(dotIndex - childIndex);

    if (dotIndex > childIndex) {
      this.handleIncrementForward(null, numToIncrement);
    } else if (dotIndex < childIndex) {
      this.handleIncrementBackward(null, numToIncrement);
    }
    if (reportPositionIndicatorClick) {
      reportPositionIndicatorClick();
    }
  };

  /* Default to true if prop not supplied */
  getIsTrackSwipeable = (): boolean =>
    this.props.isTrackSwipeable === undefined
      ? true
      : this.props.isTrackSwipeable;

  /* returns the index of the last visible slide. Used to mark non-visible slides as aria-hidden for accessibility */
  getLastVisibleSlideIndex = (): number => {
    let lastIndex = 0;
    let totalSlidesWidth = 0;
    const trackWidth = this.state.slideTrackWidth;
    const indexArr = this.state.trackIndexesToRender;

    while (trackWidth >= totalSlidesWidth && lastIndex !== indexArr.length) {
      totalSlidesWidth += this.getChildWidthForTrackIndex(lastIndex) || 0;
      if (trackWidth <= totalSlidesWidth) {
        break;
      }
      lastIndex++;
    }

    return lastIndex;
  };

  render() {
    const {
      children,
      theme,
      className,
      onClick,
      dataHcName,
      uIdField,
      infinityMode,
      hideRightArrow,
      hideLeftArrow,
      incrementOnTrackClick,
      includePositionIndicator,
      incrementByComponentWidth,
      onTap,
      isTrackSwipeable,
      removeSlideTabIndex,
      /* Unused but not desired in `rest` */
      onClickPrev,
      onClickNext,
      incrementAnimationDuration,
      sliderControl,
      reportPositionIndicatorClick,
      onLastVisibleSlideIndexChange,
      sliderTrackDataHcName,
      /* ** */
      ...rest
    } = this.props;

    const {
      trackIndex,
      trackIndexesToRender,
      currentTrackPositionX,
      dragEndPosition,
      isAtStart,
      isAtEnd,
      childUIdToRenderOffscreen,
      lastVisibleSlideIndex,
    } = this.state;

    const Arrow = this.SliderControl;
    const childArr = React.Children.toArray(children).filter(
      (child) => child && getIsReactElement(child)
    ) as ReactElement[];

    return (
      <div
        className={classNames(theme.Slider, className)}
        data-hc-name={dataHcName}
        {...rest}
      >
        <div
          className={theme.SliderTrackWrapper}
          ref={this.recordSliderTrackNode}
        >
          <motion.div
            data-hc-name={sliderTrackDataHcName}
            onClick={
              incrementOnTrackClick
                ? this.handleIncrementForward
                : onClick || undefined
            }
            animate={{
              x: dragEndPosition || currentTrackPositionX,
              transition: {
                damping: 0,
                duration: dragEndPosition
                  ? 0
                  : this.getIncrementAnimationDuration(),
              },
            }}
            className={classNames(theme.SliderTrack, {
              [theme.clickable]: incrementOnTrackClick,
            })}
          >
            {trackIndexesToRender.map((childTrackIndex, arrIndex) => {
              const childIndex =
                this.getChildIndexForTrackIndex(childTrackIndex);
              const child = childArr[childIndex];
              const uId = this.getChildUIdForChild(child);

              /* For accessibility we mark all non-visible slides as aria-hidden */
              const isVisibleIndex = arrIndex <= lastVisibleSlideIndex;

              return (
                <SliderItem
                  key={`${uId}${childTrackIndex}`}
                  uId={uId}
                  childIndex={childIndex}
                  theme={theme}
                  onTap={onTap}
                  ariaHidden={!isVisibleIndex}
                  tabIndex={isVisibleIndex ? 0 : undefined}
                  removeSlideTabIndex={removeSlideTabIndex}
                  style={{
                    left: `${this.childPositionByTrackIndex[childTrackIndex]}px`,
                    /* This is applied via CSS, but defining inline helps fix an issue whether the slider isn't
                     * sized correctly during local HMR */
                    position: 'absolute',
                    visibility: !isVisibleIndex ? 'hidden' : undefined,
                  }}
                  ref={
                    !child.props['data-get-width-using-onload']
                      ? this.handleChildComponentRender
                      : () => null
                  }
                >
                  {this.decorateWithOnLoadCallbackIfNecessary(child, uId)}
                  {/* For debugging */
                  /* <span className={theme.Index}>{childTrackIndex}</span> */}
                </SliderItem>
              );
            })}
            {childUIdToRenderOffscreen && (
              <div className={theme.Offscreen}>
                {(() => {
                  const child = React.Children.toArray(children)
                    .filter((child) => child && getIsReactElement(child))
                    .find(
                      (child) =>
                        this.getChildUIdForChild(child) ===
                        childUIdToRenderOffscreen
                    ) as ReactElement;
                  return (
                    child && (
                      <SliderItem
                        removeSlideTabIndex={removeSlideTabIndex}
                        ariaHidden={true}
                        tabIndex={-1}
                        key={childUIdToRenderOffscreen}
                        theme={theme}
                        uId={childUIdToRenderOffscreen}
                        ref={
                          !child.props['data-get-width-using-onload']
                            ? this.handleChildComponentRender
                            : () => null
                        }
                        style={{
                          visibility: 'hidden',
                        }}
                      >
                        {this.decorateWithOnLoadCallbackIfNecessary(
                          child,
                          childUIdToRenderOffscreen
                        )}
                      </SliderItem>
                    )
                  );
                })()}
              </div>
            )}
          </motion.div>
        </div>
        <div className={theme.Controls}>
          {!hideLeftArrow && !isAtStart && (
            <Arrow
              theme={theme}
              direction="left"
              onClick={this.handleIncrementBackward}
            />
          )}
          {!hideRightArrow && !isAtEnd && (
            <Arrow
              theme={theme}
              direction="right"
              onClick={this.handleIncrementForward}
            />
          )}
        </div>
        {includePositionIndicator && !incrementByComponentWidth && (
          <div
            data-hc-name="nearby-listings-dots"
            role="tablist"
            className={classNames(theme.NavDots, {
              [theme.NavDotsWithLessThanThreeItems]: childArr.length < 3,
            })}
          >
            {childArr.map((child, i) => {
              return (
                <div
                  onClick={() => this.handlePositionIndicatorClick(i)}
                  onKeyDown={onEnterOrSpaceKey(() =>
                    this.handlePositionIndicatorClick(i)
                  )}
                  tabIndex={0}
                  role="tab"
                  aria-selected={
                    this.getChildIndexForTrackIndex(trackIndex) === i
                      ? true
                      : false
                  }
                  aria-label={`Show slide ${i + 1} of ${childArr.length}`}
                  key={`indicator-${i}`}
                  className={classNames(theme.Dot, {
                    [theme.active]:
                      this.getChildIndexForTrackIndex(trackIndex) === i,
                  })}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default themr('ThemedSlider', defaultTheme)(Slider);
