import React from 'react';
import { useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import TopAgentsRankedLogo from '@client/inline-svgs/cobrand/topagentsranked/top-agents-ranked-logo';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import defaultTheme from '@client/css-modules/ConnectWithATopLocalAgent.css';
import {
  getIsLoggedIn,
  getUserPartnerUserId,
  getChapiPartnerInviteId,
} from '@client/store/selectors/auth.selectors';
import {
  getTARCTALink,
  useReportTAREvent,
} from '@client/components/cobrand/topagentsranked/top-agents-ranked-utils';
import { TopLocalAgentAdDetailsInterface } from '@hc/consumer-customization-data/lib/types';
import PillButton from '@client/components/generic/PillButton';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';

type Props = {
  theme: Theme;
  key?: string;
};

const ConnectWithATopLocalAgent: React.FC<Props> = ({ theme, key }) => {
  const reportTAREvent = useReportTAREvent();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const partnerUserId = useSelector(getUserPartnerUserId);
  const chapiPartnerInviteId = useSelector(getChapiPartnerInviteId);
  const { top_local_agent_ad_details } = useSelector(getCustomizationData);

  const handleTalkToAnAgentClick = (
    tarAdDetails: TopLocalAgentAdDetailsInterface | null
  ) => {
    const ctaLink = getTARCTALink(
      tarAdDetails,
      isLoggedIn,
      partnerUserId,
      chapiPartnerInviteId
    );

    reportTAREvent();
    window.open(ctaLink, '_blank');
  };

  return (
    <div className={theme.ConnectWithATopLocalAgentContainer} key={key}>
      <div className={theme.LogoAndLabel}>
        <div
          className={theme.LogoWrapper}
          data-hc-name="top-agents-ranked-logo"
        >
          <TopAgentsRankedLogo           
            ariaProps={{
            title: "Top Agents Ranked",
            role: "img",
            ariaLabelledBy:"top-agents-ranked",
          }} />
        </div>
        <div
          aria-label="Top Agents Ranked"
          className={theme.LogoLabel}
          data-hc-name="title"
        >
          TopAgentsRanked
        </div>
      </div>
      <div
        aria-label={
          top_local_agent_ad_details?.srp_ho_header ??
          'Connect with a top local agent'
        }
        className={theme.Description}
        data-hc-name="desc"
      >
        {top_local_agent_ad_details?.srp_ho_header ??
          'Connect with a top local agent'}
      </div>
      <PillButton
        ariaLabel={
          top_local_agent_ad_details?.srp_ho_button_title ?? 'Email an agent'
        }
        dataHcName="talk-to-an-agent-button"
        className={theme.PillButton}
        onClick={() => {
          handleTalkToAnAgentClick(top_local_agent_ad_details);
        }}
        onKeyDown={onEnterOrSpaceKey(() => {
          handleTalkToAnAgentClick(top_local_agent_ad_details);
        })}
      >
        {top_local_agent_ad_details?.srp_ho_button_title ?? 'Email an agent'}
      </PillButton>
    </div>
  );
};

const ThemedConnectWithATopLocalAgent = themr(
  'ConnectWithATopLocalAgent',
  defaultTheme
)(ConnectWithATopLocalAgent);
export default ThemedConnectWithATopLocalAgent;
