import React, { Component } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { LngLatBounds } from 'mapbox-gl';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { LatitudeLongitudeObject } from '@client/store/types/maps';
import defaultTheme from '@client/css-modules/AvmBreakdownVertical.css';
import CobrandedComponent, {
  CobrandedComponentArgs,
} from '@client/components/CobrandedComponent';
import AvmFactorTypeIcon from '@client/components/AvmFactorTypeIcon';
import AvmDeepDiveContainer from '@client/containers/avm-deep-dive.container';
import {
  getMapboxGLBoundsForBounds,
  getBoundsFromCenterPointAndZoom,
} from '@client/utils/maps.utils';
import { RegressionData } from '@client/store/types/regression-data';
import { ICON_FOR_AVM_FACTOR_TYPE } from '@client/store/constants';
import {
  buildMedianValues,
  DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM,
  getHasAvmFactors,
  getShowDataDeepDives,
  VIRTUAL_MAP_DIMENSIONS,
} from '@client/utils/avm-data-deep-dives.utils';
import { AvmFactor, MedianValues } from '@client/store/types/avm-break-down';
import PillButton from '@client/components/generic/PillButton';
import AvmBreakdownAvmValueCobranded from '@client/components/AvmBreakdownAvmValue/AvmBreakdownAvmValueCobranded';
import AVMFactorDeltaValueCobranded from '@client/components/AVMFactorDeltaValue/AVMFactorDeltaValueCobranded';
import AvmBreakdownAvmDefinitionCobranded from '@client/components/AvmBreakdownAvmDefinition/AvmBreakdownAvmDefinitionCobranded';

type Props = {
  avmFSD: number | null;
  isAppMounted: boolean;
  isAvmFactorsLoading: boolean;
  avmFactors: AvmFactor[] | null;
  avmValue: number | null;
  addressSlug: string | null;
  defaultDeepDivesFactorToLaunchWith: string | null;
  fullAddress: string | null;
  propertyPhoto: string | null;
  isLoadingRegressionsData: boolean;
  fetchAvmFactors: () => void;
  fetchPropertyAvmDeepDiveData: () => void;
  /* Method called with the trigger hover/click event as the only argument.  When passed,
   * the displaying of the tooltip will need to be handled externally
   * this prop is not required when component is used in PropertyPageMobileContents
   */
  isAvmDeepDiveModalActive: boolean;
  handleShowAvmDeepDiveModal: () => void;
  handleHideAvmDeepDiveModal: () => void;
  handleGetMapLayerLegendBreaks: (bounds: LngLatBounds, zoom: number) => void;
  propertyLocation: LatitudeLongitudeObject | null;
  propertyStatus: string | null;
  regressionsData: RegressionData[];
  medianValues: MedianValues | null;
  showLocationInDataDeepDives: boolean;
  theme: Theme;
};

type State = {
  avmDeepDiveFactorActive: string | null;
};

/**
 * Display the property AVM followed by the components the comprise the AVM
 */
class AvmBreakdownVertical extends Component<Props, State> {
  peekAnim: any = null;

  state: State = {
    avmDeepDiveFactorActive: null,
  };

  componentDidMount() {
    const { isAppMounted } = this.props;

    /* Since multiple versions of this component mount on PDP SSR for multiple screen sizes (then some
     * unmount), ensure we're not sending init requests multiple times when SSRing the PDP by only
     * fetching init data after app mounts */
    if (isAppMounted) {
      this.fetchComponentData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isAppMounted } = this.props;

    if (prevProps.isAvmFactorsLoading && !this.props.isAvmFactorsLoading) {
      this.props.fetchPropertyAvmDeepDiveData();
    }
    if (!prevProps.isAppMounted && isAppMounted) {
      this.fetchComponentData();
    }
  }

  componentWillUnmount() {
    if (this.peekAnim) {
      clearTimeout(this.peekAnim);
    }
  }

  fetchComponentData = () => {
    const {
      isAvmFactorsLoading,
      fetchAvmFactors,
      fetchPropertyAvmDeepDiveData,
      handleGetMapLayerLegendBreaks,
      propertyLocation,
    } = this.props;

    /* This should always be defined when the component mounts */
    if (propertyLocation) {
      const { latitude, longitude } = propertyLocation;
      const mapBounds = getBoundsFromCenterPointAndZoom(
        [latitude, longitude],
        DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM,
        VIRTUAL_MAP_DIMENSIONS
      );

      fetchAvmFactors();
      /**
       * Make sure medium block price heatmap data exists before we render location in
       * data deep dives, fetching heatmap legend breaks with approximate map size and zoom.
       */
      handleGetMapLayerLegendBreaks(
        getMapboxGLBoundsForBounds(mapBounds),
        DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM
      );
    } else {
      throw new Error('AvmBreakdownVertical mounted without propertyLocation');
    }

    if (!isAvmFactorsLoading) {
      fetchPropertyAvmDeepDiveData();
    }
  };

  handleOpenAvmDeepDive = (factorType: string | null) => {
    const {
      avmFactors,
      avmValue,
      isLoadingRegressionsData,
      regressionsData,
      showLocationInDataDeepDives,
      handleShowAvmDeepDiveModal,
    } = this.props;
    const hasAvmFactors = getHasAvmFactors(avmValue, avmFactors);

    if (
      getShowDataDeepDives(
        hasAvmFactors,
        isLoadingRegressionsData,
        regressionsData,
        showLocationInDataDeepDives
      )
    ) {
      this.setState({ avmDeepDiveFactorActive: factorType });
      handleShowAvmDeepDiveModal();
    }
  };

  render() {
    const {
      avmValue,
      avmFSD,
      avmFactors,
      defaultDeepDivesFactorToLaunchWith,
      medianValues,
      isAvmDeepDiveModalActive,
      isLoadingRegressionsData,
      theme,
      handleHideAvmDeepDiveModal,
      regressionsData,
      showLocationInDataDeepDives,
    } = this.props;
    const { avmDeepDiveFactorActive } = this.state;

    const medianValuesComponents = medianValues
      ? buildMedianValues(medianValues)
      : [];
    const hasAvmFactors = getHasAvmFactors(avmValue, avmFactors);
    const showDataDeepDives = getShowDataDeepDives(
      hasAvmFactors,
      isLoadingRegressionsData,
      regressionsData,
      showLocationInDataDeepDives
    );

    return (
      <>
        <CobrandedComponent key="cobranded-component">
          {({
            styles: {
              avmBreakdownBackground,
              avmBreakdownBorderRadius,
              avmBreakdownBorderColor,
              avmBreakdownBoxShadow,
            },
            utils: { getShouldDisplayAVMForFSD },
          }: CobrandedComponentArgs) => {
            const isShowingAVMForFSD =
              avmFSD && getShouldDisplayAVMForFSD(avmFSD);

            return (
              <>
                {isShowingAVMForFSD && (
                  <div className={theme.Heading}>
                    More Information On This Home
                  </div>
                )}
                <div
                  className={theme.AvmBreakdown}
                  key="AvmBreakdown"
                  style={
                    isShowingAVMForFSD
                      ? {
                          border: `1px solid ${avmBreakdownBorderColor}`,
                          borderRadius: avmBreakdownBorderRadius,
                          boxShadow: avmBreakdownBoxShadow,
                        }
                      : {}
                  }
                >
                  {isShowingAVMForFSD &&
                    (avmValue ? (
                      <>
                        <div
                          key="avm-section"
                          className={theme.AvmSection}
                          style={{
                            background: avmBreakdownBackground,
                            borderTopRightRadius: avmBreakdownBorderRadius,
                            borderTopLeftRadius: avmBreakdownBorderRadius,
                          }}
                        >
                          <div
                            className={theme.AvmLabelValueWrapper}
                            onClick={() =>
                              this.handleOpenAvmDeepDive(
                                defaultDeepDivesFactorToLaunchWith
                              )
                            }
                          >
                            <AvmBreakdownAvmValueCobranded
                              value={avmValue}
                              theme={theme}
                            />
                          </div>
                          <AvmBreakdownAvmDefinitionCobranded
                            noAvmFactors={
                              !hasAvmFactors || isEmpty(medianValuesComponents)
                            }
                            showDataDeepDives={showDataDeepDives}
                            maxWidth={300}
                            initialShiftAmount={-60}
                          />
                        </div>
                        {hasAvmFactors && (
                          <ul
                            className={theme.AvmFactorsSection}
                            key="AvmFactors"
                          >
                            {medianValuesComponents.length > 0 && (
                              <li
                                className={theme.AvmFactor}
                                key="median-values"
                              >
                                <div
                                  onClick={() =>
                                    this.handleOpenAvmDeepDive(
                                      defaultDeepDivesFactorToLaunchWith
                                    )
                                  }
                                >
                                  {medianValuesComponents}
                                </div>
                              </li>
                            )}
                            {avmFactors &&
                              avmFactors.map(
                                (factor) =>
                                  /* Ensure that we have an icon for the factor type before displaying */
                                  ICON_FOR_AVM_FACTOR_TYPE.includes(
                                    factor.type
                                  ) && (
                                    <li
                                      className={theme.AvmFactor}
                                      key={factor.type}
                                      onClick={() =>
                                        this.handleOpenAvmDeepDive(factor.label)
                                      }
                                    >
                                      {/**
                                       * Only Icons used for chase cobrand have classnames
                                       * AvmFactorIconPositive & AvmFactorIconNegative on them.
                                       * On all other cobrands these classnames won't be effective
                                       */}
                                      <div
                                        className={classNames(
                                          theme.AvmFactorIcon,
                                          {
                                            [theme.AvmFactorIconPositive]:
                                              factor.value >= 0,
                                            [theme.AvmFactorIconNegative]:
                                              factor.value < 0,
                                          }
                                        )}
                                      >
                                        <div
                                          className={theme.AvmFactorIconLabel}
                                        >
                                          {factor.label}
                                        </div>
                                        <AvmFactorTypeIcon
                                          type={factor.type}
                                          className={defaultTheme.icon}
                                        />
                                      </div>
                                      <div className={theme.AvmFactorContent}>
                                        <div
                                          className={theme.AvmFactorDescription}
                                        >
                                          {factor.description}
                                        </div>
                                        <AVMFactorDeltaValueCobranded
                                          value={factor.value}
                                          theme={theme}
                                        />
                                      </div>
                                    </li>
                                  )
                              )}
                          </ul>
                        )}
                        {isEmpty(medianValuesComponents) && !hasAvmFactors && (
                          <div
                            className={classNames(
                              theme.DefaultAvmValueExplanation,
                              {
                                [theme.DefaultAvmValueWithSeeMoreButton]:
                                  showDataDeepDives,
                              }
                            )}
                          >
                            This is ComeHome’s estimated market value for this
                            home. It is not a formal appraisal. This estimate is
                            based on our market knowledge, and it should be used
                            as a starting point to determine a home’s value.
                          </div>
                        )}
                        {showDataDeepDives && (
                          <div className={theme.SeeMoreButtonContainer}>
                            <PillButton
                              ariaLabel="See more"
                              onClick={() =>
                                this.handleOpenAvmDeepDive(
                                  defaultDeepDivesFactorToLaunchWith
                                )
                              }
                              theme={theme}
                            >
                              See More
                            </PillButton>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        key="no-avm-section"
                        className={theme.NoAvmSection}
                        style={{
                          background: avmBreakdownBackground,
                          borderRadius: avmBreakdownBorderRadius,
                        }}
                      >
                        <div>
                          We don't have enough information to calculate a value.
                        </div>
                      </div>
                    ))}
                </div>
              </>
            );
          }}
        </CobrandedComponent>
        <AvmDeepDiveContainer
          modalTitle={'Data Deep Dive'}
          isActive={isAvmDeepDiveModalActive}
          avmDeepDiveFactorActive={avmDeepDiveFactorActive}
          handleClose={handleHideAvmDeepDiveModal}
        />
      </>
    );
  }
}

export default themr(
  'ThemedAvmBreakdownVertical',
  defaultTheme
)(AvmBreakdownVertical);
