import { Status, STATUSES } from '@client/store/constants';
import { ReduxState } from '@client/store/types/redux-state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { NormalizedGrantProperty } from '../types/property';

export type GrantProgramState = {
  censusTractDataFetchStatus: Status;
  created: string | null;
  censusTractData:
    | null
    | {
        grant_amount: number;
        tract_id: string;
      }[];
  id: string | null;
  selectedPropertyDetails: NormalizedGrantProperty | null;
  isSRPGrantFilterOn: boolean;
};

export const INITIAL_GRANT_PROGRAM_STATE: GrantProgramState = {
  censusTractDataFetchStatus: STATUSES.INIT,
  created: null,
  censusTractData: null,
  id: null,
  selectedPropertyDetails: null,
  isSRPGrantFilterOn: true,
};

export const GrantProgramSlice = createSlice({
  name: 'grantProgram',
  initialState: INITIAL_GRANT_PROGRAM_STATE,
  reducers: {
    fetchCensusTractData: (state) => {},
    fetchCensusTractDataLoading: (state) => ({
      ...state,
      censusTractDataFetchStatus: STATUSES.LOADING,
    }),
    fetchCensusTractDataSuccess: (
      state,
      action: PayloadAction<{
        created: string | null;
        id: string | null;
        data: GrantProgramState['censusTractData'];
      }>
    ) => ({
      ...state,
      id: action.payload.id,
      created: action.payload.created,
      censusTractData: action.payload.data,
      censusTractDataFetchStatus: STATUSES.SUCCESS,
    }),
    setSelectedPropertyDetails: (
      state,
      action: PayloadAction<NormalizedGrantProperty>
    ) => ({
      ...state,
      selectedPropertyDetails: action.payload,
    }),
    clearSelectedPropertyDetails: (state) => ({
      ...state,
      selectedPropertyDetails: null,
    }),
    toggleSRPGrantFilter: (state) => ({
      ...state,
      isSRPGrantFilterOn: !state.isSRPGrantFilterOn,
    }),
  },
});

export function selectCensusTract(state: ReduxState) {
  return state.grantProgram;
}
export const selectCensusTractStatus = createSelector(
  selectCensusTract,
  (grantProgram) => grantProgram.censusTractDataFetchStatus
);
export const selectTractIdForModalDisplay = createSelector(
  selectCensusTract,
  (grantProgram) => grantProgram.selectedPropertyDetails?.tractId || null
);
export const selectPropertyDetailsForModalDisplay = createSelector(
  selectCensusTract,
  (grantProgram) => grantProgram.selectedPropertyDetails
);
export const selectIsSRPGrantFilterOn = createSelector(
  selectCensusTract,
  (grantProgram) => grantProgram.isSRPGrantFilterOn
);
export const selectCensusTractData = createSelector(
  selectCensusTract,
  selectIsSRPGrantFilterOn,
  (grantProgram, isEnabled) => (isEnabled ? grantProgram.censusTractData : null)
);
export function selectGrantAmountForTractId(tractId: string | null) {
  return createSelector(selectCensusTract, (grantProgram) =>
    tractId
      ? grantProgram.censusTractData?.find((d) => d.tract_id === tractId)
          ?.grant_amount ?? null
      : null
  );
}
export const getReportEventPropsFromPropertyDetails = (
  propertyDetails: NormalizedGrantProperty | null
) => ({
  street: propertyDetails?.streetAddress ?? '',
  city: propertyDetails?.city ?? '',
  state: propertyDetails?.state ?? '',
  zip: propertyDetails?.zipcode ?? '',
  unit: propertyDetails?.unit ?? null,
  address_id: propertyDetails?.hcAddressId ?? null,
  slug: propertyDetails?.slug,
});

export const selectReportEventProps = createSelector(
  selectPropertyDetailsForModalDisplay,
  getReportEventPropsFromPropertyDetails
);

export const {
  fetchCensusTractData,
  setSelectedPropertyDetails,
  clearSelectedPropertyDetails,
  toggleSRPGrantFilter,
  fetchCensusTractDataLoading,
  fetchCensusTractDataSuccess,
} = GrantProgramSlice.actions;

export default GrantProgramSlice.reducer;
