import {
  watchEvery,
  watchLatest,
  watchLeading,
} from '@client/utils/saga.utils';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { addYears, format, subYears } from 'date-fns';
import { get, uniq } from 'lodash';
import { call, delay, put, select } from 'redux-saga/effects';

import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  FETCH_FULL_PROPERTY_DETAILS,
  FETCH_INIT_PROPERTY_DETAILS,
  FETCH_MORTGAGE_AND_TERMS,
  FETCH_MORTGAGE_SUMMARY,
  FETCH_PROPERTY_AVM_DEEP_DIVE_DATA,
  FETCH_PROPERTY_AVM_FACTORS,
  fetchFullPropertyDetailsSuccess,
  fetchInitPropertyDetailsError,
  fetchInitPropertyDetailsSuccess,
  FetchMortgageAndTermsAction,
  fetchMortgageAndTermsError,
  fetchMortgageAndTermsSuccess,
  FetchMortgageSummaryAction,
  fetchMortgageSummaryError,
  fetchMortgageSummarySuccess,
  fetchPropertyAvmDeepDiveDataSuccess,
  fetchPropertyAvmFactorsSuccess,
  REPORT_PROPERTY_PAGE_MORTGAGE_CALCULATOR,
  SET_LOCAL_STORAGE_PROPERTY_SEEN,
} from '@client/store/actions/property-details.actions';
import { SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE } from '@client/store/actions/search.actions';
import { SESSION_STORAGE_KEYS } from '@client/store/constants';
import {
  FullPropertyLookupWithAddressRequired,
  InitPropertyLookupWithAddressRequired,
  MortgageCalculationsForUIComponents,
} from '@client/store/types/property';

import { consumerApiClient } from '@client/services/consumer-api-client';
import { graphQLApiClient } from '@client/services/graphql-api-client';

import { fetchClaimedHomesIfNecessary } from '@client/store/actions/homeowner.actions';
import { getCobrandUtilMethods } from '@client/store/selectors/cobranding-utils.selectors';
import {
  getAvmFactorsWithLocation,
  getMortgageCalculationDetails,
  getPropertyAddressSlug,
} from '@client/store/selectors/property-details.selectors';

/* This saga is used when navigating from another page in the app to a PDP.
 * When a PDP is loaded directly via URL, the data is gotten server-side and delivered
 * to the client through SSR */
function* getInitPropertyDetailsSaga(action) {
  const slug = action.payload.slug as string;
  if (slug) {
    try {
      const propertyLookup = (yield call(
        [graphQLApiClient, graphQLApiClient.getPropertyDetailsInit],
        { slug }
      )) as InitPropertyLookupWithAddressRequired;

      yield put(
        fetchInitPropertyDetailsSuccess({
          slug,
          data: propertyLookup,
        })
      );
    } catch (error: any) {
      yield put(fetchInitPropertyDetailsError({ slug }));
      // Rethrow for Sentry reporting
      throw error;
    }
  } else {
    yield put(routeChange({ view: View.PAGE_404, options: { replace: true } }));
  }
}

/* This is called after the PDP mounts to fully flesh out the Property Details for the breakout sections */
function* getFullPropertyDetailsSaga(action) {
  const slug = action.payload.slug as string;
  const now = new Date();
  const dateFiveYearsAgo = format(subYears(now, 5), 'YYYY-MM-DD');
  const dateOneYearAhead = format(addYears(now, 1), 'YYYY-MM-DD');
  const dateThreeYearsAhead = format(addYears(now, 3), 'YYYY-MM-DD');

  /* Slight delay to allow other more immediately necessary page elements to load faster */
  yield delay(800);

  if (slug) {
    const propertyLookup = (yield call(
      [graphQLApiClient, graphQLApiClient.getFullPropertyDetails],
      slug,
      dateFiveYearsAgo,
      dateOneYearAhead,
      dateThreeYearsAhead
    )) as FullPropertyLookupWithAddressRequired;

    /* Fetch homeowner claimed homes if feature enabled and other conditions are met. Needed to determine
     * whether to display the homeowner ad on the PDP */
    yield put(fetchClaimedHomesIfNecessary());

    yield put(fetchFullPropertyDetailsSuccess({ slug, data: propertyLookup }));
  }
}

function setPropertyVisitedInStorage(action) {
  let slug = action.payload.slug;
  const visitedPropertiesString =
    window.sessionStorage &&
    window.sessionStorage.getItem(SESSION_STORAGE_KEYS.VISITED_PROPERTIES);
  if (window.sessionStorage) {
    window.sessionStorage.setItem(
      SESSION_STORAGE_KEYS.VISITED_PROPERTIES,
      uniq([
        ...(visitedPropertiesString ? visitedPropertiesString.split(',') : []),
        slug,
      ]).join(',')
    );
  }
}

function* getAvmFactorsSaga() {
  const addressSlug = yield select(getPropertyAddressSlug);
  if (addressSlug) {
    /* RESPONSE FORMAT:
     * propertyLookup: {
     *   avmFactors: {
     *     __typename
     *     displayName
     *     comparisonDescription
     *     accessibleComparisonDescription
     *     value
     *   }
     * }
     */
    const data = yield call(
      [graphQLApiClient, graphQLApiClient.getAvmFactors],
      addressSlug
    );
    yield put(
      fetchPropertyAvmFactorsSuccess({
        slug: addressSlug,
        avmFactors: get(data, ['propertyLookup', 'avmFactors']),
        avm: get(data, ['propertyLookup', 'avm']),
      })
    );
  }
}

function* getAvmDeepDiveDataSaga() {
  const addressSlug = yield select(getPropertyAddressSlug);
  if (addressSlug) {
    /* RESPONSE FORMAT:
     * propertyLookup: {
     *   avmFactors: {
     *     __typename
     *     displayName
     *     comparisonDescription
     *     accessibleComparisonDescription
     *     value
     *   }
     * }
     */
    const data = yield call(
      [graphQLApiClient, graphQLApiClient.getAvmDeepDiveData],
      addressSlug
    );
    const avmFactorsWithLocation = yield select(getAvmFactorsWithLocation);

    yield put(
      fetchPropertyAvmDeepDiveDataSuccess({
        slug: addressSlug,
        regressionsData: get(data, ['propertyLookup', 'regressions']),
        tractStatsCount: get(data, ['propertyLookup', 'tractStats', 'count']),
        avmFactorsWithLocation,
      })
    );
  }
}

function* getMortgageTermsSaga(action: FetchMortgageAndTermsAction) {
  const { activePropertySlug, data, isFetchingDefaultTerms } = action.payload;
  const cobrandUtilMethods = yield select(getCobrandUtilMethods);
  try {
    let body;
    /**
     * Interest rate is a string for FAM as we have interest rate rounding
     * /calculate API needs interest rate to be a number
     * */
    body = {
      down_payment: data.downPayment,
      down_payment_pct: data.downPaymentPct,
      interest_rate:
        typeof data.interestRate === 'string'
          ? +data.interestRate
          : data.interestRate,
      home_price: data.homePrice,
      mortgage_id: data.mortgageId,
    };

    /**
     * we only have homePrice defined for the initial call made to get mortgage calculations
     * Removing keys with null values before making the API call
     */
    Object.keys(body).forEach((key: string) => {
      if (body[key] === null) delete body[key];
    });

    const response = yield call(
      [consumerApiClient, consumerApiClient.calculateMortgage],
      body
    );
    const interestRate = isFetchingDefaultTerms
      ? cobrandUtilMethods.getRoundedInterestRate(response.interest_rate)
      : Math.round((response.interest_rate + Number.EPSILON) * 100) / 100;

    yield put(
      fetchMortgageAndTermsSuccess(activePropertySlug, response.terms, {
        homePrice: data.homePrice,
        downPayment: response.down_payment,
        downPaymentPct: response.down_payment_pct,
        interestRate,
        insurance: response.monthly_pmi,
        mortgageId: response.mortgage_id,
        monthlyPayment: response.monthly_payment,
      })
    );
  } catch (error: any) {
    yield put(fetchMortgageAndTermsError(activePropertySlug, error));
  }
}

function* getMortgageSummary(action: FetchMortgageSummaryAction) {
  try {
    const response = yield call(
      [consumerApiClient, consumerApiClient.getMortgageSummary],
      action.payload
    );
    yield put(
      fetchMortgageSummarySuccess(
        action.payload.activePropertySlug,
        response.summary
      )
    );
  } catch (error: any) {
    yield put(fetchMortgageSummaryError(error));
  }
}

function* navigateToPDPForPropertyListProperty(action) {
  yield put(
    routeChange({
      view: View.PROPERTY_DETAILS,
      params: { slug: action.payload.addressSlug },
    })
  );
}

export function* reportPropertyPageMortgageCalculator(action: {
  payload: {
    eventName:
      | 'click_property_details_update_mortgage_calc_details'
      | 'click_property_details_mortgage_calculator';
    data: MortgageCalculationsForUIComponents | null;
  };
}) {
  const slug = yield select(getPropertyAddressSlug);

  let data: MortgageCalculationsForUIComponents;
  if (!action.payload.data) {
    data = yield select(getMortgageCalculationDetails);
  } else {
    data = action.payload.data;
  }

  yield put(
    reportEvent(action.payload.eventName, '', {
      slug,
      mortgage_id: data.mortgageId,
      interest_rate: data.interestRate,
      down_payment: data.downPayment,
      down_payment_pct: data.downPaymentPct,
      home_price: data.homePrice,
      monthly_payment: data.monthlyPayment,
      insurance: data.insurance,
    })
  );
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_MORTGAGE_SUMMARY]: getMortgageSummary,
    [FETCH_MORTGAGE_AND_TERMS]: getMortgageTermsSaga,
    [FETCH_INIT_PROPERTY_DETAILS]: getInitPropertyDetailsSaga,
    [FETCH_FULL_PROPERTY_DETAILS]: getFullPropertyDetailsSaga,
    [SET_LOCAL_STORAGE_PROPERTY_SEEN]: setPropertyVisitedInStorage,
    [FETCH_PROPERTY_AVM_DEEP_DIVE_DATA]: getAvmDeepDiveDataSaga,
    [SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE]:
      navigateToPDPForPropertyListProperty,
  });
  watchLeading(sagaMiddleware, {
    [FETCH_PROPERTY_AVM_FACTORS]: getAvmFactorsSaga,
  });
  watchLatest(sagaMiddleware, {
    [REPORT_PROPERTY_PAGE_MORTGAGE_CALCULATOR]:
      reportPropertyPageMortgageCalculator,
  });
};
