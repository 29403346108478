import {
  CONTROL_TYPES,
  FilterPropertyTypes,
  FILTER_KEYS,
} from '@client/store/filter-constants';
import { MlsStateGroup } from '@client/store/sagas/queries/types';

function abbreviatedValueSummaryFn(min: number, max: number): string {
  const format = function (number: number): string {
    const converted = Number(number);
    const adjust = { div: 1, append: '' };
    if (converted > 999999) {
      adjust.div = 1000000;
      adjust.append = 'M';
    } else if (converted > 999) {
      adjust.div = 1000;
      adjust.append = 'k';
    }
    const adjusted = converted / adjust.div;
    let fixed = 1;
    if (adjusted % 1 === 0) {
      fixed = 0;
    }
    return '$' + adjusted.toFixed(fixed) + adjust.append;
  };
  if (min && max) {
    return format(min) + ' to ' + format(max);
  } else if (min) {
    return 'At least ' + format(min);
  } else if (max) {
    return 'Up to ' + format(max);
  } else {
    return 'Any';
  }
}

function abbreviatedSummaryFn(min: number, max: number): string {
  if (min && max) {
    return min + ' to ' + max;
  } else if (min) {
    return 'At least ' + min;
  } else if (max) {
    return 'Up to ' + max;
  } else {
    return 'Any';
  }
}

function integerPercentageDisplayFn(number: number): string {
  return number.toString() + '%';
}

function minOnlyIntegerSummaryFn(min: number, max: number): string {
  if (min) {
    return `At least ${+min.toString()}`;
  } else {
    return 'Any';
  }
}

function schoolsSummaryFn(min: number | string, max: number | string): string {
  const format = function (num: number | string): string {
    if (!num) {
      return '';
    }
    return num + 'th';
  };
  const capitalize = function (str: string): string {
    const splt = str.toLowerCase().split(' ');
    if (splt.length > 0) {
      splt[0] = splt[0].charAt(0).toUpperCase() + splt[0].slice(1);
    }
    return splt.join(' ');
  };
  min = format(min);
  max = format(max);
  const suffix = ' percentile';
  if (min && max) {
    if (min === max) {
      const rv = min + suffix;
      return capitalize(rv);
    } else {
      const rv = min + ' to ' + max + suffix;
      return capitalize(rv);
    }
  } else if (min) {
    const rv = min + suffix;
    return capitalize(rv);
  } else if (max) {
    const rv = max + suffix;
    return capitalize(rv);
  } else {
    return 'Any';
  }
}

function generateYearBuiltOptionList(): number[] {
  let yearBuiltOption = [
    1900, 1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 1995,
  ];

  const currYear = new Date().getFullYear();
  for (let year = 2000; year <= currYear; year++) {
    yearBuiltOption.push(year);
  }

  return yearBuiltOption;
}

/* Filter definitions FOR MOBILE. For desktop see FILTER_CONTROLS in `filter.utils.ts` */
export const FILTER_DEFINITIONS = {
  [FILTER_KEYS.MLS_STATE]: {
    control_type: CONTROL_TYPES.CHECKBOX_PICKER,
    control_customization: null,
    description_short: 'Specific MLS status',
    displayable_title: 'Listing Status',
    formatting_type: 'custom',
    ql_type: 'string',
    value_list: ['ACTIVE', 'OFF_MARKET', 'PENDING', 'CONTINGENT'],
    summary_function: function (items: MlsStateGroup[]): string {
      const getDisplay = (value: MlsStateGroup): string => {
        switch (value) {
          case 'ACTIVE':
            return 'For sale';
          case 'OFF_MARKET':
            return 'Off Market';
          case 'PENDING':
            return 'Pending';
          case 'CONTINGENT':
            return 'Under Contract';
          default:
            return 'Unknown';
        }
      };
      const displayArray = items.map(getDisplay);
      const rv = displayArray.join(', ');
      if (rv) {
        return rv;
      } else {
        return 'All';
      }
    },
    summary_input_type: 'STRING_ARRAY',
    display_function: function (value: string): string {
      switch (value) {
        case 'ACTIVE':
          return 'For sale';
        case 'OFF_MARKET':
          return 'Off Market';
        case 'PENDING':
          return 'Pending';
        case 'CONTINGENT':
          return 'Under Contract';
        default:
          return 'Unknown';
      }
    },
  },
  [FILTER_KEYS.BATHS_MIN_MAX]: {
    control_customization: { min_only_buttons_should_add_plus: true },
    control_type: CONTROL_TYPES.MIN_ONLY_BUTTONS,
    description_short: 'Minimum number of bathrooms',
    displayable_title: 'Baths',
    formatting_type: 'none',
    ql_type: 'integer',
    value_list: [1, 2, 3, 4, 5, 6, 7],
    summary_function: minOnlyIntegerSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.BEDS_MIN_MAX]: {
    control_customization: { min_only_buttons_should_add_plus: true },
    control_type: CONTROL_TYPES.MIN_ONLY_BUTTONS,
    description_short: 'Minimum number of bedrooms',
    displayable_title: 'Beds',
    formatting_type: 'none',
    ql_type: 'integer',
    value_list: [1, 2, 3, 4, 5, 6, 7],
    summary_function: minOnlyIntegerSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.UNITS_TOTAL_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short: 'Number of units',
    displayable_title: 'Units',
    formatting_type: 'none',
    ql_type: 'integer',
    value_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    summary_function: abbreviatedSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_SLIDER,
    description_short: 'Properties on blocks with a maximum crime rate',
    displayable_title: 'Crime',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    summary_function: function (
      min: string | number,
      max: string | number
    ): string {
      const format = function (number) {
        if (!number) {
          return '';
        }
        const converted = Number(number);
        if (converted < 0 || converted > 100) {
          return '';
        } else if (converted >= 0 && converted <= 10) {
          return 'Lowest';
        } else if (converted >= 20 && converted <= 30) {
          return 'Low';
        } else if (converted >= 40 && converted <= 60) {
          return 'Medium';
        } else if (converted >= 70 && converted <= 80) {
          return 'High';
        } else if (converted >= 90 && converted <= 100) {
          return 'Highest';
        }
        return '';
      };
      const capitalize = function (str: string): string {
        const splt = str.toLowerCase().split(' ');
        if (splt.length > 0) {
          splt[0] = splt[0].charAt(0).toUpperCase() + splt[0].slice(1);
        }
        return splt.join(' ');
      };
      min = format(min);
      max = format(max);
      const suffix = ' crime';
      if (min && max) {
        if (min === max) {
          const rv = min + suffix;
          return capitalize(rv);
        } else {
          const rv = min + ' to ' + max + suffix;
          return capitalize(rv);
        }
      } else if (min) {
        const rv = min + suffix;
        return capitalize(rv);
      } else if (max) {
        const rv = max + suffix;
        return capitalize(rv);
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (num: number): string {
      const str = num.toString();
      if (!str) {
        return '';
      }
      const converted = Number(str);
      if (converted < 0 || converted > 100) {
        return '';
      } else if (converted >= 0 && converted <= 10) {
        return 'Lowest';
      } else if (converted >= 20 && converted <= 30) {
        return 'Low';
      } else if (converted >= 40 && converted <= 60) {
        return 'Medium';
      } else if (converted >= 70 && converted <= 80) {
        return 'High';
      } else if (converted >= 90 && converted <= 100) {
        return 'Highest';
      }
      return '';
    },
  },
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: {
    control_customization: { custom_no_min_text: 'Any' },
    control_type: CONTROL_TYPES.MIN_ONLY_PICKER,
    description_short:
      'Properties that were listed during a certain date range',
    displayable_title: 'Days on Market',
    formatting_type: 'custom',
    ql_type: 'string',
    value_list: [1, 3, 7, 14, 30, 90],
    summary_function: function (
      min: number | null,
      max: number | null
    ): string {
      function format(value: number) {
        if (value === 1) {
          return '1 Day';
        } else {
          return value.toString() + ' Days';
        }
      }
      if (min !== null && max !== null) {
        return 'Between ' + min.toString() + ' and ' + format(max);
      } else if (min !== null) {
        return 'Less than ' + format(min);
      } else if (max !== null) {
        return 'Over ' + format(max);
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (value: number): string {
      if (value === 1) {
        return '< 1 Day';
      } else {
        return '< ' + value.toString() + ' Days';
      }
    },
  },
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short: "Homes within ComeHome's value range",
    displayable_title: 'ComeHome Value',
    formatting_type: 'dollar',
    ql_type: 'integer',
    value_list: [
      50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000, 400000,
      450000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000,
      2500000, 3000000, 3500000, 4000000, 4500000, 5000000, 6000000, 7000000,
      8000000, 9000000, 10000000,
    ],
    summary_function: abbreviatedValueSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short: 'Active listings within an asking price range',
    displayable_title: 'Price',
    formatting_type: 'dollar',
    ql_type: 'integer',
    value_list: [
      50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000, 400000,
      450000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000,
      2500000, 3000000, 3500000, 4000000, 4500000, 5000000, 6000000, 7000000,
      8000000, 9000000, 10000000,
    ],
    summary_function: abbreviatedValueSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short: 'Specific lot size range',
    displayable_title: 'Lot Size',
    formatting_type: 'custom',
    ql_type: 'integer',
    value_list: [
      2000, 3000, 4000, 5000, 6000, 7000, 8000, 10890, 21780, 43560, 87120,
      130680, 174240, 217800, 435600, 871200, 1306800,
    ],
    summary_function: function (min: number, max: number): string {
      const convert = function (num: number): string {
        const acreInSqFt = 43560;
        if (num < acreInSqFt / 4.0) {
          return num.toString() + ' sq. ft.';
        } else {
          const converted = num / acreInSqFt;
          let display = '';
          if (converted < 1) {
            const double = num / acreInSqFt;
            display = double.toFixed(2);
          } else {
            display = converted.toFixed(2).toString();
          }
          return (
            display.toString() + ' ' + (converted === 1 ? 'acre' : 'acres')
          );
        }
      };
      if (min && max) {
        return convert(min) + ' to ' + convert(max);
      } else if (min) {
        return 'At least ' + convert(min);
      } else if (max) {
        return 'Up to ' + convert(max);
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (num: number): string {
      const acreInSqFt = 43560;
      if (num < acreInSqFt / 4.0) {
        return num.toString() + ' sq. ft.';
      } else {
        const converted = num / acreInSqFt;
        let display = '';
        if (converted < 1) {
          const double = num / acreInSqFt;
          display = double.toFixed(2);
        } else {
          display = converted.toFixed(2).toString();
        }
        return display.toString() + ' ' + (converted === 1 ? 'acre' : 'acres');
      }
    },
  },
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: {
    control_customization: { should_reverse_slider_colors: true },
    control_type: CONTROL_TYPES.MIN_MAX_SLIDER,
    description_short: 'In market/submarket with selected strength/volatility',
    displayable_title: 'Market Grade',
    formatting_type: 'custom',
    ql_type: 'string',
    value_list: ['F', 'D', 'C', 'B', 'A'],
    summary_function: function (min: string, max: string): string {
      const getDisplay = function (keyString: string): string {
        switch (keyString) {
          case 'A':
            return 'Stable';
          case 'B':
            return 'Less Stable';
          case 'C':
            return 'Average';
          case 'D':
            return 'Less Volatile';
          case 'F':
            return 'Volatile';
          default:
            return keyString;
        }
      };
      const displayMin = getDisplay(min);
      const displayMax = getDisplay(max);
      if (displayMin && displayMax) {
        return displayMin + ' to ' + displayMax;
      } else if (displayMin) {
        return 'At least ' + displayMin;
      } else if (displayMax) {
        return 'Up to ' + displayMax;
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (keyString: string): string {
      switch (keyString) {
        case 'A':
          return 'Stable';
        case 'B':
          return 'Less Stable';
        case 'C':
          return 'Average';
        case 'D':
          return 'Less Volatile';
        case 'F':
          return 'Volatile';
        default:
          return keyString;
      }
    },
  },
  [FILTER_KEYS.HPI_MIN_MAX]: {
    control_customization: { should_reverse_slider_colors: true },
    control_type: CONTROL_TYPES.MIN_MAX_SLIDER,
    description_short: 'Block expected to change in value in 12 months',
    displayable_title: 'One-Year Forecast',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [
      -1.0, -0.1, -0.09, -0.08, -0.07, -0.06, -0.05, -0.04, -0.03, -0.02, -0.01,
      0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12,
      0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 1.0,
    ],
    summary_function: function (
      min: string | number,
      max: string | number
    ): string {
      const format = function (num: string | number): string {
        const converted = Number(num);
        if (converted && converted < 1 && converted > -1) {
          const adjusted = converted * 100;
          return adjusted.toFixed(0).toString() + '%';
        } else {
          return '';
        }
      };
      min = format(min);
      max = format(max);
      if (min && max) {
        return min + ' to ' + max;
      } else if (min) {
        return 'At least ' + min;
      } else if (max) {
        return 'Up to ' + max;
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (num: number): string {
      num = num * 100;
      if (num < -99) {
        return '<-10%';
      } else if (num > 99) {
        return '>20%';
      } else {
        return num.toFixed(0).toString() + '%';
      }
    },
  },
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short:
      'On blocks within specified median price per square foot',
    displayable_title: 'Price per Square Foot',
    formatting_type: 'dollar',
    ql_type: 'float',
    value_list: [
      20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 350, 400, 500,
      600, 700, 800, 900, 1000,
    ],
    summary_function: function (min: number, max: number): string {
      if (min && max) {
        return '$' + min + ' to ' + '$' + max;
      } else if (min) {
        return 'At least ' + '$' + min;
      } else if (max) {
        return 'Up to ' + '$' + max;
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.PROPERTY_TYPE]: {
    control_customization: null,
    control_type: CONTROL_TYPES.CHECKBOX_PICKER,
    description_short: 'Property category, such as a house, condo or townhouse',
    displayable_title: 'Property Type',
    formatting_type: 'custom',
    ql_type: 'string',
    value_list: ['SFR', 'TOWNHOUSE', 'CONDO', 'COOP', 'MULTI'],
    summary_function: function (items: FilterPropertyTypes[]): string {
      const getDisplay = function (keyString: string): string {
        switch (keyString) {
          case 'MULTI':
            return 'Multi-family';
          case 'SFR':
            return 'House';
          case 'TOWNHOUSE':
            return 'Townhouse';
          case 'CONDO':
            return 'Condo';
          case 'COOP':
            return 'Co-op';
          case 'MANUFACTURED':
            return 'Manufactured';
          default:
            return keyString;
        }
      };
      const displayArray = items.map(getDisplay);
      const rv = displayArray.join(', ');
      if (rv) {
        return rv;
      } else {
        return 'All';
      }
    },
    summary_input_type: 'STRING_ARRAY',
    display_function: function (keyString: string): string {
      switch (keyString) {
        case 'MULTI':
          return 'Multi-family';
        case 'SFR':
          return 'House';
        case 'TOWNHOUSE':
          return 'Townhouse';
        case 'CONDO':
          return 'Condo';
        case 'COOP':
          return 'Co-op';
        case 'MANUFACTURED':
          return 'Manufactured';
        default:
          return keyString;
      }
    },
  },
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: {
    control_customization: { should_expand_upwards: true },
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short: 'Homes within our rental estimate range',
    displayable_title: 'Rental Estimate',
    formatting_type: 'dollar',
    ql_type: 'integer',
    value_list: [
      250, 300, 350, 400, 450, 500, 1000, 1250, 1500, 1750, 2000, 2500, 3000,
      3500, 4000, 4500, 5000, 5000, 6000, 7000, 8000, 9000, 10000, 15000, 20000,
    ],
    summary_function: abbreviatedValueSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: {
    control_customization: { should_expand_upwards: true },
    control_type: CONTROL_TYPES.MIN_ONLY_PICKER,
    description_short:
      'Rental Yield describes the amount of income that could be made annually from a property. It is calculated as the monthly Rental Estimate, multiplied by twelve, and divided by the estimated value.',
    displayable_title: 'Rental Yield',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [
      0.03, 0.035, 0.04, 0.045, 0.05, 0.055, 0.06, 0.065, 0.07, 0.075, 0.08,
      0.085, 0.09, 0.095, 0.1, 0.105, 0.11, 0.115, 0.12, 0.125, 0.13, 0.135,
      0.14, 0.145, 0.15, 0.155, 0.16, 0.165, 0.17, 0.175, 0.18, 0.185, 0.19,
      0.195, 0.2, 0.205,
    ],
    summary_function: function (min: number, max: number): string {
      if (min) {
        min *= 100;
        const abbr = min.toFixed(1);
        return 'At least ' + abbr.toString() + '%';
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (num: number) {
      const adjusted = num * 100;
      const abbr = adjusted.toFixed(1);
      return abbr.toString() + '%';
    },
  },
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]: {
    control_customization: { should_reverse_slider_colors: true },
    control_type: CONTROL_TYPES.MIN_MAX_SLIDER,
    description_short: 'In an area with a specified risk of decline',
    displayable_title: 'Risk of Decline',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    summary_function: function (
      min: string | number,
      max: string | number
    ): string {
      const format = function (num: string | number): string {
        const numToFormat = +num * 100;
        return numToFormat.toString() + '%';
      };
      if (min) {
        min = format(min);
      }
      if (max) {
        max = format(max);
      }
      if (min && max) {
        return min + ' to ' + max;
      } else if (min) {
        return 'At least ' + min;
      } else if (max) {
        return 'Up to ' + max;
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (num: number): string {
      num *= 100;
      return num.toString() + '%';
    },
  },
  [FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX]: {
    control_customization: { should_reverse_slider_colors: true },
    control_type: CONTROL_TYPES.MIN_MAX_SLIDER,
    description_short:
      'Access to elementary schools with minimum state ranking',
    displayable_title: 'Schools - Elementary',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    summary_function: schoolsSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: integerPercentageDisplayFn,
  },
  [FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX]: {
    control_customization: { should_reverse_slider_colors: true },
    control_type: CONTROL_TYPES.MIN_MAX_SLIDER,
    description_short:
      'Access to middle schools with a minimum ranking within the state',
    displayable_title: 'Schools - Middle',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    summary_function: schoolsSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: integerPercentageDisplayFn,
  },
  [FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX]: {
    control_customization: { should_reverse_slider_colors: true },
    control_type: CONTROL_TYPES.MIN_MAX_SLIDER,
    description_short: 'Access to high schools with minimum state ranking',
    displayable_title: 'Schools - High',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    summary_function: schoolsSummaryFn,
    summary_input_type: 'TWO_STRING',
    display_function: integerPercentageDisplayFn,
  },
  [FILTER_KEYS.SQFT_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short: 'Specific size range',
    displayable_title: 'Square Feet',
    formatting_type: 'none',
    ql_type: 'integer',
    value_list: [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000],
    summary_function: function (min: number, max: number): string {
      const format = function (num: number): string {
        if (num) {
          return num + ' sq. ft.';
        } else {
          return '';
        }
      };
      if (min && max) {
        return format(min) + ' to ' + format(max);
      } else if (min) {
        return 'At least ' + format(min);
      } else if (max) {
        return 'Up to ' + format(max);
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: {
    control_customization: { custom_no_max_text: 'Any' },
    control_type: CONTROL_TYPES.MAX_ONLY_PICKER,
    description_short: 'Underpriced relative to the ComeHome Value',
    displayable_title: 'Underpriced Homes',
    formatting_type: 'custom',
    ql_type: 'float',
    value_list: [0.99, 0.98, 0.97, 0.96, 0.95, 0.9, 0.85, 0.8],
    summary_function: function (min, max: number): string {
      if (max) {
        max *= 100;
        max = 100 - max;
        const abbr = max.toFixed(1);
        return 'At least ' + abbr.toString() + '%';
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: function (num: number): string {
      num *= 100;
      num = 100 - num;
      const abbr = num.toFixed(1);
      return abbr.toString() + '%';
    },
  },
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: {
    control_customization: null,
    control_type: CONTROL_TYPES.MIN_MAX_PICKER,
    description_short: 'Specific time frame',
    displayable_title: 'Year Built',
    formatting_type: 'none',
    ql_type: 'integer',
    value_list: generateYearBuiltOptionList(),
    summary_function: function (min: number, max: number): string {
      if (min && max) {
        return min + ' to ' + max;
      } else if (min) {
        return 'After ' + min;
      } else if (max) {
        return 'Before ' + max;
      } else {
        return 'Any';
      }
    },
    summary_input_type: 'TWO_STRING',
    display_function: (v) => v,
  },
};

export type FilterDefinition =
  (typeof FILTER_DEFINITIONS)[keyof typeof FILTER_DEFINITIONS];
