import MobileFilterSelect from '@client/components/MobileFilterSelect';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getInactiveFilterKeys,
  getSearchFilters,
} from '@client/store/selectors/search.selectors';
import { selectIsSRPGrantFilterOn } from '@client/store/slices/grant-program.slice';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const isGrantToggleOn = selectIsSRPGrantFilterOn(state);
  return {
    inactiveFilterKeys: getInactiveFilterKeys(state),
    filterValuesByKey: getSearchFilters(state),
    shouldShowUnitsTotalMinMax:
      getIsFeatureEnabled('temp_display_multi_family_search_filters')(state) &&
      getSearchFilters(state).propertyType.includes('MULTI'),
    isSchoolDataEnabled: getIsFeatureEnabled('school_data')(state),
    isHidingAdvancedFilters: getIsFeatureEnabled(
      'hide_advanced_search_filters'
    )(state),
    isShowingGrantToggle:
      getIsFeatureEnabled('grant_program')(state) && !isGrantToggleOn,
    isGrantToggleEnabled: isGrantToggleOn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reportFilterClick: (key) => {
    dispatch(reportEvent('click_mobile_filter_item', '', { filter_key: key }));
  },
  routeToMlsIdSearch: () => dispatch(routeChange({ view: View.MLS_NUMBER })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilterSelect);
