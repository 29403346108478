import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { themr } from '@friendsofreactjs/react-css-themr';

import MLSAttribution from '@client/components/MLSAttribution';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import PillButton from '@client/components/generic/PillButton';
import ShareButtonWithModal from '@client/components/ShareButtonWithModal';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { dollarsWithPlaceholder } from '@client/utils/string.utils';
import {
  getPropertyValueAndValueLabel,
  getMLSIdFromPropertyDetails,
} from '@client/utils/property.utils';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { PDPRightRailCardProps } from '@client/store/types/cobranded-components/pdp-right-rail-card';
import TopAgentsRankedLogo from '@client/inline-svgs/cobrand/topagentsranked/top-agents-ranked-logo';
import {
  getPropertyIsInWatchList,
  getPropertyDetailsNormalized,
  getMonthlyPaymentEstimateForProperty,
} from '@client/store/selectors/property-details.selectors';
import {
  getIsLoggedIn,
  getUserPartnerUserId,
  getChapiPartnerInviteId,
} from '@client/store/selectors/auth.selectors';
import {
  reportPropertyDetailsWatchClick,
  reportPropertyDetailsUnwatchClick,
  reportPropertyDetailsUnwatchConfirmClick,
} from '@client/store/actions/analytics.actions';
import { NWMLS_ID } from '@client/store/constants';
import {
  getTARCTALink,
  useReportTAREvent,
} from '@client/components/cobrand/topagentsranked/top-agents-ranked-utils';
import defaultTheme from '@client/css-modules/PDPRightRailCardTopAgentsRanked.css';
import { useAutoSaveProperty } from '@client/hooks/auto-save-property.hooks';
import { TopLocalAgentAdDetailsInterface } from '@hc/consumer-customization-data/lib/types';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';

const PDPRightRailCardTopAgentsRanked = ({
  theme,
  isShowingShareAndSaveSection,
  isStandaloneModule,
  onClick,
}: PDPRightRailCardProps) => {
  const dispatch = useDispatch();
  const reportTAREvent = useReportTAREvent();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const autoSaveProperty = useAutoSaveProperty();
  const partnerUserId = useSelector(getUserPartnerUserId);
  const chapiPartnerInviteId = useSelector(getChapiPartnerInviteId);
  const { top_local_agent_ad_details } = useSelector(getCustomizationData);
  const isAddedToWatchList: boolean = useSelector(getPropertyIsInWatchList);
  const monthlyPaymentEstimateForProperty = useSelector(
    getMonthlyPaymentEstimateForProperty
  );
  const propertyDetails = useSelector(getPropertyDetailsNormalized);
  const { fullAddress, slug } = propertyDetails || {};

  if (!propertyDetails || !slug || !fullAddress) {
    return null;
  }

  const watchlistAddress = {
    street: propertyDetails.streetAddress,
    city: propertyDetails.city,
    state: propertyDetails.state,
    zip: propertyDetails.zipcode,
    unit: propertyDetails.unit,
    address_id: propertyDetails.hcAddressId,
    slug: propertyDetails.slug,
  };

  const handlePropertyDetailsWatchClick = (slug) => {
    dispatch(reportPropertyDetailsWatchClick(slug));
  };

  const handlePropertyDetailsUnwatchClick = (slug) => {
    dispatch(reportPropertyDetailsUnwatchClick(slug));
  };

  const handlePropertyDetailsUnwatchConfirmClick = (slug) => {
    dispatch(reportPropertyDetailsUnwatchConfirmClick(slug));
  };

  const handleTalkToAnAgentClick = (
    tarAdDetails: TopLocalAgentAdDetailsInterface | null
  ) => {
    const ctaLink = getTARCTALink(
      tarAdDetails,
      isLoggedIn,
      partnerUserId,
      chapiPartnerInviteId
    );
    autoSaveProperty();
    reportTAREvent();
    window.open(ctaLink, '_blank');
  };

  const valueAndValueLabel = !propertyDetails
    ? { value: 0, valueLabel: '' }
    : getPropertyValueAndValueLabel(propertyDetails);
  const { value, valueLabel } = valueAndValueLabel;
  const { total } = monthlyPaymentEstimateForProperty || {};
  return (
    <div
      className={classNames(theme.PDPRightRailCard, {
        [theme.PDPRightRailCardWithBoxShadow]: isStandaloneModule,
        [theme.PDPRightRailCardInCollapsedPDP]: !isShowingShareAndSaveSection,
      })}
      onClick={onClick}
      data-hc-name={'summary-options-panel'}
    >
      <div className={theme.ContentWrapper}>
        <div className={theme.ListingStatusSection}>
          <div className={theme.ListingStatus} data-hc-name={'listing-status'}>
            {valueLabel} {value ? dollarsFormatter(value) : '-'}
          </div>
          <HorizontalSeparator theme={theme} />
          {
            <div
              className={theme.MonthlyPaymentsSection}
              data-hc-name={'monthly-payment'}
            >
              <div className={theme.Label}>
                Estimated monthly payment {dollarsWithPlaceholder(total)}
              </div>
            </div>
          }
        </div>
        <PillButton
          ariaLabel="Talk to an agent"
          dataHcName="talk-to-an-agent-button"
          className={theme.PillButton}
          onClick={() => {
            handleTalkToAnAgentClick(top_local_agent_ad_details);
          }}
          onKeyDown={onEnterOrSpaceKey(() => {
            handleTalkToAnAgentClick(top_local_agent_ad_details);
          })}
        >
          {top_local_agent_ad_details?.pdp_button_title ?? 'Email an agent'}
        </PillButton>
        <div className={theme.LogoAndLabel}>
          <span className={theme.LogoLabel}>Powered by</span>
          <div
            className={theme.LogoWrapper}
            data-hc-name="top-agents-ranked-logo"
          >
            <TopAgentsRankedLogo
              ariaProps={{
                title: "Top Agents Ranked",
                role: "img",
                ariaLabelledBy: "top-agents-ranked",
              }}
            />
          </div>
          <div
            aria-label="Top Agents Ranked"
            className={theme.LogoLabel}
            data-hc-name="title"
          >
            TopAgentsRanked
          </div>
        </div>
        {getMLSIdFromPropertyDetails(propertyDetails) !== NWMLS_ID && (
          <MLSAttribution
            className={theme.MLSAttribution}
            propertyDetails={propertyDetails}
          />
        )}
      </div>
      <div className={theme.ShareAndSavePropertySection}>
        <AccessibleElementUniqueId>
          {({ uid }) => (
            <div
              className={theme.ShareButtonContainer}
              data-hc-name={'share-button'}
            >
              <ShareButtonWithModal id={uid} theme={theme} slug={slug} />
              <label htmlFor={uid} className={theme.ButtonLabel}>
                Share
              </label>
            </div>
          )}
        </AccessibleElementUniqueId>
        <WatchListActionButtonContainer
          address={watchlistAddress}
          dataHcName={'save-button'}
          fullAddress={fullAddress}
          isAddedToWatchList={isAddedToWatchList}
          addressSlug={slug}
          theme={theme}
          isShowingButtonLabel
          handleReportWatchClick={handlePropertyDetailsWatchClick}
          handleReportUnwatchClick={handlePropertyDetailsUnwatchClick}
          handleReportUnwatchConfirmClick={
            handlePropertyDetailsUnwatchConfirmClick
          }
          shouldHandleCheckingForWatchListStatus={true}
        />
      </div>
    </div>
  );
};

const ThemedPDPRightRailCardTopAgentsRanked = themr(
  'PDPRightRailCardTopAgentsRanked',
  defaultTheme
)(PDPRightRailCardTopAgentsRanked);
export default ThemedPDPRightRailCardTopAgentsRanked;
