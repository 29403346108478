import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';

import MLSAttribution from '@client/components/MLSAttribution';
import PropertyCardValueSection from '@client/components/PropertyCardValueSection';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import PropertyPhotoContainer from '@client/containers/property-photo.container';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import defaultTheme from '@client/css-modules/PropertyCard.css';
import { STATUSES } from '@client/store/constants';

import ConditionalFeature from '@client/components/ConditionalFeature';
import PropertyCardGrantProgramLabel from '@client/components/GrantProgram/PropertyCardGrantProgramLabel';
import PulseLoader from '@client/components/PulseLoader';
import CloseIcon from '@client/inline-svgs/close';
import HeartIconInactive from '@client/inline-svgs/heart-inactive';
import {
  NormalizedGrantProperty,
  NormalizedProperty,
} from '@client/store/types/property';
import { PropertyPhotosSizes } from '@client/store/types/property-photos';

export type PropertyCardProps = {
  /* Used to get property details for a single card on init */
  addressId?: number;
  /* Needed when using this component as <Slider> child */
  uId?: string;
  propertyDetails?: NormalizedProperty | NormalizedGrantProperty;
  /* The loading status of the property card. Omitting this prop is the same as passing
   * status=SUCCESS */
  status?: string;
  isAddedToWatchList: boolean;
  isShowingWatchListActionButton: boolean;
  isShowingDaysOnMarket?: boolean;
  isShowingSimilarity?: boolean;
  isShowingLegacyLenderCTA?: boolean;
  /* Whether the WatchListActionButton should handle making a request to check for added status
   * On page featuring many PropertyCard components, we want to instead get all added properties at once */
  shouldHandleCheckingForWatchListStatus?: boolean;
  photoSize: PropertyPhotosSizes;
  theme: Theme;
  rel?: string;
  className?: string;
  /* Photos that were fetched as part of the property data for this card. Since we already have them,
   * there's no need for the `PropertyPhotoContainer` to fetch them again */
  overridePhotos?: string[];
  goToProperty: (slug: string) => void;
  handlePropertyCardClick?: (slug: string) => void;
  /* This is only necessary if we don't have the prop details to begin with */
  handleFetchPropertyDetails?: (addressId: number) => void;
  handleReportWatchClick?: (slug: string) => void;
  handleReportUnwatchClick: (slug: string) => void;
  handleReportUnwatchConfirmClick: (slug: string) => void;
  onMouseEnter?: (e: React.MouseEvent, addressSlug: string) => void;
  onMouseLeave?: (e: React.MouseEvent, addressSlug: string) => void;
  ariaLabel?: string;
  style?: React.CSSProperties;
  isForcingMLSSpacing?: boolean;
  hasMobileMapCloseButton?: boolean;
  handlePropertyCardClose?: (e: React.MouseEvent) => void;
  isFocused?: boolean;
  inactiveIcon?: string | null;
};

type State = {
  /* Defining here as a performance optimization.  Passing it to <PropertyCardValueSection> as a new
   * object each time causes that component to unnecessarily re-render */
  WatchListButton: JSX.Element | null;
};

class PropertyCard extends PureComponent<PropertyCardProps, State> {
  constructor(props: PropertyCardProps) {
    super(props);
    this.state = {
      WatchListButton: this.getWatchListButton(!!props.isAddedToWatchList),
    };
  }

  componentDidUpdate(prevProps: PropertyCardProps) {
    if (
      prevProps.isAddedToWatchList !== this.props.isAddedToWatchList ||
      (!prevProps.propertyDetails && this.props.propertyDetails)
    ) {
      this.setState({
        WatchListButton: this.getWatchListButton(
          !!this.props.isAddedToWatchList
        ),
      });
    }

    if (prevProps.isFocused !== this.props.isFocused) {
      this.setState({
        WatchListButton: this.getWatchListButton(
          !!this.props.isAddedToWatchList
        ),
      });
    }
  }

  componentDidMount() {
    // set focus to mobile close button if it exists
    const mobileCloseButton = document.getElementById('mobile-close-button');
    if (mobileCloseButton) {
      mobileCloseButton.focus();
    }
    const { addressId, propertyDetails, handleFetchPropertyDetails } =
      this.props;

    if (isEmpty(propertyDetails) && handleFetchPropertyDetails && addressId) {
      handleFetchPropertyDetails(addressId);
    }
  }

  /* To optimize rendering performance for lists that contain many PropertyCard components, we need
   * to pass the watchlist button component down by reference instead of defining it in this component's
   * render() method */
  getWatchListButton = (isAddedToWatchList: boolean): JSX.Element | null => {
    const {
      propertyDetails,
      theme,
      handleReportWatchClick,
      handleReportUnwatchClick,
      handleReportUnwatchConfirmClick,
      shouldHandleCheckingForWatchListStatus,
      isFocused,
    } = this.props;

    if (propertyDetails) {
      const watchlistAddress = {
        street: propertyDetails.streetAddress,
        city: propertyDetails.city,
        state: propertyDetails.state,
        zip: propertyDetails.zipcode,
        unit: propertyDetails.unit,
        address_id: propertyDetails.hcAddressId,
        slug: propertyDetails.slug,
      };

      return (
        <WatchListActionButtonContainer
          address={watchlistAddress}
          fullAddress={propertyDetails.fullStreetAddress}
          isAddedToWatchList={isAddedToWatchList}
          shouldHandleCheckingForWatchListStatus={
            !!shouldHandleCheckingForWatchListStatus
          }
          addressSlug={propertyDetails && propertyDetails.slug}
          handleReportWatchClick={handleReportWatchClick}
          handleReportUnwatchClick={handleReportUnwatchClick}
          handleReportUnwatchConfirmClick={handleReportUnwatchConfirmClick}
          theme={theme}
          isFocused={isFocused}
        />
      );
    }

    return null;
  };

  handleMouseEnter = (e: React.MouseEvent): void => {
    const { onMouseEnter, propertyDetails } = this.props;

    if (onMouseEnter && propertyDetails) {
      onMouseEnter(e, propertyDetails.slug);
    }
  };

  handleMouseLeave = (e: React.MouseEvent): void => {
    const { onMouseLeave, propertyDetails } = this.props;

    if (onMouseLeave && propertyDetails) {
      onMouseLeave(e, propertyDetails.slug);
    }
  };

  handlePropertyCardDetailClick = (): void => {
    const { propertyDetails, goToProperty, handlePropertyCardClick } =
      this.props;

    if (propertyDetails) {
      handlePropertyCardClick && handlePropertyCardClick(propertyDetails.slug);
      goToProperty(propertyDetails.slug);
    }
  };

  render() {
    const {
      theme,
      className,
      propertyDetails,
      overridePhotos,
      rel,
      status,
      isShowingWatchListActionButton,
      isShowingDaysOnMarket,
      isShowingSimilarity,
      isShowingLegacyLenderCTA,
      photoSize,
      ariaLabel,
      style,
      isForcingMLSSpacing,
      hasMobileMapCloseButton,
      handlePropertyCardClose,
      inactiveIcon
    } = this.props;
    const { WatchListButton } = this.state;
    const isLoading =
      status === STATUSES.LOADING || propertyDetails === undefined;

    return (
      <div
        className={classNames(theme.PropertyCard, {
          [className || '']: className,
        })}
        style={style}
      >
        <div
          className={theme.PropertyCardInner}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handlePropertyCardDetailClick}
        >
          {propertyDetails?.tractId && (
            <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
              <PropertyCardGrantProgramLabel
                propertyDetails={propertyDetails as NormalizedGrantProperty}
                hasMobileMapCloseButton={hasMobileMapCloseButton}
              />
            </ConditionalFeature>
          )}

          {hasMobileMapCloseButton && (
            <button
              aria-label="Close home detail overlay and return to map"
              className={theme.MobileMapCloseButton}
              type="button"
              onClick={handlePropertyCardClose}
              id="mobile-close-button"
            >
              <CloseIcon className={theme.MobileMapCloseIcon} />
            </button>
          )}
          <PropertyPhotoContainer
            className={theme.PropertyCardPhoto}
            addressSlug={propertyDetails?.slug}
            fullAddress={propertyDetails?.fullAddress}
            photoSize={photoSize}
            mlsLogoUrl={propertyDetails?.mlsLogoOverlay}
            overridePhotos={overridePhotos}
            streetViewLocation={
              propertyDetails?.latitude && propertyDetails?.longitude
                ? {
                    latitude: propertyDetails.latitude,
                    longitude: propertyDetails.longitude,
                  }
                : null
            }
            shouldUseThumbnailCarousel
            theme={theme}
          />
          {isShowingWatchListActionButton && WatchListButton ? (
            <div className={theme.WatchListButtonPositioner}>
              {WatchListButton}
            </div>
          ) : null}
          {isLoading && (
            <div className={theme.InActiveHeartIconOnLoad}>
              {inactiveIcon ? <img alt="" src={inactiveIcon} />:<HeartIconInactive />}
            </div>
          )}
          <PulseLoader
            isLoading={isLoading}
            style={{ width: 230, height: 50, margin: 16 }}
          >
            <PropertyIntroContainer
              theme={theme}
              ariaLabel={ariaLabel}
              rel={rel}
              propertyDetails={propertyDetails}
              isShowingDaysOnMarket={isShowingDaysOnMarket}
              isShowingSimilarity={isShowingSimilarity}
              isShowingCondensedPropertyInfo
              isCondensedAddressLink
              onStreetAddressLinkKeyDown={this.handlePropertyCardDetailClick}
            />
          </PulseLoader>
          <PulseLoader
            isLoading={isLoading}
            style={{ width: 300, height: 50, margin: '0 auto' }}
          >
            {propertyDetails ? (
              <PropertyCardValueSection
                propertyDetails={propertyDetails}
                isShowingClickHintCTA
                isShowingLegacyLenderCTA={isShowingLegacyLenderCTA}
                theme={theme}
                forceLowerSpacing={isForcingMLSSpacing}
                bottomLabel={
                  <MLSAttribution propertyDetails={propertyDetails} />
                }
              />
            ) : (
              <></>
            )}
          </PulseLoader>
        </div>
      </div>
    );
  }
}

const ThemedPropertyCard = themr('PropertyCard', defaultTheme)(PropertyCard);
export default ThemedPropertyCard;
