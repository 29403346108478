import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import BreakoutSections from '@client/components/BreakoutSections/BreakoutSections';
import { resetLocalActivitiesFilter } from '@client/store/actions/local-activities.actions';
import { closeModal, openModal } from '@client/store/actions/modals.actions';
import {
  fetchMortgageSummary,
  reportPropertyPageMortgageCalculator,
} from '@client/store/actions/property-details.actions';
import { ModalKey, STATUSES } from '@client/store/constants';
import {
  getShouldShowLocalActivitiesBreakoutSection,
  selectBreakoutSectionOrder,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getLocalActivitiesResultCount,
  getLocalActivitiesStatus,
} from '@client/store/selectors/local-activities.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import {
  getAvmPriceMean,
  getBestSchool,
  getBlockForecastSummary,
  getBlockHistograms,
  getComps,
  getCompsValueRange,
  getCounty,
  getCrimeRate,
  getHomePriceForMortgageCalculator,
  getIsCrimeDataMissing,
  getMortgageCalculationDetails,
  getMortgageSummary,
  getPropertyAddressSlug,
  getRentalAvm,
  getSalesHistory,
  getSchoolsSummary,
  getZip1YearForecastSummary,
  getZip3YearForecastSummary,
} from '@client/store/selectors/property-details.selectors';
import { MortgageSummaryCallParams } from '@client/store/types/property';

const mapStateToProps = (state) => {
  const activityCount = getLocalActivitiesResultCount(state);
  const histogramData = getBlockHistograms(state);
  const buildingArea = histogramData?.buildingArea || null;
  const valuePerSqft = histogramData?.valuePerSqft || null;

  let insightTextData: {
    buildingArea: {
      high: number | null;
      low: number | null;
    };
    valuePerSqFt: {
      high: number | null;
      low: number | null;
    };
  } = {
    buildingArea: {
      high: null,
      low: null,
    },
    valuePerSqFt: {
      high: null,
      low: null,
    },
  };

  if (buildingArea && buildingArea.length) {
    insightTextData.buildingArea.low = buildingArea[0].start;
    insightTextData.buildingArea.high =
      buildingArea[buildingArea.length - 1].end;
  }

  if (valuePerSqft && valuePerSqft.length) {
    insightTextData.valuePerSqFt.low = valuePerSqft[0].start;
    insightTextData.valuePerSqFt.high =
      valuePerSqft[valuePerSqft.length - 1].end;
  }

  const hasHistoBuildingArea = !!(
    insightTextData.buildingArea.high && insightTextData.buildingArea.low
  );
  const hasHistoValuePerSqFt = !!(
    insightTextData.valuePerSqFt.high && insightTextData.valuePerSqFt.low
  );

  return {
    activePropertySlug: getPropertyAddressSlug(state),
    activeModalKey: getActiveModalKey(state),
    avmPriceMean: getAvmPriceMean(state),
    isCrimeDataMissing: getIsCrimeDataMissing(state),
    crimeRate: getCrimeRate(state),
    crimeCounty: getCounty(state),
    bestSchool: getBestSchool(state),
    rentalAVM: getRentalAvm(state),
    forecastBlockSummary: getBlockForecastSummary(state),
    forecastZip1YearSummary: getZip1YearForecastSummary(state),
    forecastZip3YearSummary: getZip3YearForecastSummary(state),
    saleHistory: getSalesHistory(state),
    comps: getComps(state),
    compsValueRange: getCompsValueRange(state),
    shouldScrollModalsIntoView: !getIsSmallSize(state),
    localActivitiesHasData: activityCount > 0,
    shouldDisplayLocalActivitiesForCobrand:
      getShouldShowLocalActivitiesBreakoutSection(state),
    insightTextData,
    shouldDisplayNeighborhoodInsights:
      hasHistoBuildingArea || hasHistoValuePerSqFt,
    hasHistoValuePerSqFt,
    hasHistoBuildingArea,
    schoolsSummary: getSchoolsSummary(state),
    shouldDisplayMortgageCalculator: !!getHomePriceForMortgageCalculator(state),
    mortgageSummary: getMortgageSummary(state),
    localActivitiesHaveLoaded:
      getLocalActivitiesStatus(state) === STATUSES.SUCCESS,
    useSlideInModals: getIsSmallSize(state),
    isShowingMobileCloseButton: getIsSmallSize(state),
    isSchoolDataEnabled: getIsFeatureEnabled('school_data')(state),
    mortgageCalculationDetails: getMortgageCalculationDetails(state),
    isShowingRentalEstimate: !getIsFeatureEnabled('hide_rental_estimate_pdp')(
      state
    ),
    isHidingBlockLevelForecast: getIsFeatureEnabled('hide_forecast_block_pdp')(
      state
    ),
    customBreakoutSectionOrder: selectBreakoutSectionOrder(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMortgageSummary: ({
    activePropertySlug,
    userChosenHomePrice,
    userChosenInterestRate,
    userChosenLoanTerm,
    userChosenDownpaymentPct,
  }: MortgageSummaryCallParams): void => {
    dispatch(
      fetchMortgageSummary({
        activePropertySlug,
        userChosenHomePrice,
        userChosenInterestRate,
        userChosenLoanTerm,
        userChosenDownpaymentPct,
      })
    );
  },
  resetActivitiesFilter: (): void => {
    dispatch(resetLocalActivitiesFilter());
  },
  handleOpenModal: (modalKey: ModalKey): void => {
    dispatch(openModal(modalKey));
  },
  handleCloseModal: (): void => {
    dispatch(closeModal());
  },
  reportMortgageCalculatorClick: (): void => {
    dispatch(
      reportPropertyPageMortgageCalculator(
        'click_property_details_mortgage_calculator',
        'click_pdp_modules'
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BreakoutSections);
