import { SCHOOL_TYPES } from '@client/store/constants';
import {
  Address,
  Comp,
  CompSummary,
  MlsState,
  Property,
  PropertyDetailsForWatchlistItemQuery,
  PropertyDetailsFullQuery,
  PropertyDetailsInitQuery,
  PropertySchool,
  PropertySummary,
  PropertyType,
} from '@client/store/sagas/queries/types';
import { AlertPropertyDetails } from '@client/store/types/alerts';
import { ClaimedHomeData } from '@client/store/types/homeowner';
import {
  AdCardForCobrand,
  FinanceAd,
  LenderCTAAdCard,
  LoanOfficerAd,
  TopAgentsRankedAd,
} from '@client/store/types/search-ads';
import { PartialDeep } from 'type-fest';

export const PDP_CAROUSEL_PHOTO_SIZE = 'LARGE' as 'LARGE';

export type WithAddressRequired<T> = NonNullable<T> & {
  address: Omit<
    Address,
    | 'id'
    | 'slug'
    | 'streetAddress'
    | 'city'
    | 'state'
    | 'zipcode'
    | 'zipcodePlus4'
    | 'fullAddress'
  > & {
    id?: string | null;
    slug: string;
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
    zipcodePlus4?: string | null;
    fullAddress: string;
  };
};

export type WithAddressAndGeoRequired<T> = NonNullable<T> & {
  address: Omit<
    Address,
    | 'id'
    | 'slug'
    | 'streetAddress'
    | 'city'
    | 'state'
    | 'zipcode'
    | 'zipcodePlus4'
    | 'fullAddress'
  > & {
    hcAddressId?: number | null;
    slug: string;
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
    zipcodePlus4?: string | null;
    fullAddress: string;
  };
  geoLocation: {
    latitude: number;
    longitude: number;
  };
};

export type AgentInfo = {
  name: string | null;
  email: string | null;
  phone: string | null;
  licenseNumber: string | null;
};

export type PropertyIntroDetails = {
  Bathrooms: number | null;
  Bedrooms: string | number | null;
  'HOA Fee': string | null;
  'HOA Includes': string | string[] | null;
  'HOA Name': string | null;
  'Living Area': string | null;
  'Lot Size': string | null;
  'Property in Flood Zone': string | null;
  'Property Type': string | null;
  Stories: number | null;
  'Tax Amount': string | null;
  'Tax Year': number | null;
  'Total Rooms': number | null;
  'Year Built': string | null;
  Zoning: string | null;
};

export type PropertyBasicInfo = {
  address: Omit<Address, 'id' | 'zipcodePlus4'> | null;
  baths: number | null;
  beds: number | null;
  sqFeet: number | null;
  propertyType: string | null;
  daysOnMarket?: number | null;
};

/* All possibilities of property data requested from property-graph */
export type IncomingPropertyLookupData =
  | PropertyDetailsInitQuery['propertyLookup']
  | PropertyDetailsFullQuery['propertyLookup']
  | PropertyDetailsForWatchlistItemQuery['propertyLookup'];

export type InitPropertyLookupWithAddressRequired = WithAddressAndGeoRequired<
  PropertyDetailsInitQuery['propertyLookup']
>;
export type FullPropertyLookupWithAddressRequired = WithAddressAndGeoRequired<
  PropertyDetailsFullQuery['propertyLookup']
>;
export type WatchlistItemPropertyLookupWithAddressRequired =
  WithAddressAndGeoRequired<
    PropertyDetailsForWatchlistItemQuery['propertyLookup']
  >;
export type PartialPropertyWithAddressAndGeoRequired =
  WithAddressAndGeoRequired<PartialDeep<Property>>;
export type CompWithAddressAndGeoRequired = WithAddressAndGeoRequired<Comp>;

/* The nexus of the possible ways that we request property data, but with `address` and `geoLocation`
 * and all of their fields required instead of possibly null. Our strategy is to fail hard early if
 * any of these properties aren't defined so that we can ensure these properties exist throughout the
 * app flow */
export type PropertyLookupWithAddressRequired =
  | InitPropertyLookupWithAddressRequired
  | FullPropertyLookupWithAddressRequired
  | WatchlistItemPropertyLookupWithAddressRequired;

export type PropertyDetailsPropertyLookup =
  | InitPropertyLookupWithAddressRequired
  | FullPropertyLookupWithAddressRequired;

export type PartialPropertySummary = PartialDeep<PropertySummary>;
export type PartialCompSummary = PartialDeep<CompSummary>;

/* Ensuring that the expected properties are defined on the propertyLookup object confirms that the
 * object is the "init" propertyLookup object  */
export const isInitPropertyLookupData = (
  propertyLookup:
    | PropertyLookupWithAddressRequired
    | PartialPropertyWithAddressAndGeoRequired
): propertyLookup is InitPropertyLookupWithAddressRequired => {
  const propertyLookupInit =
    propertyLookup as InitPropertyLookupWithAddressRequired;
  return !!(
    propertyLookupInit !== undefined &&
    propertyLookupInit.association !== undefined &&
    propertyLookupInit.avm !== undefined &&
    propertyLookupInit.block !== undefined &&
    propertyLookupInit.hcBuildingId !== undefined &&
    propertyLookupInit.comps !== undefined &&
    propertyLookupInit.county !== undefined &&
    propertyLookupInit.latestAssessment !== undefined &&
    propertyLookupInit.latestListing !== undefined &&
    propertyLookupInit.mls !== undefined &&
    propertyLookupInit.mlsState !== undefined &&
    propertyLookupInit.rentalAvm !== undefined &&
    propertyLookupInit.schools !== undefined &&
    propertyLookupInit.transfers !== undefined
  );
};

/* Ensuring that the expected properties are defined on the propertyLookup object confirms that the
 * object is the "full" propertyLookup object  */
export const isFullPropertyLookupData = (
  propertyLookup:
    | PropertyLookupWithAddressRequired
    | PartialPropertyWithAddressAndGeoRequired
): propertyLookup is FullPropertyLookupWithAddressRequired => {
  const propertyLookupFull =
    propertyLookup as FullPropertyLookupWithAddressRequired;
  return !!(
    isInitPropertyLookupData(propertyLookup) &&
    (propertyLookupFull.avm === null ||
      propertyLookupFull.avm.priceUpper !== undefined) &&
    (propertyLookupFull.avm === null ||
      propertyLookupFull.avm.priceLower !== undefined) &&
    propertyLookupFull.parcel !== undefined &&
    propertyLookupFull.rentalAvm !== undefined &&
    propertyLookupFull.rentalYield !== undefined &&
    propertyLookupFull.nearbyListings !== undefined &&
    propertyLookupFull.zip !== undefined &&
    propertyLookupFull.floodZone !== undefined &&
    propertyLookupFull.paymentEstimate !== undefined
  );
};

/* Ensuring that the expected properties are defined on the propertyLookup object confirms that the
 * object is the "watchlist item" propertyLookup object  */
export const isWatchListItemPropertyLookupData = (
  propertyLookup: PropertyLookupWithAddressRequired
): propertyLookup is WatchlistItemPropertyLookupWithAddressRequired => {
  const propertyLookupWatchlistItem =
    propertyLookup as WatchlistItemPropertyLookupWithAddressRequired;
  return !!(
    propertyLookupWatchlistItem !== undefined &&
    propertyLookupWatchlistItem.address !== undefined &&
    propertyLookupWatchlistItem.county !== undefined &&
    propertyLookupWatchlistItem.structure !== undefined &&
    propertyLookupWatchlistItem.avm !== undefined &&
    propertyLookupWatchlistItem.latestListing !== undefined &&
    propertyLookupWatchlistItem.mlsState !== undefined &&
    propertyLookupWatchlistItem.mls !== undefined
  );
};

export const isInitOrFullPropertyLookupData = (
  propertyLookup:
    | PropertyLookupWithAddressRequired
    | PartialPropertyWithAddressAndGeoRequired
): propertyLookup is
  | InitPropertyLookupWithAddressRequired
  | FullPropertyLookupWithAddressRequired => {
  return (
    isInitPropertyLookupData(propertyLookup) ||
    isFullPropertyLookupData(propertyLookup)
  );
};

export type NormalizedProperty = {
  fullStreetAddress: string;
  streetAddress: string;
  hcBuildingId: number | null;
  hcAddressId?: number | null;
  slug: string;
  city: string;
  state: string;
  zipcode: string;
  zipcodePlus4: string | null;
  county?: string | null;
  unit: string | null;
  fullAddress: string;
  latitude: number;
  longitude: number;
  sqFt?: string | null;
  baths: number | null;
  beds: number | null;
  propertyType: PropertyType | null;
  listingOfficeName?: string | null;
  lotSize?: number | null;
  value: number | null;
  avm: number | null;
  rentalValue?: number | null;
  rentalAvm?: number | null;
  similarity: string | null;
  status: MlsState | 'NOT_LISTED' | null;
  statusLabel: string;
  statusDate?: string | null;
  schools: Partial<PropertySchool>[] | null;
  listPrice: number | null;
  dateOfSale: Date | null;
  dateOfListing: Date | null;
  salePrice: number | null;
  contactInfo: AgentInfo;
  hcMlsId: number | null;
  yearBuilt: number | null;
  monthlyPaymentEstimate?: number | null;
  mlsLogoOverlay?: string | null;
  tractId?: string | null;
};

export interface NormalizedGrantProperty extends NormalizedProperty {
  tractId: string;
}

export type PropertyListProperty = NormalizedProperty & {
  isAddedToWatchList?: boolean;
};

export type PropertyListWithAdCard =
  | PropertyListProperty
  | LenderCTAAdCard
  | LoanOfficerAd
  | TopAgentsRankedAd
  | FinanceAd
  | AdCardForCobrand;

export type SchoolCategory = keyof typeof SCHOOL_TYPES;

export type ForecastChartDataPoint = {
  year: number;
  percent: number;
  value: number;
};

export type ForecastChartData = {
  byYear: Array<{ x: Date; y: number }[]>;
  current: {
    label: string;
    x: Date;
    y: number;
  }[];
  dataId: number;
  forecast: { label: string; x: Date; y: number }[];
  full: { x: Date; y: number }[];
};

export type SchoolsByLevel = {
  [K in SchoolCategory]: Partial<PropertySchool>[];
};

export type CommonPropertyDetails =
  | AlertPropertyDetails
  | NormalizedProperty
  | ClaimedHomeData;

/**
 * type used for mortgage/loan term received from calculate API
 */
export type MortgageTerm = {
  id: string;
  interest_rate: number;
  is_arm: boolean;
  label: string;
  term: number;
};

/**
 * minimum requirement for calculate API is to pass homePrice in
 * payload data, rest of the properties are optional
 */
export type MortgageCalculationDetails = {
  mortgageId?: string | null;
  interestRate?: number | string | null;
  downPayment?: number | null;
  downPaymentPct?: number | null;
  homePrice: number | null;
  monthlyPayment?: number | null;
  insurance?: number | null;
};

/**
 * On redux state we are maintaining a default state with mortgage
 * calculation properties as null to avoid uncontrolled input to
 * controlled input warning in React
 */
export type MortgageCalculationsForUIComponents = {
  mortgageId: string | null;
  interestRate: number | null;
  downPayment: number | null;
  downPaymentPct: number | null;
  homePrice: number | null;
  monthlyPayment: number | null;
  insurance: number | null;
};

export type MortgageSummaryCallParams = {
  activePropertySlug: string;
  userChosenHomePrice?: number | null;
  userChosenInterestRate?: number | string | null;
  userChosenLoanTerm?: string | null;
  userChosenDownpaymentPct?: number | null;
};

export type MortgageSummaryApiData = {
  summary: string;
};

/**
 * Not using type Property here as property details init call
 * loads only parts of Property data
 */
export type PropertyDetailsInitAPIData = {
  data: PropertyDetailsInitQuery;
  errors: any[];
};

export type TransferEventType = 'FORECLOSURE' | 'DISTRESSED' | 'PURCHASE';
