import { resetLocalActivitiesFilter } from '@client/store/actions/local-activities.actions';
import { openModal } from '@client/store/actions/modals.actions';
import { getShouldShowLocalActivitiesBreakoutSection } from '@client/store/selectors/cobranding.selectors';
import { useDispatch, useSelector } from 'react-redux';
import PreviewSection from './PreviewSection';

const LocalActivitiesModal = () => {
  const dispatch = useDispatch();
  const shouldDisplayLocalActivitiesForCobrand = useSelector(
    getShouldShowLocalActivitiesBreakoutSection
  );

  return (
    <>
      {shouldDisplayLocalActivitiesForCobrand && (
        <PreviewSection
          data-section-key={'local-activities-modal'}
          dataEventName={'click_property_details_local_activities_modal'}
          onClick={() => {
            dispatch(openModal('local-activities-modal'));
            dispatch(resetLocalActivitiesFilter());
          }}
          title="Activities and Shopping"
        >
          <div>
            Learn about activities, shopping, entertainment, and more near this
            home.
          </div>
        </PreviewSection>
      )}
    </>
  );
};

export default LocalActivitiesModal;
