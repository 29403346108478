import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { Component } from 'react';

import defaultTheme from '@client/css-modules/MobileFilterSelect.css';
import iOSModalScrollingFix from '@client/hocs/ios-modal-scrolling-fix';
import { FilterKey, FILTERS_ORDER } from '@client/store/filter-constants';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  FILTER_CONTROLS,
  getFilterDescription,
  getFilterTitle,
} from '@client/utils/filters.utils';
import { motion } from 'framer-motion';
import { grantProgramFilterToggleDescription } from './GrantProgram/GrantProgramFilterToggle';

const ScrollableContainer = iOSModalScrollingFix('table');
const FOCUSABLE_ELEMENT_ATTRIBUTE_KEY = 'data-programmatically-focusable';

const {
  headerAndControlsCombinedHeight,
  headerAndControlsAndTitleCombinedHeight,
} = defaultTheme;

type Props = {
  inactiveFilterKeys: FilterKey[];
  handleClose: () => void;
  handleSelect: (key: FilterKey | 'grant-program') => void;
  shouldShowUnitsTotalMinMax: boolean;
  needsMobileHeightAdjustment: boolean;
  theme: Theme;
  reportFilterClick: (key) => void;
  isSchoolDataEnabled: boolean;
  isHidingAdvancedFilters: boolean;
  routeToMlsIdSearch: () => void;
  isShowingGrantToggle: boolean;
};

const ADVANCED_FILTERS = FILTER_CONTROLS.advanced.map(
  (filter) => filter.key
) as Partial<FilterKey>[];

class MobileFilterSelect extends Component<Props> {
  getSortedInactiveFilterKeys = (): Partial<FilterKey>[] => {
    const {
      isHidingAdvancedFilters,
      inactiveFilterKeys,
      shouldShowUnitsTotalMinMax,
    } = this.props;
    const usableFilterKeys = isHidingAdvancedFilters
      ? inactiveFilterKeys.filter(
          (control) => !ADVANCED_FILTERS.includes(control)
        )
      : inactiveFilterKeys;
    return usableFilterKeys
      .filter((key) =>
        shouldShowUnitsTotalMinMax ? key : key !== 'unitsTotalMinMax'
      )
      .sort(
        (aKey, bKey) =>
          FILTERS_ORDER.indexOf(aKey) - FILTERS_ORDER.indexOf(bKey)
      );
  };

  /* Focus the first filter item after component mounts for a11y compliance*/
  focusFirstFilter = (ele: HTMLDivElement | null) => {
    if (ele !== null) {
      const filterItemNodeList = ele.querySelectorAll(
        `[${FOCUSABLE_ELEMENT_ATTRIBUTE_KEY}="true"]`
      );
      if (filterItemNodeList && filterItemNodeList[0]) {
        (filterItemNodeList[0] as HTMLElement).focus();
      }
    }
  };

  render() {
    const {
      theme,
      handleClose,
      handleSelect,
      needsMobileHeightAdjustment,
      reportFilterClick,
      routeToMlsIdSearch,
      isShowingGrantToggle,
    } = this.props;
    const windowHeight = window ? window.innerHeight : 0;

    return (
      <div
        className={theme.MobileFilterSelect}
        role="dialog"
        ref={this.focusFirstFilter}
        style={{
          ...(needsMobileHeightAdjustment
            ? {
                maxHeight: `calc(${windowHeight}px - ${headerAndControlsCombinedHeight})`,
              }
            : {}),
        }}
      >
        <div className={theme.Header}>
          <button
            type="button"
            aria-label="Close Select Filter"
            className={theme.SelectFilters}
            onClick={handleClose}
            onKeyDown={onEnterOrSpaceKey(handleClose)}
          >
            Select Filter
          </button>
        </div>
        <span
          className={theme.SelectFilterHeadingAriaText}
          id="selectFilterHeading"
        >
          Select Filter list
        </span>
        <ScrollableContainer
          aria-labelledby="#selectFilterHeading"
          style={{
            ...(needsMobileHeightAdjustment
              ? {
                  maxHeight: `calc(${windowHeight}px - ${headerAndControlsAndTitleCombinedHeight})`,
                }
              : {
                  maxHeight: `calc(100vh - ${headerAndControlsAndTitleCombinedHeight})`,
                }),
          }}
          className={theme.FilterSelectTable}
        >
          <caption className={theme.Screenreader}>
            Please select a filter
          </caption>
          <tbody>
            <tr className={theme.Screenreader}>
              <th scope="col">Filter name and description</th>
            </tr>
            {isShowingGrantToggle && (
              <tr>
                <td>
                  <motion.div
                    tabIndex={0}
                    aria-labelledby={`#grant-id #grant-desc`}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: {
                        delay: 0.4 * 0.05,
                        duration: 0.2,
                      },
                    }}
                    className={theme.FilterRow}
                    onKeyDown={onEnterOrSpaceKey(() => {
                      reportFilterClick('grant_program');
                      handleSelect('grant-program');
                    })}
                    onClick={(e) => {
                      reportFilterClick('grant_program');
                      handleSelect('grant-program');
                    }}
                    data-filter-key={'grant-program'}
                    /* Attribute to target for focusing on component mount for a11y compliance */
                    {...{ [FOCUSABLE_ELEMENT_ATTRIBUTE_KEY]: 'true' }}
                  >
                    <div className={theme.FilterTitle} id={'grant-id'}>
                      Grant Eligible
                    </div>
                    <div className={theme.FilterDesc} id={'grant-desc'}>
                      {grantProgramFilterToggleDescription}
                    </div>
                  </motion.div>
                </td>
              </tr>
            )}
            {this.getSortedInactiveFilterKeys().map((key, i) => {
              const filterTitleId = `filterTitle_${i}`;
              const filterDescId = `filterDesc_${i}`;
              const effectiveAnimationIndex = isShowingGrantToggle ? i + 1 : i;

              return (
                <tr key={key}>
                  <td>
                    <motion.div
                      key={key}
                      tabIndex={0}
                      aria-labelledby={`#${filterTitleId} #${filterDescId}`}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          delay: 0.4 + effectiveAnimationIndex * 0.05,
                          duration: 0.2,
                        },
                      }}
                      className={theme.FilterRow}
                      onKeyDown={onEnterOrSpaceKey(() => {
                        reportFilterClick(key);
                        handleSelect(key);
                      })}
                      onClick={(e) => {
                        reportFilterClick(key);
                        handleSelect(key);
                      }}
                      data-filter-key={key}
                      /* Attribute to target for focusing on component mount for a11y compliance */
                      {...{ [FOCUSABLE_ELEMENT_ATTRIBUTE_KEY]: 'true' }}
                    >
                      <div className={theme.FilterTitle} id={filterTitleId}>
                        {getFilterTitle(key)}
                      </div>
                      <div className={theme.FilterDesc} id={filterDescId}>
                        {getFilterDescription(key)}
                      </div>
                    </motion.div>
                  </td>
                </tr>
              );
            })}
            {/* Hard-coded MLS ID search link */}
            <tr key="mls-search">
              <td>
                <motion.div
                  key={'search-by-mls-number'}
                  tabIndex={0}
                  aria-labelledby={`#mls-search #mls-search-description`}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      delay:
                        0.4 +
                        (isShowingGrantToggle
                          ? this.getSortedInactiveFilterKeys().length + 1
                          : this.getSortedInactiveFilterKeys().length) *
                          0.05,
                      duration: 0.2,
                    },
                  }}
                  className={theme.FilterRow}
                  onKeyDown={onEnterOrSpaceKey(() => {
                    reportFilterClick('mls-search');
                    routeToMlsIdSearch();
                  })}
                  onClick={(e) => {
                    reportFilterClick('mls-search');
                    routeToMlsIdSearch();
                  }}
                  data-filter-key="mls-search"
                  /* Attribute to target for focusing on component mount for a11y compliance */
                  {...{ [FOCUSABLE_ELEMENT_ATTRIBUTE_KEY]: 'true' }}
                >
                  <div className={theme.FilterTitle} id="mls-search">
                    MLS#
                  </div>
                  <div className={theme.FilterDesc} id="mls-search-description">
                    Search by MLS#
                  </div>
                </motion.div>
              </td>
            </tr>
          </tbody>
        </ScrollableContainer>
      </div>
    );
  }
}

export default themr('MobileFilterSelect', defaultTheme)(MobileFilterSelect);
