import React, { useEffect } from 'react';
import Arrow from '@client/inline-svgs/arrow';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import defaultTheme from '@client/css-modules/HeaderBackArrow.css';
import AddressUnstyled from '@client/components/generic/AddressUnstyled';
import LoadingSection from '@client/components/generic/LoadingSection';
import { motion } from 'framer-motion';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { View } from '@client/routes/constants';

type Props = {
  currentSlug: string;
  currentView: View | null;
  hasPreviousRoute: boolean;
  handleBackButtonClick: (options: {
    view: View;
    slug: string;
    shouldUseBack: boolean;
  }) => void;
  theme: Theme;
  propertyAddress?: {
    slug: string;
    hcAddressId: number | null;
    fullAddress: string;
    streetAddress: string;
    unit?: string;
    city: string;
    state: string;
    zipcode: string;
    street: string;
    addrState: string;
    zip: string;
  } | null;
  isPropertyInClaimedHomes: boolean;
  isXSmallSize: boolean;
  fetchHomeownerUserDataIfNeeded: () => void;
};

const ADDRESS_ANIMATION_VARIANTS = {
  enter: {
    delay: 300,
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 15,
      duration: 30,
    },
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: {
      type: 'spring',
      damping: 15,
      duration: 30,
    },
  },
};

const HeaderBackArrow: React.FC<Props> = (props) => {
  const {
    currentSlug,
    handleBackButtonClick,
    theme,
    propertyAddress,
    isXSmallSize,
    currentView,
    hasPreviousRoute,
    isPropertyInClaimedHomes,
    fetchHomeownerUserDataIfNeeded,
  } = props;

  useEffect(() => {
    fetchHomeownerUserDataIfNeeded();
  }, []);

  const isLoading = !propertyAddress?.streetAddress;
  const arrowAriaProps = {
    role: "img",
    title: "Back to:",
    ariaLabelledBy: "chevron-title",
  };
  return (
    <button
      role="link"
      onKeyDown={onEnterOrSpaceKey(() => {
        handleBackButtonClick({
          view: currentView as View,
          slug: currentSlug,
          shouldUseBack: hasPreviousRoute,
        });
      })}
      onClick={() => {
        handleBackButtonClick({
          view: currentView as View,
          slug: currentSlug,
          shouldUseBack: hasPreviousRoute,
        });
      }}
      className={theme.NavWrapper}
      data-hc-name="goback-link"
    >
      <Arrow className={theme.Arrow} ariaProps={arrowAriaProps} />
      <LoadingSection
        isLoading={isLoading}
        className={classNames(theme.LoadingSectionHidden, {
          [theme.LoadingSectionVisible]: !isLoading,
        })}
      >
        <motion.div
          variants={ADDRESS_ANIMATION_VARIANTS}
          initial="exit"
          animate="enter"
          className={theme.TextWrapper}
        >
          {isPropertyInClaimedHomes && (
            <>
              <span className={theme.MyHomeTitle}>My Home </span>
            </>
          )}
          {isPropertyInClaimedHomes && !isXSmallSize && <>|</>}
          {!isXSmallSize && (
            <span className={theme.Address}>
              &nbsp;
              <AddressUnstyled
                address={{
                  addrState: propertyAddress?.addrState,
                  city: propertyAddress?.city,
                  street: propertyAddress?.street,
                  unit: propertyAddress?.unit,
                  zip: propertyAddress?.zip,
                }}
                hasLineBreak={false}
                hideCityStateZip
              />
            </span>
          )}
        </motion.div>
      </LoadingSection>
    </button>
  );
};

const ThemedHeaderBackArrow = themr(
  'HeaderBackArrow',
  defaultTheme
)(HeaderBackArrow);
export default ThemedHeaderBackArrow;
