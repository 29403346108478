import { call, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import queryString from 'query-string';

import {
  fetchNewAccessToken,
  FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  FETCH_NEW_ACCESS_TOKEN_FAILURE,
} from '@client/store/actions/auth.actions';
import {
  getAccessToken,
  getUserId,
} from '@client/store/selectors/auth.selectors';
import HC_CONSTANTS from '@client/app.config';
import { HttpClient, handleResponse } from '@client/services/http-client';
import { GRANT_TYPES, GrantType } from '@client/store/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  User,
  LODirectUserSignupFields,
  NodeCreateUserRequestBody,
  NodeSelfCreateLODirectClientRequestBody,
} from '@client/store/types/auth';
import { LoanOfficerAPIResponse } from '@client/store/types/consumer-api';
import { CompleteAgentData } from '@client/store/types/your-team';

const httpClient = new HttpClient({
  fetchNewAccessTokenActionObject: fetchNewAccessToken(),
  fetchNewAccessTokenSuccessActionString: FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  fetchNewAccessTokenFailureActionString: FETCH_NEW_ACCESS_TOKEN_FAILURE,
  getAccessTokenSelector: getAccessToken,
});

export type Credentials = {
  email: string;
  password: string;
};

const authUrl = `${HC_CONSTANTS.CONSUMER_API_URL}/auth`;
const userAccountUrl = `${HC_CONSTANTS.CONSUMER_API_URL}/users`;

class ConsumerApiAuthClient {
  cookieId: string;
  siteId: string | null;
  constructor() {
    this.cookieId = Cookies.get('hcid') as string;
    this.siteId = null;
  }

  getSiteId(): string {
    if (this.siteId === null) {
      this.siteId = window.location.hostname.split('.', 1)[0];
    }
    return this.siteId;
  }

  /* Headers to be passed to every `handleFetch` call. Not needed for `makeAuthRequest` since the headers are added in that method */
  getCommonHeaders() {
    return {
      'device-id': this.cookieId,
      'X-platform': navigator.userAgent,
      'X-SiteID': this.getSiteId(),
    };
  }

  /**
   * Creates a new user
   * @param {Object} creds
   */
  *createUser(data: NodeCreateUserRequestBody) {
    const options = {
      url: `${HC_CONSTANTS.APP_HOSTNAME}/create-user`,
      data,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const createUserResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    const {
      loan_officer: loanOfficer,
      user,
      token,
    } = yield call(handleResponse, createUserResponse);

    return {
      loanOfficer,
      user,
      token: token.access,
    } as {
      loanOfficer: LoanOfficerAPIResponse | null;
      user: User;
      token: string;
    };
  }

  /**
   * Creates an LO Direct client, either from an invite (some data already exists) or from scratch
   * via the user entering in all of the data
   * @param {Object} creds
   */
  *selfCreateLODirectClient(data: NodeSelfCreateLODirectClientRequestBody) {
    const options = {
      url: `${HC_CONSTANTS.APP_HOSTNAME}/create-lo-client`,
      data,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const createUserResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    const {
      loan_officer: loanOfficer,
      user,
      token,
    } = yield call(handleResponse, createUserResponse);

    return {
      loanOfficer,
      user,
      token: token.access,
    } as {
      loanOfficer: LoanOfficerAPIResponse | null;
      user: User;
      token: string;
    };
  }

  /**
   * Creates a new user
   * @param {Object} creds
   */
  *createLODirectUser(
    userInfo: LODirectUserSignupFields,
    inviteToken?: string
  ) {
    const options = {
      url: `${HC_CONSTANTS.APP_HOSTNAME}/create-lo-user`,
      data: {
        ...userInfo,
        invite_token: inviteToken,
        application: HC_CONSTANTS.APPLICATION_NAME,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${inviteToken}`,
          'Content-Type': 'application/json',
          ...this.getCommonHeaders(),
        },
      },
    };
    const createUserResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    const { user, token } = yield call(handleResponse, createUserResponse);

    return {
      user,
      token: token.access,
    };
  }

  *refreshAccessTokenAndCookie() {
    const options = {
      url: HC_CONSTANTS.AUTH_TOKEN_REFRESH_ENDPOINT,
      requestOptions: {
        headers: this.getCommonHeaders(),
        method: 'POST',
        cache: 'reload' as 'reload',
        credentials: 'include' as 'include',
      },
    };

    const fetchNewAccessTokenResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    const response = yield call(handleResponse, fetchNewAccessTokenResponse);
    if (fetchNewAccessTokenResponse.status === 200) {
      const { token } = response;
      return {
        token: token.access,
      };
    } else {
      return response;
    }
  }

  *refreshAdminPortalAccessTokenAndCookie() {
    const fetchNewAccessTokenResponse = yield call(
      [httpClient, httpClient.handleFetch],
      {
        url: `${HC_CONSTANTS.APP_HOSTNAME}/auth-token-refresh-cap`,
        requestOptions: {
          headers: this.getCommonHeaders(),
          method: 'POST',
          cache: 'reload' as 'reload',
          credentials: 'include' as 'include',
        },
      }
    );
    const response = yield call(handleResponse, fetchNewAccessTokenResponse);
    if (fetchNewAccessTokenResponse.status === 200) {
      const { token } = response;
      return {
        token: token.access,
      };
    } else {
      return response;
    }
  }

  /* Only used for testing. This will generate a new cookie (refresh token) and access token
   * with the expiration durations that you pass in */
  *regenAccessTokenAndCookie({ expires, grantType, refreshExpires }) {
    const options = {
      url: `${authUrl}/regen`,
      data: {
        expires: expires,
        grant_type: grantType,
        refresh_expires: refreshExpires,
      },
      requestOptions: {
        cache: 'reload' as 'reload',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    };
    const fetchNewAccessTokenResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    const response = yield call(handleResponse, fetchNewAccessTokenResponse);
    if (fetchNewAccessTokenResponse.status === 200) {
      const { token } = response;
      return {
        token: token.access,
      };
    } else {
      return response;
    }
  }

  /**
   * For user to update their own account details
   * @param {Object} userInfo
   */
  *updateUserInfo(userInfo: Partial<User>) {
    const userId = yield select(getUserId);
    const options = {
      url: `${userAccountUrl}/${userId}`,
      data: {
        ...userInfo,
      },
      requestOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      },
    };
    const updateUserResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, updateUserResponse);
  }

  *updateLoDirectUserInfo(data: Partial<User>) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        data,
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${yield select(
          getUserId
        )}`,
        requestOptions: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      })
    );
  }

  *postLoDirectUserImage({
    userInfo,
    image,
  }: {
    userInfo: Partial<User>;
    image: File;
  }) {
    const formData = new FormData();
    formData.append('file', image, image.name);
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        data: formData,
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userInfo.id}/headshot`,
        requestOptions: { method: 'POST' },
      })
    );
  }

  *deleteLoDirectUserImage(userId: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/headshot`,
        requestOptions: { method: 'DELETE' },
      })
    );
  }

  *fetchLoDirectUserInfo(userId: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}}`,
        requestOptions: { method: 'GET' },
      })
    );
  }

  /**
   * Update that the user accepted the terms
   */
  *updateUserTermsAccepted() {
    const userId = yield select(getUserId);

    if (userId) {
      const options = {
        url: `${userAccountUrl}/${userId}`,
        data: {
          terms_accepted: true,
        },
        requestOptions: {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'PATCH',
        },
      };
      const updateUserResponse = yield call(
        [httpClient, httpClient.makeAuthRequest],
        options
      );
      return yield call(handleResponse, updateUserResponse);
    } else {
      throw new Error(
        'userId not present when attempting to call updateTermsAccepted (this is expected during testing)'
      );
    }
  }

  /**
   * Get user info on profile page load
   */
  *getUserInfo() {
    const userId = yield select(getUserId);
    const options = {
      url: `${userAccountUrl}/${userId}`,
      requestOptions: { method: 'GET' },
    };
    const userResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, userResponse);
  }

  /**
   * Login user
   * @param {Object}
   */
  *login({
    email,
    password,
    grant_type,
  }: {
    email: string;
    password: string;
    grant_type: GrantType;
  }) {
    const includeLoanOfficer = yield select(
      getIsFeatureEnabled('loan_officer')
    );

    const options = {
      url: `${HC_CONSTANTS.APP_HOSTNAME}/user-login?${queryString.stringify({
        include_agent: true,
        include_loan_officer: includeLoanOfficer,
      })}`,
      data: {
        email,
        password,
        grant_type,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this.getCommonHeaders(),
        },
        credentials: 'include' as 'include',
      },
    };
    const loginResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    const handledResponse = yield call(handleResponse, loginResponse);
    const { loan_officer: loanOfficer, agent, user, token } = handledResponse;

    return {
      loanOfficer,
      agent,
      user,
      token: token.access,
    };
  }

  *getUserData(userId: string) {
    const response = yield call([httpClient, httpClient.makeAuthRequest], {
      url: `${HC_CONSTANTS.CONSUMER_API_URL}/users/${userId}`,
      requestOptions: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    });
    return yield call(handleResponse, response);
  }

  /**
   * Login anonymous user
   */
  *anonymousLogin() {
    const options = {
      url: `${authUrl}/login`,
      data: {
        grant_type: GRANT_TYPES.ANONYMOUS_DEVICE,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this.getCommonHeaders(),
        },
        credentials: 'include' as 'include',
      },
    };
    const loginResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    const { token } = yield call(handleResponse, loginResponse);

    return {
      token: token.access,
    };
  }

  /**
   * Logout user
   */
  *logout() {
    const options = {
      url: `${HC_CONSTANTS.APP_HOSTNAME}/logout`,
      requestOptions: {
        method: 'POST',
        headers: this.getCommonHeaders(),
        credentials: 'include' as 'include',
      },
    };
    const response = yield call([httpClient, httpClient.handleFetch], options);
    const { user, token } = yield call(handleResponse, response);
    return {
      user,
      token: token.access,
    };
  }

  /**
   * Forgot Password
   * @param {Object} body : { email }
   */
  *forgotPassword(body: { email: string; type: 'lo-direct' | null }) {
    const options = {
      url: `${authUrl}/forgot-password`,
      data: body,
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const forgotPasswordResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    return yield call(handleResponse, forgotPasswordResponse);
  }

  /**
   * Reset password
   * @param {String} resetPasswordToken
   * @param {String} newPassword
   * @param {String} email
   */
  *resetPassword(
    resetPasswordToken: string,
    newPassword: string,
    email: string
  ) {
    const options = {
      url: `${authUrl}/reset-password`,
      data: {
        token: resetPasswordToken,
        new_password: newPassword,
        email,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this.getCommonHeaders(),
        },
      },
    };
    const resetPasswordResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    return yield call(handleResponse, resetPasswordResponse);
  }

  /**
   * Confirm new user email with token (from the email received by the user)
   * @param {string} confirmToken - token to verify
   */
  *confirmUserEmail(confirmToken: string) {
    const options = {
      url: `${userAccountUrl}/confirm`,
      requestOptions: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${confirmToken}`,
          ...this.getCommonHeaders(),
        },
      },
    };
    const confirmUserEmailResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    const response = yield call(handleResponse, confirmUserEmailResponse);
    return { response };
  }

  /**
   * Resend confirm user email
   *  @param {string} token - user's token from the confirmation email
   */
  *resendConfirmationEmail(token: string | null) {
    const accessToken = yield select(getAccessToken);
    const options = {
      url: `${userAccountUrl}/resend-confirm`,
      /* If we have a special confirm token, send it. Otherwise, the user is logged in, and the standard user
       * token will suffice */
      data: token ? { Token: token } : {},
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this.getCommonHeaders(),
          ...(!token ? { Authorization: `Bearer ${accessToken}` } : {}),
        },
      },
    };
    const resendConfirmationEmailResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    return yield call(handleResponse, resendConfirmationEmailResponse);
  }

  *getLODirectUserSubscriptionStatus(userId: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/lousers/${userId}/status`,
        requestOptions: { method: 'GET' },
      })
    );
  }

  *fetchOAuthIdTokenForUser(userId: string) {
    return yield call(
      handleResponse,
      yield call([httpClient, httpClient.makeAuthRequest], {
        url: `${HC_CONSTANTS.CONSUMER_API_URL}/oath/idtoken?userid=${userId}`,
        requestOptions: { method: 'GET' },
      })
    );
  }

  *loginOAuthOIDC({ id_token }: { id_token: string }) {
    const includeLoanOfficer = yield select(
      getIsFeatureEnabled('loan_officer')
    );
    const options = {
      url: `${
        HC_CONSTANTS.CONSUMER_API_URL
      }/auth/login-token-exchange?${queryString.stringify({
        include_agent: true,
        include_loan_officer: includeLoanOfficer,
      })}`,
      data: {
        id_token,
      },
      requestOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...this.getCommonHeaders(),
        },
        credentials: 'include' as 'include',
      },
    };
    const loginResponse = yield call(
      [httpClient, httpClient.makeAuthRequest],
      options
    );
    const {
      loan_officer: loanOfficer,
      user,
      agent,
      token,
    }: {
      loan_officer: LoanOfficerAPIResponse | null;
      user: User;
      agent: CompleteAgentData | null;
      token: { access: string };
    } = yield call(handleResponse, loginResponse);

    return {
      loanOfficer,
      user,
      agent,
      token: token.access,
    };
  }
}

/**
 * Authentication client that talks to consumer API
 * @return {ConsumerApiAuthClient}
 */
export const authClient = new ConsumerApiAuthClient();
